.at {
  width: 100%;
  height: auto;
  padding: 5rem 0;
  margin-top: 8rem;

  .container {
    width: 100%;
    background: linear-gradient(217deg, #6c3c4a00, rgba(255, 0, 0, 0) 70.71%),
      linear-gradient(127deg, #6c3c4a00, rgba(0, 255, 0, 0) 70.71%),
      linear-gradient(336deg, #6c3c4a65, rgba(0, 0, 255, 0) 70.71%);
    margin: 0 auto;
    padding: 11rem 0 20rem 0;
    border-radius: 10% 40% / 50% 20%;
    position: relative;

    .content {
      width: 100%;
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: space-evenly;

      .title {
        width: 300px;
        font-family: homemade-apple, sans-serif;
        font-size: 1.8rem;
        line-height: 2.3rem;
        color: $c-yellow;
        background-color: $c-dark-pink;
        border-radius: 20% 0% 0% 40%;
        padding: 2rem 0 2.5rem 1rem;
        text-align: center;
        box-shadow: 0px 10px 10px -10px $c-very-dark-duck-blue;
        position: absolute;
        top: -7rem;
        right: 0;
        z-index: 5;
      }

      .title-background {
        width: 300px;
        height: 200px;
        background-color: $c-straw-red;
        border-radius: 35% 40% 30% 30%;
        box-shadow: 0px 10px 10px -10px $c-very-dark-duck-blue;
        position: absolute;
        top: -6.5rem;
        right: 1rem;
        z-index: 0;
      }

      .at-image-container {
        width: 400px;
        height: 200px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        margin-top: 6rem;
        padding: 0 2rem;

        .image-at {
          width: 250px;
          height: 250px;
          margin: 0 auto;
        }

        .at1 {
          margin-bottom: -7rem;
        }

        .at3 {
          margin-top: -7rem;
        }
      }

      .text {
        width: 95%;
        text-align: center;
        align-self: center;
        font-family: barlow-italic, sans-serif;
        font-size: 1.2rem;
        line-height: 1.5rem;
        color: $c-dark-duck-blue;
        text-align: center;
        margin: 10rem auto 0 auto;
        z-index: 100;

        .see-article {
          width: 70%;
          display: flex;
          justify-content: flex-start;
          align-items: center;
          background-color: transparent;
          color: $c-dark-straw-pink;
          margin: 3rem 0 0 -2rem;

          &:hover > .fa-arrow {
            animation: slidein 1.3s ease-in-out infinite running;
          }

          .label {
            font-family: barlow-semibold, sans-serif;
            font-size: 1rem;
            margin-right: -1rem;
          }

          .fa-arrow {
            font-size: 0.8rem;
            margin-top: 0.3rem;
          }
        }
      }

      .image {
        width: 280px;
        height: 350px;
        position: absolute;
        bottom: -0.2rem;
        right: -4rem;
      }
    }
  }
}

@media (min-width: 375px) {
  .at {
    .container {
      .content {
        .at-image-container {
          margin-top: 8rem;
          padding: 0 3rem;

          .at1 {
            margin-bottom: -7.5rem;
          }

          .at3 {
            margin-top: -7.5rem;
          }
        }

        .text {
          .see-article {
            width: 60%;
            margin: 3rem 0 0 0;
          }
        }
      }
    }
  }
}

@media (min-width: 375px) and (min-height: 812px) {
  .at {
    .container {
      .content {
        .text {
          margin: 13rem auto 0 auto;
        }
      }
    }
  }
}

@media (min-width: 412px) {
  .at {
    .container {
      .content {
        .at-image-container {
          margin-top: 5rem;

          .at1 {
            margin-bottom: -7rem;
          }

          .at3 {
            margin-top: -7rem;
          }
        }
      }
    }
  }
}

@media (min-width: 414px) {
  .at {
    .container {
      .content {
        .at-image-container {
          padding: 0 4rem;
        }
      }
    }
  }
}

//* -------------------------------------------------------------------------------
//* ------------------------------ TABLET ----------------------------------------
//* -------------------------------------------------------------------------------

@media (min-width: 599px) {
  .at {
    .container {
      padding: 11rem 0 23rem 0;

      .content {
        .title {
          width: 500px;
          font-size: 2.5rem;
          line-height: 3rem;
          padding: 2rem;
        }

        .title-background {
          width: 500px;
          top: -4.5rem;
        }

        .at-image-container {
          width: 400px;
          height: 200px;
          margin-top: 10rem;
          padding: 0 4rem;
        }

        .text {
          width: 85%;
          font-size: 1.3rem;
          line-height: 1.6rem;

          .see-article {
            width: 60%;
            margin: 3rem 0 0 2rem;

            .label {
              font-size: 1.3rem;
              margin-right: 1rem;
            }

            .fa-arrow {
              font-size: 1rem;
              margin-top: 0.5rem;
            }
          }
        }

        .image {
          width: 300px;
          height: 380px;
          bottom: -0.3rem;
        }
      }
    }
  }
}

//* -------------------------------------------------------------------------------
//* ------------------------------ DESKTOP ----------------------------------------
//* -------------------------------------------------------------------------------

@media (min-width: 1199px) {
  .at {
    padding: 5rem 0;
    margin-top: 13rem;

    &:hover > .container > .content > .at-image-container > .at1 {
      animation: bounce-at1 4s infinite;
      animation-delay: 0s;
    }

    &:hover > .container > .content > .at-image-container > .at2 {
      animation: bounce-at2 4s infinite;
      animation-delay: 0.5s;
    }

    &:hover > .container > .content > .at-image-container > .at3 {
      animation: bounce-at3 4s infinite;
      animation-delay: 1s;
    }

    .container {
      padding: 11rem 2rem 23rem 2rem;
      border-radius: 10% 40% / 50% 20%;

      .content {
        flex-wrap: nowrap;

        .title {
          width: 750px;
          font-size: 3rem;
          line-height: 4.5rem;
          padding: 2rem 0 2rem 2rem;
          top: -7rem;
        }

        .title-background {
          width: 750px;
          height: 120px;
          top: 0rem;
          right: 3rem;
        }

        .at-image-container {
          width: 300px;
          height: 200px;
          margin-top: 15rem;
          padding: 0;
        }

        .text {
          width: 800px;
          font-size: 1.7rem;
          line-height: 2.3rem;
          margin: 0 auto;

          .see-article {
            justify-content: flex-start;

            .label {
              font-size: 1.5rem;
              margin-right: 1rem;
            }

            .fa-arrow {
              font-size: 1rem;
              margin-top: 0.3rem;
            }
          }
        }

        .image {
          width: 350px;
          height: 420px;
          bottom: -0.3rem;
        }
      }
    }
  }
}

@media (min-width: 1399px) {
  .at {
    .container {
      .content {
        .at-image-container {
          width: 350px;
          margin-top: 15rem;
          padding: 0;
        }

        .text {
          .see-article {
            .label {
              font-size: 1.7rem;
              margin-right: 1.5rem;
            }

            .fa-arrow {
              font-size: 1.3rem;
              margin-top: 0.5rem;
            }
          }
        }

        .image {
          width: 400px;
          height: 480px;
          bottom: -0.4rem;
        }
      }
    }
  }
}

@media (min-width: 1920px) {
  .at {
    .container {
      padding: 11rem 2rem 3rem 2rem;

      .content {
        .at-image-container {
          width: 400px;
          height: 200px;
          margin-top: 5rem;

          .image-at {
            width: 350px;
            height: 350px;
          }

          .at1 {
            margin-bottom: -10rem;
          }

          .at3 {
            margin-top: -10rem;
          }
        }

        .text {
          width: 1000px;
          margin-right: 20rem;

          .see-article {
            width: 90%;
            justify-content: flex-end;

            .label {
              margin-right: 1rem;
            }

            .fa-arrow {
              font-size: 1.1rem;
            }
          }
        }

        .image {
          width: 450px;
          height: 550px;
        }
      }
    }
  }
}
