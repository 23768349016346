.landing-container {
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background: linear-gradient(207deg, $c-light-duck-blue, rgba(255, 0, 0, 0) 70.71%),
    linear-gradient(336deg, $c-duck-blue, rgba(0, 255, 0, 0) 70.71%),
    linear-gradient(0deg, rgba(246, 246, 246, 0), rgba(0, 0, 255, 0) 70.71%);
  background-repeat: no-repeat;
  background-size: cover;
  overflow: hidden;
  position: relative;

  .image {
    width: 200px;
    height: auto;
    position: absolute;
    bottom: 0;
    left: -2rem;
  }

  .principal-content {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    overflow: hidden;
    position: relative;

    &::after {
      content: '';
      width: 250px;
      height: 250px;
      background: none;
      box-shadow: 0px 2px 10px $c-very-dark-duck-blue;
      border: solid 0.5rem;
      border-image: linear-gradient($c-ocre, $c-yellow) 90;
      transform: rotate(45deg);
      position: absolute;
      top: 4%;
      right: 11%;
    }

    .title-container {
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;
      margin: 1rem auto 0 auto;

      .title {
        font-family: amatic-bold, sans-serif;
        font-size: 3rem;
        color: $c-white-aqua-blue;
        text-shadow: 0px 2px 5px $c-very-dark-duck-blue;
        z-index: 10;
        text-align: center;
      }

      .subtitle {
        font-family: homemade-apple, sans-serif;
        font-size: 1.2rem;
        color: $c-light-ocre;
        margin: 1rem auto 0 auto;
        text-shadow: 0px 1px 2px $c-very-dark-duck-blue;
        z-index: 10;
      }
    }

    .cirle-container {
      width: 100%;
      height: 250px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      position: absolute;
      top: 12%;

      .circle {
        width: 65px;
        height: 65px;
        background-repeat: no-repeat;
        background-size: contain;
        animation-duration: 1s;
        animation-name: slide-in;
        animation-iteration-count: 1;
        animation-fill-mode: forwards;
        transform: translateX(500px);
        z-index: 5;
      }

      #circle1 {
        background-image: url('../../images/circle3.webp');
        // bottom: 0%;
        margin-bottom: -0.8rem;
      }

      #circle2 {
        background-image: url('../../images/circle2.webp');
        // bottom: 65px;
        animation-delay: 1s;
      }

      #circle3 {
        background-image: url('../../images/circle1.webp');
        margin-top: -0.8rem;
        animation-delay: 2s;

        // bottom: 130px;
      }
    }

    .text-container {
      width: 100%;
      display: flex;
      justify-content: flex-end;
      align-items: flex-start;
      z-index: 15;

      .text {
        width: 55%;
        font-family: homemade-apple, sans-serif;
        font-size: 1rem;
        line-height: 1.5rem;
        color: $c-very-dark-duck-blue;
        text-align: end;
        margin-right: 0.5rem;
        margin-top: 9rem;
        text-shadow: 0px 1px 1px $c-very-dark-duck-blue;
      }
    }
  }

  .secondary-content {
    .button {
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      justify-content: center;
      font-size: 2rem;
      font-family: amatic-bold, sans-serif;
      color: $c-white-aqua-blue;
      position: absolute;
      right: 0;
      bottom: 3.5rem;
      margin-right: 1rem;
      text-shadow: 0px 1px 2px $c-very-dark-duck-blue;
      z-index: 10000;

      &:hover {
        cursor: pointer;
      }

      .fa-arrow {
        font-size: 1.5rem;
        color: $c-yellow;
        margin: 0.3rem 0 0 1rem;
        text-shadow: 0px 1px 2px $c-very-dark-duck-blue;
        animation: slide-right 0.5s infinite alternate;
        animation-delay: 3s;
      }
    }
  }
}

@media (min-width: 360px) {
  .landing-container {
    .image {
      width: 240px;
    }

    .principal-content {
      &::after {
        width: 350px;
        height: 350px;
        top: -3%;
        right: 1%;
      }

      .title-container {
        .title {
          font-size: 3.5rem;
        }

        .subtitle {
          font-size: 1.5rem;
        }
      }

      .cirle-container {
        height: 270px;
        top: 15%;

        .circle {
          width: 90px;
          height: 90px;
        }

        #circle1 {
          margin-bottom: -1rem;
        }

        #circle3 {
          margin-top: -1rem;
        }
      }
    }

    .secondary-content {
      .button {
        bottom: 5.5rem;
      }
    }
  }
}

@media (min-width: 360px) and (min-height: 740px) {
  .landing-container {
    .principal-content {
      .cirle-container {
        top: 14%;

        #circle1 {
          margin-bottom: -1.1rem;
        }

        #circle3 {
          margin-top: -1.1rem;
        }
      }
    }
  }
}

@media (min-width: 375px) {
  .landing-container {
    .image {
      width: 250px;
    }

    .principal-content {
      &::after {
        width: 350px;
        height: 350px;
        top: -3%;
        right: 4%;
      }

      .title-container {
        .title {
          font-size: 3.5rem;
        }

        .subtitle {
          font-size: 1.5rem;
        }
      }

      .cirle-container {
        top: 15%;

        #circle1 {
          margin-bottom: -1.2rem;
        }

        #circle3 {
          margin-top: -1.2rem;
        }
      }
    }
  }
}

@media (min-width: 375px) and (min-height: 812px) {
  .landing-container {
    .principal-content {
      &::after {
        top: 3%;
      }
    }
  }
}

@media (min-width: 390px) {
  .landing-container {
    .image {
      width: 270px;
    }

    .principal-content {
      &::after {
        width: 350px;
        height: 350px;
        top: 5%;
        right: 4%;
      }

      .title-container {
        .title {
          font-size: 3.5rem;
        }

        .subtitle {
          font-size: 1.5rem;
        }
      }
    }
  }
}

@media (min-width: 400px) {
  .landing-container {
    .principal-content {
      .text-container {
        .text {
          width: 50%;
          margin-top: 13rem;
        }
      }
    }
  }
}

@media (min-width: 414px) {
  .landing-container {
    .image {
      width: 270px;
    }

    .principal-content {
      &::after {
        width: 350px;
        height: 350px;
        right: 7.5%;
      }

      .title-container {
        .title {
          font-size: 4rem;
        }
      }
    }
  }
}

//* -------------------------------------------------------------------------------
//* ------------------------------ TABLET ----------------------------------------
//* -------------------------------------------------------------------------------

@media (min-width: 599px) {
  .landing-container {
    .image {
      width: 350px;
    }

    .principal-content {
      &::after {
        width: 600px;
        height: 600px;
        top: -3%;
        right: 11%;
      }

      .title-container {
        .title {
          font-size: 7rem;
        }

        .subtitle {
          font-size: 2.5rem;
          margin-top: 2rem;
        }
      }

      .cirle-container {
        height: 390px;
        top: 20%;

        .circle {
          width: 130px;
          height: 130px;
        }

        #circle1 {
          margin-bottom: -1.5rem;
        }

        #circle3 {
          margin-top: -1.5rem;
        }
      }

      .text-container {
        .text {
          font-size: 1.2rem;
          line-height: 1.7rem;
          margin: 20rem 1rem 0 0;
        }
      }
    }

    .secondary-content {
      .button {
        font-size: 2.5rem;
        margin-right: 2rem;

        .fa-arrow {
          font-size: 2rem;
          margin: 0.5rem 0 0 1.5rem;
        }
      }
    }
  }
}

@media (min-width: 899px) {
  .landing-container {
    .image {
      width: 530px;
    }

    .principal-content {
      &::after {
        width: 800px;
        height: 800px;
        border: solid 1.2rem;
        border-image: linear-gradient($c-ocre, $c-yellow) 90;
        top: -8%;
      }

      .title-container {
        .title {
          font-size: 9rem;
        }

        .subtitle {
          font-size: 3.5rem;
          margin-top: 2rem;
        }
      }

      .cirle-container {
        height: 540px;
        top: 17%;

        .circle {
          width: 180px;
          height: 180px;
        }

        #circle1 {
          margin-bottom: -2.2rem;
        }

        #circle3 {
          margin-top: -2.2rem;
        }
      }

      .text-container {
        .text {
          margin: 25rem 1.5rem 0 0;
        }
      }
    }

    .secondary-content {
      .button {
        font-size: 2.5rem;
        margin-right: 2rem;

        .fa-arrow {
          font-size: 2rem;
          margin: 0.5rem 0 0 1.5rem;
        }
      }
    }
  }
}

//* -------------------------------------------------------------------------------
//* ------------------------------ DESKTOP ----------------------------------------
//* -------------------------------------------------------------------------------

@media (min-width: 1199px) {
  .landing-container {
    .image {
      width: 30%;
      left: -5rem;
    }

    .principal-content {
      &::after {
        content: '';
        width: 700px;
        height: 700px;
        top: 4rem;
        right: -5rem;
      }

      .title-container {
        width: 100%;
        align-items: flex-end;
        margin: 2rem 2rem 0 0;

        .title {
          font-size: 8rem;
        }

        .subtitle {
          font-size: 2.5rem;
          margin: 2rem 7% 0 0;
        }
      }

      .cirle-container {
        width: 50%;
        height: 480px;
        right: -2rem;
        top: 30%;

        .circle {
          width: 160px;
          height: 160px;
        }

        #circle1 {
          margin-bottom: -2rem;
        }

        #circle3 {
          margin-top: -2rem;
        }
      }

      .text-container {
        width: 75%;
        justify-content: flex-start;
        z-index: 15;

        .text {
          margin: 20rem 2rem 0 12rem;
          text-align: end;
        }
      }
    }

    .secondary-content {
      .button {
        font-size: 2rem;
        right: 0;
        bottom: 1rem;
        margin-right: 0.5rem;

        .fa-arrow {
          font-size: 1.5rem;
          margin: 0.3rem 0 0 1rem;
        }
      }
    }
  }
}

@media (min-width: 1399px) {
  .landing-container {
    .image {
      width: 650px;
      left: -5rem;
    }

    .principal-content {
      &::after {
        right: 0rem;
      }

      .title-container {
        width: 100%;
        margin: 2rem 2rem 0 0;

        .title {
          font-size: 10rem;
        }

        .subtitle {
          font-size: 3rem;
          margin: 2rem 8% 0 0;
        }
      }

      .cirle-container {
        right: 0rem;
        top: 35%;
      }

      .text-container {
        .text {
          margin: 15rem 2rem 0 12rem;
          font-size: 1.5rem;
          line-height: 2rem;
        }
      }
    }

    .secondary-content {
      .button {
        font-size: 2.5rem;
        bottom: 1.5rem;
        margin-right: 0.5rem;

        .fa-arrow {
          font-size: 2rem;
          margin: 0.4rem 0 0 1.5rem;
        }
      }
    }
  }
}

@media (min-width: 1599px) {
  .landing-container {
    .principal-content {
      .text-container {
        .text {
          margin: 15rem 0 0 17rem;
        }
      }

      .cirle-container {
        right: -4rem;
        top: 35%;
      }
    }
  }
}

@media (min-width: 1920px) {
  .landing-container {
    .image {
      width: 770px;
    }

    .principal-content {
      &::after {
        width: 57em;
        height: 57em;
        top: 1rem;
        right: 7rem;
      }

      .title-container {
        margin: 2rem 7rem 0 0;

        .title {
          font-size: 12rem;
        }

        .subtitle {
          font-size: 4rem;
          margin: 3rem 12rem 0 0;
        }
      }

      .cirle-container {
        height: 660px;
        right: 5rem;

        .circle {
          width: 220px;
          height: 220px;
          transform: translateX(700px);
        }

        #circle1 {
          margin-bottom: -2.8rem;
        }

        #circle3 {
          margin-top: -2.8rem;
        }
      }

      .text-container {
        width: 85%;

        .text {
          font-size: 2rem;
          line-height: 2.5rem;
          margin: 27rem 2rem 0 12rem;
        }
      }
    }

    .secondary-content {
      .button {
        font-size: 3rem;
        bottom: 2rem;
        margin-right: 2rem;

        .fa-arrow {
          font-size: 2rem;
          margin: 0.7rem 0 0 1.5rem;
        }
      }
    }
  }
}
