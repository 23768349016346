.emotions {
  width: 101%;
  height: auto;
  padding: 5rem 0;
  margin-top: 1rem;
  position: relative;

  .title {
    width: 300px;
    font-family: homemade-apple, sans-serif;
    font-size: 2rem;
    line-height: 2.5rem;
    color: $c-yellow;
    background-color: $c-light-duck-blue;
    border-radius: 0% 30% 45% 0%;
    padding: 2rem 0 2rem 1rem;
    text-align: start;
    box-shadow: 0px 10px 10px -10px $c-very-dark-duck-blue;
    position: absolute;
    top: 2rem;
    left: 0;
    z-index: 5;
  }

  .title-background {
    width: 300px;
    height: 200px;
    background-color: $c-duck-blue;
    border-radius: 35% 40% 30% 20%;
    box-shadow: 0px 10px 10px -10px $c-very-dark-duck-blue;
    position: absolute;
    top: 5rem;
    left: 1rem;
    z-index: 0;
  }

  .content {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-evenly;
    background: linear-gradient(217deg, #985e0d12, rgba(255, 0, 0, 0) 70.71%),
      linear-gradient(127deg, #bf7e2400, rgba(0, 255, 0, 0) 70.71%),
      linear-gradient(336deg, #985e0d2d, rgba(0, 0, 255, 0) 70.71%);
    border-radius: 50% 20% / 10% 40%;
    padding: 15rem 0 2rem 0;

    .content-item {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-bottom: 2rem;

      .image {
        width: 150px;
        height: 200px;
        margin-bottom: -1rem;
      }

      .label {
        width: 100%;
        font-size: 1.5rem;
        text-align: center;
      }

      .sad {
        font-family: edu-regular, sans-serif;
        color: $c-dark-aqua-blue;
      }

      .happy {
        font-family: homemade-apple, sans-serif;
        color: $c-light-pink;
      }

      .fear {
        font-family: amatic-regular, sans-serif;
        color: $c-ocre;
        font-weight: bold;
      }

      .angry {
        font-family: barlow-regular, sans-serif;
        text-transform: uppercase;
        color: $c-dark-straw-pink;
      }
    }

    .content-item-sad {
      & > .sad {
        animation: lights 8s linear infinite;
        animation-timing-function: linear;
        animation-delay: 0s;
      }
    }

    .content-item-happy {
      & > .happy {
        animation: pulse 8s infinite;
        animation-timing-function: linear;
        animation-delay: 2s;
      }
    }

    .content-item-fear {
      & > .fear {
        animation: shake 8s infinite;
        animation-timing-function: linear;
        animation-delay: 3s;
      }
    }

    .content-item-angry {
      & > .angry {
        animation: wiggle 8s infinite;
        animation-timing-function: linear;
        animation-delay: 4s;
      }
    }
  }
}

@media (min-width: 375px) {
  .emotions {
    .content {
      .content-item {
        .image {
          width: 180px;
          height: 230px;
          margin-bottom: -1.5rem;
        }

        .label {
          font-size: 1.8rem;
        }
      }
    }
  }
}

//* -------------------------------------------------------------------------------
//* ------------------------------ TABLET ----------------------------------------
//* -------------------------------------------------------------------------------

@media (min-width: 599px) {
  .emotions {
    .title {
      width: 550px;
      font-size: 2.5rem;
      line-height: 3rem;
      padding: 2rem;
    }

    .title-background {
      width: 550px;
    }

    .content {
      border-radius: 50% 10% / 10% 40%;
      padding: 15rem 0 2rem 0;

      .content-item {
        .image {
          width: 300px;
          height: 350px;
          margin-bottom: -2rem;
        }

        .label {
          font-size: 3rem;
        }
      }
    }
  }
}

@media (min-width: 899px) {
  .emotions {
    .content {
      .content-item {
        .image {
          width: 350px;
          height: 400px;
          margin-bottom: -2.5rem;
        }

        .label {
          font-size: 3.5rem;
        }
      }
    }
  }
}

//* -------------------------------------------------------------------------------
//* ------------------------------ DESKTOP ----------------------------------------
//* -------------------------------------------------------------------------------

@media (min-width: 1199px) {
  .emotions {
    padding: 8rem 0 10rem 0;
    margin-top: 3rem;

    .title {
      width: 750px;
      font-size: 3rem;
      line-height: 4rem;
      padding: 2rem 0 2rem 2rem;
      top: 5rem;
    }

    .title-background {
      width: 750px;
      height: 120px;
      top: 11rem;
      left: 3rem;
    }

    .content {
      border-radius: 50% 20% / 10% 20%;
      padding: 15rem 1rem 5rem 1rem;

      .content-item {
        .image {
          width: 280px;
          height: 330px;
          margin-bottom: -3rem;
        }

        .label {
          font-size: 3.5rem;
        }
      }
    }
  }
}

@media (min-width: 1399px) {
  .emotions {
    .content {
      padding: 15rem 0 5rem 0;

      .content-item {
        .image {
          width: 350px;
          height: 400px;
        }

        .label {
          font-size: 4rem;
        }
      }
    }
  }
}
