html {
  min-width: 100vw;
  max-width: 100vw;
}

body {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  color: $c-dark-duck-blue;
  position: relative;
  overflow-x: hidden;

  &:after {
    width: 101%;
    height: 100%;
    content: '';
    display: block;
    background-image: url('../../images/wallpaper.svg');
    background-repeat: no-repeat;
    background-size: cover;
    position: fixed;
    top: 0;
    left: -5px;
    z-index: -1;
  }
}

::selection {
  background-color: $c-light-duck-blue;
  color: $c-white-aqua-blue;
}

.fa {
  transition: transform 0.3s ease-in-out;

  &:hover {
    transform: translateY(-3px);
    color: $c-ocre;
    filter: drop-shadow(1px 1px 1px $c-dark-duck-blue);
  }
}

.scroll-icon {
  position: absolute;
  bottom: 0rem;
  left: 45%;
  z-index: 300;
}

@media (min-width: 1199px) {
  .scroll-icon {
    bottom: 0rem;
    left: 48%;
  }
}

@media (min-width: 1920px) {
  .scroll-icon {
    bottom: 0rem;
    left: 48%;
  }
}
