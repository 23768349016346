// https://coolors.co/81a99f-b5cdc7-dfe8e6-fafbfa-fffdfd
$c-dark-aqua-blue: #81a99f;
$c-aqua-blue: #b5cdc7;
$c-light-aqua-blue: #dfe8e6;
$c-very-light-aqua-blue: #fafbfa;
$c-white-aqua-blue: #fffdfd;

// https://coolors.co/031416-00181b-012f36-0f5b66-026a79
$c-very-dark-duck-blue: #031416;
$c-dark-duck-blue: #00181b;
$c-duck-blue: #012f36;
$c-light-duck-blue: #0f5b66;
$c-very-light-duck-blue: #026a79;

// https://coolors.co/2b040f-451120-5b2333-6c3c4a-995d6e-a26c7b-ce95a5
$c-blood-red: #2b040f;
$c-straw-red: #451120;
$c-dark-straw-pink: #5b2333;
$c-dark-pink: #6c3c4a;
$c-pink: #995d6e;
$c-light-pink: #a26c7b;
$c-very-light-pink: #ce95a5;

// https://coolors.co/985e0d-bf7e24-db9d47-fac172-ffd79f
$c-dark-ocre: #985e0d;
$c-ocre: #bf7e24;
$c-light-ocre: #db9d47;
$c-yellow: #fac172;
$c-light-yellow: #ffd79f;

$c-error-message: #c31f3a;

// rotaion de background pour la page Blog

$c-first-background: linear-gradient(217deg, $c-dark-straw-pink, rgba(255, 0, 0, 0) 70.71%),
  linear-gradient(127deg, $c-dark-pink, rgba(0, 255, 0, 0) 70.71%),
  linear-gradient(336deg, $c-dark-straw-pink, rgba(0, 0, 255, 0) 70.71%), url('../../images/wallpaper.svg');

$c-second-background: linear-gradient(217deg, $c-pink, rgba(255, 0, 0, 0) 70.71%),
  linear-gradient(127deg, $c-light-pink, rgba(0, 255, 0, 0) 70.71%),
  linear-gradient(336deg, $c-pink, rgba(0, 0, 255, 0) 70.71%), url('../../images/wallpaper.svg');

$c-third-background: linear-gradient(217deg, $c-light-ocre, rgba(255, 0, 0, 0) 70.71%),
  linear-gradient(127deg, $c-yellow, rgba(0, 255, 0, 0) 70.71%),
  linear-gradient(336deg, $c-light-ocre, rgba(0, 0, 255, 0) 70.71%), url('../../images/wallpaper.svg');

$c-fourth-background: linear-gradient(217deg, $c-ocre, rgba(255, 0, 0, 0) 70.71%),
  linear-gradient(127deg, $c-light-ocre, rgba(0, 255, 0, 0) 70.71%),
  linear-gradient(336deg, $c-ocre, rgba(0, 0, 255, 0) 70.71%), url('../../images/wallpaper.svg');

$c-fifth-background: linear-gradient(217deg, $c-light-duck-blue, rgba(255, 0, 0, 0) 70.71%),
  linear-gradient(127deg, $c-very-light-duck-blue, rgba(0, 255, 0, 0) 70.71%),
  linear-gradient(336deg, $c-light-duck-blue, rgba(0, 0, 255, 0) 70.71%), url('../../images/wallpaper.svg');

$c-sixth-background: linear-gradient(217deg, $c-duck-blue, rgba(255, 0, 0, 0) 70.71%),
  linear-gradient(127deg, $c-light-duck-blue, rgba(0, 255, 0, 0) 70.71%),
  linear-gradient(336deg, $c-duck-blue, rgba(0, 0, 255, 0) 70.71%), url('../../images/wallpaper.svg');

$c-seventh-background: linear-gradient(217deg, $c-dark-duck-blue, rgba(255, 0, 0, 0) 70.71%),
  linear-gradient(127deg, $c-duck-blue, rgba(0, 255, 0, 0) 70.71%),
  linear-gradient(336deg, $c-dark-duck-blue, rgba(0, 0, 255, 0) 70.71%), url('../../images/wallpaper.svg');

$articles-rotation-background-list: (
  1: $c-first-background,
  2: $c-second-background,
  3: $c-third-background,
  4: $c-fourth-background,
  5: $c-fifth-background,
  6: $c-sixth-background,
  7: $c-seventh-background
);

// $articles-rotation-color-list: (
//   1: $c-dark-pink,
//   2: $c-pink,
//   3: $c-light-ocre,
//   4: $c-ocre,
//   5: $c-very-light-duck-blue,
//   6: $c-light-duck-blue,
//   7: $c-duck-blue
// );
