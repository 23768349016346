.blog-container {
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  position: relative;

  .blog-hero {
    width: 100%;
    height: 100vh;
    margin: auto;
    display: flex;
    flex-direction: column-reverse;
    justify-content: space-between;
    position: relative;
    color: $c-white-aqua-blue;
    background: linear-gradient(137deg, $c-dark-ocre, rgba(0, 102, 255, 0) 70.71%),
      linear-gradient(220deg, $c-light-ocre, rgba(255, 208, 0, 0) 70.71%);
    overflow: hidden;
    padding: 1rem 0;

    .circle-white {
      width: 320px;
      height: 320px;
      background-color: #ffffff18;
      border-radius: 50%;
      position: absolute;
      bottom: -1rem;
      left: 5rem;
      z-index: 0;
    }

    .circle-yellow {
      width: 300px;
      height: 300px;
      background-color: #985e0d5d;
      border-radius: 50%;
      position: absolute;
      bottom: -5rem;
      left: -1rem;
      z-index: 0;
    }

    .image-container {
      width: 220px;
      height: 200px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      z-index: 10;

      .painting-image {
        width: 100%;
        height: 100%;
      }
    }

    .text-container {
      width: 100%;
      margin: 5rem auto 0 auto;
      display: flex;
      flex-direction: column;
      justify-content: center;
      z-index: 30;

      .title {
        color: $c-white-aqua-blue;
        font-size: 1.7rem;
        font-family: homemade-apple, sans-serif;
        line-height: 2.2rem;
        margin-bottom: 1rem;
        text-align: center;

        .span-blog {
          font-family: amatic-bold, sans-serif;
          font-size: 4rem;
          color: $c-dark-pink;
          text-shadow: 0px 2px 2px $c-dark-pink;
        }
      }

      .text {
        width: 95%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        font-size: 1rem;
        line-height: 1rem;
        font-family: barlow-italic, sans-serif;
        color: $c-dark-straw-pink;
        text-align: center;
        margin: 0 auto;
      }
    }
  }

  .blog-content {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
    padding: 3rem 0 0 0;

    .message {
      width: 100%;
      font-family: homemade-apple, sans-serif;
      color: $c-light-duck-blue;
      font-size: 1rem;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
  }

  .key-image {
    width: 220px;
    height: 270px;
    position: absolute;
    bottom: 50px;
    left: 0;
  }
}

@media (min-width: 360px) {
  .blog-container {
    .blog-hero {
      padding: 1.5rem 0;

      .circle-white {
        width: 350px;
        height: 350px;
        bottom: -1rem;
        left: 5rem;
      }

      .circle-yellow {
        width: 320px;
        height: 320px;
        bottom: -3rem;
        left: -1rem;
      }

      .image-container {
        width: 250px;
        height: 220px;
      }

      .text-container {
        margin: 4rem auto 0 auto;

        .title {
          font-size: 1.8rem;
          line-height: 3rem;
          margin-bottom: 1rem;

          .span-blog {
            font-size: 4rem;
          }
        }

        .text {
          font-size: 1.1rem;
          line-height: 1.2rem;
          margin: 0 auto;
        }
      }
    }
  }
}

@media (min-width: 375px) {
  .blog-container {
    .blog-hero {
      .text-container {
        .title {
          font-size: 2rem;
        }
      }
    }
  }
}

@media (min-width: 375px) and (min-height: 812px) {
  .blog-container {
    .blog-hero {
      padding: 2rem 0;

      .circle-white {
        width: 370px;
        height: 370px;
      }

      .circle-yellow {
        width: 350px;
        height: 350px;
        bottom: -2rem;
      }

      .image-container {
        width: 300px;
        height: 270px;
      }

      .text-container {
        .title {
          margin-bottom: 4rem;
        }
      }
    }
  }
}

@media (min-width: 414px) {
  .blog-container {
    .blog-hero {
      .circle-white {
        width: 350px;
        height: 350px;
        bottom: -1rem;
        left: 5rem;
      }

      .circle-yellow {
        width: 340px;
        height: 340px;
        bottom: -3rem;
        left: -1rem;
      }

      .image-container {
        width: 300px;
        height: 270px;
      }

      .text-container {
        .title {
          font-size: 2.2rem;
          line-height: 3.3rem;
          margin-bottom: 1.5rem;

          .span-blog {
            font-size: 5rem;
          }
        }

        .text {
          font-size: 1.2rem;
          line-height: 1.3rem;
        }
      }
    }

    .blog-content {
      .message {
        font-size: 1.1rem;
      }
    }
  }
}

//* -------------------------------------------------------------------------------
//* ------------------------------ TABLET ----------------------------------------
//* -------------------------------------------------------------------------------

@media (min-width: 599px) {
  .blog-container {
    .blog-hero {
      .circle-white {
        width: 600px;
        height: 600px;
        bottom: -1rem;
        left: 10rem;
      }

      .circle-yellow {
        width: 550px;
        height: 550px;
        bottom: -4rem;
        left: -1rem;
      }

      .image-container {
        width: 550px;
        height: 500px;
      }

      .text-container {
        width: 85%;
        margin: 5rem auto 0 auto;

        .title {
          font-size: 3rem;
          line-height: 4rem;
          margin-bottom: 2rem;

          .span-blog {
            font-size: 7rem;
          }
        }

        .text {
          font-size: 1.5rem;
          line-height: 1.8rem;
          margin: 0 auto;
        }
      }
    }

    .blog-content {
      .message {
        font-size: 1.7rem;
      }
    }

    .key-image {
      width: 300px;
      height: 350px;
    }
  }
}

@media (min-width: 899px) {
  .blog-container {
    .blog-hero {
      .circle-white {
        width: 800px;
        height: 800px;
        bottom: -1rem;
        left: 10rem;
      }

      .circle-yellow {
        width: 700px;
        height: 700px;
        bottom: -4rem;
        left: -1rem;
      }

      .image-container {
        width: 700px;
        height: 600px;
      }

      .text-container {
        width: 85%;
        margin: 5rem auto 0 auto;

        .title {
          font-size: 3.5rem;
          line-height: 5rem;
          margin-bottom: 2rem;

          .span-blog {
            font-size: 9rem;
          }
        }

        .text {
          width: 80%;
          font-size: 1.8rem;
          line-height: 2rem;
          margin: 0 auto;
        }
      }
    }

    .blog-content {
      padding: 5rem 0 0 0;
      .message {
        font-size: 2rem;
      }
    }

    .key-image {
      width: 380px;
      height: 450px;
    }
  }
}

//* -------------------------------------------------------------------------------
//* ------------------------------ DESKTOP ----------------------------------------
//* -------------------------------------------------------------------------------

@media (min-width: 1199px) {
  .blog-container {
    .blog-hero {
      flex-direction: row;
      padding: 0 1rem;

      .circle-white {
        width: 800px;
        height: 800px;
        bottom: -6rem;
        left: 2rem;
      }

      .circle-yellow {
        width: 700px;
        height: 700px;
        bottom: -8rem;
        left: -7rem;
      }

      .image-container {
        width: 750px;
        height: 700px;

        .painting-image {
          padding-top: 20rem;
        }
      }

      .text-container {
        width: 85%;
        margin: 5rem auto 0 auto;

        .title {
          font-size: 4rem;
          line-height: 5.5rem;
          margin-bottom: 2rem;

          .span-blog {
            font-size: 9rem;
          }
        }

        .text {
          width: 80%;
          font-size: 1.8rem;
          line-height: 2rem;
          margin: 0 auto;
        }
      }
    }

    .blog-content {
      padding: 5rem 0 0 0;

      .message {
        font-size: 2rem;
      }
    }

    .key-image {
      width: 280px;
      height: 350px;
      bottom: 0;
      left: 0;
    }
  }
}

@media (min-width: 1399px) {
  .blog-container {
    .key-image {
      width: 300px;
      height: 380px;
      bottom: 0;
    }
  }
}

@media (min-width: 1599px) {
  .blog-container {
    .key-image {
      width: 380px;
      height: 450px;
      bottom: 0;
    }
  }
}

@media (min-width: 1920px) {
  .blog-container {
    .blog-hero {
      .circle-white {
        width: 1100px;
        height: 1100px;
        bottom: -10rem;
        left: 2rem;
      }

      .circle-yellow {
        width: 1000px;
        height: 1000px;
        bottom: -12rem;
        left: -10rem;
      }

      .image-container {
        width: 40%;
        height: 100%;
        z-index: 10;

        .painting-image {
          width: 100%;
        }
      }

      .text-container {
        width: 60%;
        height: 75%;
        margin: auto;
        justify-content: flex-start;
        padding: 2rem 0rem 5rem 2rem;

        .title {
          font-size: 6rem;
          line-height: 8rem;
          margin-bottom: 5rem;

          .span-blog {
            font-size: 12rem;
          }
        }

        .text {
          width: 90%;
          font-size: 2rem;
          line-height: 2.7rem;
        }
      }
    }

    .blog-content {
      padding: 2rem 0 5rem 0;

      .grid {
        width: 100%;
        height: 100%;
        display: grid;
        grid-template-columns: repeat(3, 33.333333%);
        grid-template-rows: auto;
        grid-auto-flow: row;
        gap: 15px;
        justify-content: center;
        align-content: start;
        justify-items: center;

        .article {
          width: 100%;
          height: 600px;
          cursor: pointer;
          overflow: hidden;
          position: relative;
          box-shadow: $c-very-dark-duck-blue 0px 5px 30px -30px, $c-very-dark-duck-blue 0px 20px 30px -15px;

          &:hover .article-image {
            transform: scale(1.05);
            z-index: 2;
          }

          .link {
            width: 100%;
            text-decoration: none;
            z-index: 2;
            margin: auto;

            .article-image {
              display: flex;
              flex-direction: column;
              align-items: center;
              justify-content: space-between;
              transition: transform 0.5s ease-in-out;
              cursor: pointer;
              padding: 10rem 1rem;
              position: relative;
              margin: auto;

              &:hover {
                transform: scale(1.05);
                z-index: 2;
              }

              .title {
                width: 70%;
                z-index: 2;
                font-family: amatic-bold, sans-serif;
                font-size: 3rem;
                color: $c-very-light-aqua-blue;
                text-shadow: 0px 0px 10px $c-dark-duck-blue;
                text-align: center;
              }

              .text {
                width: 70%;
                text-align: center;
                z-index: 2;
                font-family: barlow-regular, sans-serif;
                font-size: 1.3rem;
                line-height: 1.5rem;
                color: $c-white-aqua-blue;
                text-shadow: 0px 0px 15px $c-dark-duck-blue;
              }

              .more {
                opacity: 0;
                z-index: 2;
                text-align: center;
                color: $c-white-aqua-blue;
                font-size: 1.3rem;
                font-family: barlow-regular, sans-serif;
                text-shadow: 0px 0px 10px $c-dark-duck-blue;

                .fa-eye {
                  font-size: 2rem;
                  margin-top: 1rem;
                  filter: drop-shadow(1px 1px 1px $c-dark-duck-blue);
                }
              }

              &:hover > .more {
                opacity: 1;
              }
            }
          }

          &::after {
            content: '';
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            opacity: 1;
            z-index: 1;
            transition: opacity 1s;
          }

          @each $index, $background in $articles-rotation-background-list {
            &:nth-child(7n + #{$index})::after {
              width: 100%;
              height: 101%;
              background: $background;
              background-repeat: no-repeat;
              background-size: cover;
              background-position: center;
            }
          }

          &:hover::after {
            opacity: 0.2;
          }
        }
      }

      .message {
        height: 300px;
        font-size: 3rem;
      }
    }

    .key-image {
      width: 400px;
      height: 480px;
    }
  }
}
