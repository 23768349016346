.hero {
  width: 100%;
  height: 100vh;
  margin: auto;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  position: relative;
  color: $c-white-aqua-blue;
  background: linear-gradient(137deg, $c-blood-red, rgba(0, 102, 255, 0) 70.71%),
    linear-gradient(220deg, $c-dark-straw-pink, rgba(255, 208, 0, 0) 70.71%);
  overflow: hidden;
  align-items: center;

  .losange-white {
    width: 320px;
    height: 320px;
    background-color: #ffffff18;
    border-radius: 5px;
    position: absolute;
    bottom: -1rem;
    right: 5rem;
    transform: rotate(45deg);
    z-index: 0;
  }

  .losange-red {
    width: 300px;
    height: 300px;
    background-color: #2b040f70;
    border-radius: 5px;
    position: absolute;
    bottom: -5rem;
    right: -1rem;
    transform: rotate(60deg);
    z-index: 0;
  }

  .text-container {
    width: 100%;
    height: 40%;
    margin: auto;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    font-family: barlow-extra-light, sans-serif;
    padding: 5rem 0 0 0;
    z-index: 30;

    .title {
      color: $c-white-aqua-blue;
      font-size: 3.5rem;
      font-family: amatic-bold, sans-serif;
      line-height: 3rem;
      margin-bottom: 1rem;
      text-align: center;

      .span-en {
        font-family: edu-regular, sans-serif;
        font-size: 1.5rem;
      }

      .span-at {
        font-family: edu-regular, sans-serif;
        font-size: 2rem;
      }
    }

    .text {
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      font-size: 2rem;
      line-height: 2.5rem;
      font-family: amatic-bold, sans-serif;
      color: $c-very-dark-duck-blue;
      align-items: flex-end;
      padding-right: 1rem;
    }
  }

  .image-container {
    width: 340px;
    height: auto;
    margin-top: 0rem;
    z-index: 10;

    .image {
      width: 100%;
      height: auto;
    }
  }
}

@media (min-width: 375px) and (min-height: 812px) {
  .hero {
    .text-container {
      .title {
        margin-bottom: 4rem;
      }
    }

    .image-container {
      width: 380px;
    }
  }
}

@media (min-width: 414px) {
  .hero {
    .losange-white {
      width: 360px;
      height: 360px;
    }

    .losange-red {
      width: 340px;
      height: 340px;
    }

    .text-container {
      height: 50%;

      .title {
        font-size: 4rem;
        line-height: 3.5rem;

        .span-en {
          font-size: 2rem;
        }

        .span-at {
          font-size: 2.5rem;
        }
      }

      .text {
        font-size: 3rem;
        line-height: 3.5rem;
      }
    }

    .image-container {
      width: 400px;
      margin-top: -3rem;
    }
  }
}

//* -------------------------------------------------------------------------------
//* ------------------------------ TABLET ----------------------------------------
//* -------------------------------------------------------------------------------

@media (min-width: 599px) {
  .hero {
    padding-top: 3rem;

    .losange-white {
      width: 500px;
      height: 500px;
    }

    .losange-red {
      width: 430px;
      height: 430px;
    }

    .text-container {
      .title {
        font-size: 10rem;
        line-height: 6.5rem;

        .span-en {
          font-size: 3rem;
        }

        .span-at {
          font-size: 4rem;
        }
      }

      .text {
        font-size: 4rem;
        line-height: 4.5rem;
        margin-top: 3rem;
      }
    }

    .image-container {
      width: 550px;
      margin-top: -8rem;
    }
  }
}

@media (min-width: 899px) {
  .hero {
    .losange-white {
      width: 600px;
      height: 600px;
    }

    .losange-red {
      width: 530px;
      height: 530px;
    }

    .text-container {
      .text {
        font-size: 5rem;
        line-height: 5.5rem;
        margin-top: 4rem;
      }
    }

    .image-container {
      width: 750px;
      margin-top: -14rem;
    }
  }
}

//* -------------------------------------------------------------------------------
//* ------------------------------ DESKTOP ----------------------------------------
//* -------------------------------------------------------------------------------

@media (min-width: 1199px) {
  .hero {
    flex-wrap: nowrap;

    .losange-white {
      width: 800px;
      height: 800px;
      bottom: -20rem;
      right: 5rem;
    }

    .losange-red {
      width: 700px;
      height: 700px;
      bottom: -20rem;
      right: -10rem;
    }

    .text-container {
      width: 600px;
      height: 70%;
      padding: 0rem 0rem 5rem 2rem;

      .title {
        font-size: 8rem;
        line-height: 4rem;
        margin-bottom: 5rem;

        .span-en {
          font-size: 2rem;
        }

        .span-at {
          font-size: 3rem;
        }
      }

      .text {
        font-size: 4rem;
        line-height: 4.5rem;
        align-items: flex-start;
        margin-top: 0rem;
      }
    }

    .image-container {
      width: 600px;
      height: auto;
      margin-top: 2rem;
      z-index: 10;

      .image {
        width: 100%;
      }
    }
  }
}

@media (min-width: 1399px) {
  .hero {
    .losange-white {
      width: 900px;
      height: 900px;
      right: -5rem;
    }

    .losange-red {
      width: 800px;
      height: 800px;
    }

    .text-container {
      width: 700px;
      height: 70%;
      padding: 0rem 0rem 5rem 2rem;

      .title {
        font-size: 10rem;
        line-height: 5rem;
        margin-bottom: 2rem;

        .span-en {
          font-size: 3rem;
        }

        .span-at {
          font-size: 4rem;
        }
      }

      .text {
        font-size: 5rem;
        line-height: 5.5rem;
      }
    }

    .image-container {
      width: 40%;

      .image {
        width: 100%;
      }
    }
  }
}

@media (min-width: 1920px) {
  .hero {
    .losange-white {
      width: 1100px;
      height: 1100px;
      bottom: -15rem;
      right: 5rem;
    }

    .losange-red {
      width: 1000px;
      height: 1000px;
      bottom: -15rem;
      right: -10rem;
    }

    .text-container {
      width: 950px;

      .title {
        margin-bottom: 5rem;
        align-self: flex-start;
      }
    }

    .image-container {
      margin-right: 3rem;
    }
  }
}
