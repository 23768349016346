.form-container {
  width: 95%;
  display: flex;
  box-shadow: $c-very-dark-duck-blue 0px 5px 30px -30px, $c-very-dark-duck-blue 0px 20px 30px -15px;
  border-radius: 48% 32% 14% 26% / 21% 21% 39% 39%;
  margin: 2rem auto 0 auto;

  .form {
    width: 80%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    margin: 0 auto;
    color: $c-dark-duck-blue;

    .label {
      white-space: nowrap;
      display: flex;
      text-transform: capitalize;
      font-family: amatic-bold, sans-serif;
      font-size: 1.5rem;
      margin-bottom: 0.5rem;

      .star {
        font-size: 1rem;
      }
    }

    .input {
      width: 100%;
      border-radius: 10px;
      border: none;
      font-family: barlow-regular, sans-serif;
      font-size: 1.2rem;
      color: $c-duck-blue;
      background: url('../../images/wallpaper.svg');
      background-repeat: no-repeat;
      background-size: cover;
      box-shadow: inset 0px 10px 7px -10px $c-very-dark-duck-blue;
      padding: 0.5rem;
      margin: 0 auto;
    }

    .age-input {
      width: 30%;
      text-align: center;
    }

    textarea {
      width: 100%;
      min-height: 300px;
      max-height: 300px;
      overflow-y: scroll;
      outline: none;
      resize: none;
    }

    .input-container {
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      align-items: center;

      .input-content {
        width: 100%;
        height: 70px;
        display: flex;
        flex-direction: column;

        .error-message {
          width: 100%;
          font-family: barlow-regular, sans-serif;
          color: $c-error-message;
          margin-top: 0.5rem;
          font-size: 1rem;
          text-align: center;
        }
      }

      .input-content-testimonial {
        height: 320px;
      }
    }

    .span {
      font-family: barlow-italic, sans-serif;
      font-size: 1rem;
      margin-top: 0.5rem;
      text-align: center;
    }

    .form-button {
      width: 125px;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: transparent;
      font-family: amatic-bold, sans-serif;
      font-size: 2rem;
      color: $c-dark-duck-blue;
      background-color: $c-white-aqua-blue;
      border-radius: 10px;
      margin: 1rem auto 0 auto;
      padding: 0.2rem 1rem;
      transition: box-shadow 0.3s ease-in-out;
      transition: transform 0.2s ease-in-out;
      box-shadow: 0px 10px 10px -10px $c-very-dark-duck-blue;

      .label-btn {
        margin-right: 1rem;
        font-size: 1.5rem;
      }

      .fa-arrow {
        font-size: 1rem;
      }

      &:hover {
        box-shadow: 0px 10px 20px -10px $c-very-dark-duck-blue;
        transform: scale(1.005);
      }

      &:active {
        box-shadow: 0px 10px 10px -10px $c-very-dark-duck-blue;
        transform: scale(1);
      }
    }

    .status-confirmation-message {
      font-family: barlow-italic, sans-serif;
      text-align: center;
      display: block;
      margin: 0 auto;
      font-size: 1.2rem;
      line-height: 1.5rem;
    }
  }

  .confirmation-message-container {
    width: 100%;
    align-self: center;

    .message {
      width: 90%;
      margin: auto;
      text-align: center;
      font-family: barlow-bold-italic, sans-serif;
      font-size: 1.3rem;
      line-height: 2.5rem;
    }
  }
}

.contact-form-container {
  height: 700px;
  background: linear-gradient(217deg, #ffd79faa, rgba(255, 0, 0, 0) 70.71%),
    linear-gradient(127deg, #985e0da9, rgba(0, 255, 0, 0) 70.71%),
    linear-gradient(336deg, #bf7e24ab, rgba(0, 0, 255, 0) 70.71%);
  padding: 2rem 1rem 4rem 1rem;
}

.add-testimonial-form-container {
  height: 810px;
  background: linear-gradient(217deg, #0f5a66a8, rgba(255, 0, 0, 0) 70.71%),
    linear-gradient(127deg, #026979a5, rgba(0, 255, 0, 0) 70.71%),
    linear-gradient(336deg, #012f3689, rgba(0, 0, 255, 0) 70.71%);
  padding: 2.5rem 1rem 4.5rem 1rem;
}

//* -------------------------------------------------------------------------------
//* ------------------------------ TABLET ----------------------------------------
//* -------------------------------------------------------------------------------

@media (min-width: 599px) {
  .form-container {
    width: 90%;

    .form {
      width: 80%;

      .label {
        font-size: 1.7rem;

        .star {
          font-size: 1rem;
        }
      }

      .input {
        width: 70%;
      }

      .age-input {
        width: 20%;
      }

      .input-container {
        .input-content {
          height: 75px;

          .error-message {
            font-size: 1.2rem;
          }
        }

        .input-content-testimonial {
          height: 320px;
        }
      }

      .span {
        margin-top: 0rem;
      }

      .form-button {
        width: 150px;
        margin: 2rem auto 0 auto;
        padding: 0.3rem 1rem;

        .label-btn {
          font-size: 1.7rem;
        }

        .fa-arrow {
          margin-top: 0.2rem;
        }
      }
    }

    .confirmation-message-container {
      width: 100%;
      align-self: center;

      .message {
        width: 90%;
        margin: auto;
        text-align: center;
        font-family: barlow-bold-italic, sans-serif;
        font-size: 1.3rem;
        line-height: 2.5rem;
      }
    }
  }

  .contact-form-container {
    height: 730px;
  }

  .add-testimonial-form-container {
    height: 850px;
  }
}

@media (min-width: 899px) {
  .form-container {
    width: 75%;

    .form {
      width: 90%;

      .label {
        font-size: 1.7rem;
        line-height: 2.5rem;
      }

      .age-input {
        width: 15%;
      }

      .input-container {
        .input-content {
          height: 85px;
        }

        .input-content-testimonial {
          height: 320px;
        }
      }

      .form-button {
        margin: 3rem auto 0 auto;
      }
    }

    .confirmation-message-container {
      width: 100%;
      align-self: center;

      .message {
        width: 90%;
        margin: auto;
        text-align: center;
        font-family: barlow-bold-italic, sans-serif;
        font-size: 1.3rem;
        line-height: 2.5rem;
      }
    }
  }

  .contact-form-container {
    height: 820px;
  }

  .add-testimonial-form-container {
    height: 950px;
  }
}

/*----------------------------------------------------------------------------------
-------------------------------------  DESKTOP -------------------------------------
----------------------------------------------------------------------------------*/

@media (min-width: 1199px) {
  .form-container {
    width: 50%;
    padding: 4rem 1rem;

    .form {
      .label {
        font-size: 2rem;
      }

      .age-input {
        width: 15%;
      }

      .form-button {
        margin: 3rem auto 0 auto;
      }
    }

    .confirmation-message-container {
      width: 100%;
      align-self: center;

      .message {
        width: 90%;
        margin: auto;
        text-align: center;
        font-family: barlow-bold-italic, sans-serif;
        font-size: 1.3rem;
        line-height: 2.5rem;
      }
    }
  }

  .contact-form-container {
    height: 850px;
  }

  .add-testimonial-form-container {
    height: 1000px;
  }
}

@media (min-width: 1399px) {
  .form-container {
    width: 45%;

    .form {
      .age-input {
        width: 10%;
      }
    }

    .confirmation-message-container {
      width: 100%;
      align-self: center;

      .message {
        width: 90%;
        margin: auto;
        text-align: center;
        font-family: barlow-bold-italic, sans-serif;
        font-size: 1.3rem;
        line-height: 2.5rem;
      }
    }
  }
}

@media (min-width: 1920px) {
}
