.home-container {
  height: 100%;
  width: 100%;
  overflow-x: hidden;
  position: relative;

  .outside-image {
    width: 250px;
    height: 300px;
    position: absolute;
    bottom: 50px;
    right: 0;
  }
}

//* -------------------------------------------------------------------------------
//* ------------------------------ TABLET ----------------------------------------
//* -------------------------------------------------------------------------------

@media (min-width: 599px) {
  .home-container {
    .outside-image {
      width: 380px;
      height: 430px;
    }
  }
}

//* -------------------------------------------------------------------------------
//* ------------------------------ DESKTOP ----------------------------------------
//* -------------------------------------------------------------------------------

@media (min-width: 1199px) {
  .home-container {
    .outside-image {
      width: 300px;
      height: 350px;
      bottom: 0;
    }
  }
}

@media (min-width: 1920px) {
  .home-container {
    .outside-image {
      width: 450px;
      height: 500px;
    }
  }
}
