// images "ma formation"

@keyframes zoom {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }
}

// image section session

@keyframes changeBg {
  0% {
    background: url('../../images/illustrations/thinking1.webp');
    background-repeat: no-repeat;
    background-size: contain;
  }
  25% {
    background: url('../../images/illustrations/thinking1.webp');
    background-repeat: no-repeat;
    background-size: contain;
  }
  50% {
    background: url('../../images/illustrations/thinking2.webp');
    background-repeat: no-repeat;
    background-size: contain;
  }
  75% {
    background: url('../../images/illustrations/thinking2.webp');
    background-repeat: no-repeat;
    background-size: contain;
  }
}

// image section AT

@keyframes bounce-at1 {
  0% {
    transform: translateY(0px);
  }
  25% {
    transform: translateY(-60px);
  }
  50% {
    transform: translateY(0px);
  }
  100% {
    transform: translateY(0px);
  }
}

@keyframes bounce-at2 {
  0% {
    transform: translateY(0px);
  }
  25% {
    transform: translateY(-30px);
  }
  50% {
    transform: translateY(0px);
  }
  100% {
    transform: translateY(0px);
  }
}

@keyframes bounce-at3 {
  0% {
    transform: translateY(0px);
  }
  25% {
    transform: translateY(-20px);
  }
  50% {
    transform: translateY(0px);
  }
  100% {
    transform: translateY(0px);
  }
}

// background Formulaire de contact

@keyframes move {
  0% {
    border-radius: 34% 36% 46% 54% / 33% 38% 12% 37%;
  }
  25% {
    border-radius: 48% 32% 14% 26% / 21% 21% 39% 39%;
  }
  50% {
    border-radius: 28% 32% 35% 35% / 21% 42% 38% 29%;
  }
  75% {
    border-radius: 35% 35% 49% 11% / 29% 30% 30% 31%;
  }
  100% {
    border-radius: 34% 16% 46% 54% / 33% 48% 12% 37%;
  }
}

// images émotions

@keyframes lights {
  0% {
    color: $c-dark-aqua-blue;
    text-shadow: 0 0 1em hsla(320, 100%, 50%, 0), 0 0 0.125em hsla(320, 100%, 60%, 0),
      -1em -0.125em 0.5em hsla(40, 100%, 60%, 0), 1em 0.125em 0.5em hsla(200, 100%, 60%, 0);
  }

  5% {
    color: $c-aqua-blue;
    text-shadow: 0 0 1em #ffd79f, 0 0 0.125em hsla(320, 100%, 60%, 0), -0.5em -0.125em 0.25em #012f36,
      0.5em 0.125em 0.25em #026a79;
  }

  10% {
    color: h$c-dark-aqua-blue;
    text-shadow: 0 0 1em #ffd79f, 0 0 0.125em hsla(320, 100%, 90%, 0.5), -0.25em -0.125em 0.125em #012f36,
      0.25em 0.125em 0.125em #026a79;
  }

  15% {
    color: $c-aqua-blue;
    text-shadow: 0 0 1em #ffd79f, 0 0 0.125em hsla(320, 100%, 60%, 0), 0.5em -0.125em 0.25em #012f36,
      -0.5em 0.125em 0.25em #026a79;
  }
  20% {
    color: $c-dark-aqua-blue;
    text-shadow: 0 0 1em hsla(320, 100%, 50%, 0), 0 0 0.125em hsla(320, 100%, 60%, 0),
      1em -0.125em 0.5em hsla(40, 100%, 60%, 0), -1em 0.125em 0.5em hsla(200, 100%, 60%, 0);
  }
  100% {
    color: $c-dark-aqua-blue;
    text-shadow: 0 0 1em hsla(320, 100%, 50%, 0), 0 0 0.125em hsla(320, 100%, 60%, 0),
      1em -0.125em 0.5em hsla(40, 100%, 60%, 0), -1em 0.125em 0.5em hsla(200, 100%, 60%, 0);
  }
}

@keyframes pulse {
  0% {
    transform: scale(1.1);
  }
  2% {
    transform: scale(1);
  }
  4% {
    transform: scale(1.1);
  }
  6% {
    transform: scale(1);
  }
  11% {
    transform: scale(1);
  }
  12% {
    transform: scale(1.1);
  }
  14% {
    transform: scale(1);
  }
  16% {
    transform: scale(1.1);
  }
  18% {
    transform: scale(1);
  }
  100% {
    transform: scale(1);
  }
}

@keyframes shake {
  10% {
    transform: rotate(0deg);
  }
  10.5% {
    transform: rotate(-7deg);
  }
  11% {
    transform: rotate(7deg);
  }
  11.5% {
    transform: rotate(-7deg);
  }
  12% {
    transform: rotate(7deg);
  }
  12.5% {
    transform: rotate(-7deg);
  }
  13% {
    transform: rotate(7deg);
  }
  13.5% {
    transform: rotate(-7deg);
  }
  14% {
    transform: rotate(7deg);
  }
  14.5% {
    transform: rotate(-7deg);
  }
  15% {
    transform: rotate(7deg);
  }
  15.5% {
    transform: rotate(-7deg);
  }
  16% {
    transform: rotate(7deg);
  }
  16.5% {
    transform: rotate(-7deg);
  }
  17% {
    transform: rotate(7deg);
  }
  17.5% {
    transform: rotate(-7deg);
  }
  18% {
    transform: rotate(7deg);
  }
  18.5% {
    transform: rotate(-7deg);
  }
  19% {
    transform: rotate(7deg);
  }
  19.5% {
    transform: rotate(-7deg);
  }
  20% {
    transform: rotate(7deg);
  }
  20.5% {
    transform: rotate(-7deg);
  }
  21% {
    transform: rotate(7deg);
  }
  21.5% {
    transform: rotate(-7deg);
  }
  22% {
    transform: rotate(7deg);
  }
  22.5% {
    transform: rotate(-7deg);
  }
  23% {
    transform: rotate(7deg);
  }
  23.5% {
    transform: rotate(-7deg);
  }
  24% {
    transform: rotate(7deg);
  }
  24.5% {
    transform: rotate(-7deg);
  }
  25% {
    transform: rotate(7deg);
  }
  25.5% {
    transform: rotate(-7deg);
  }
  26% {
    transform: rotate(7deg);
  }
  26.5% {
    transform: rotate(-7deg);
  }
  27% {
    transform: rotate(7deg);
  }
  27.5% {
    transform: rotate(-7deg);
  }
  28% {
    transform: rotate(7deg);
  }
  28.5% {
    transform: rotate(-7deg);
  }
  29% {
    transform: rotate(7deg);
  }
  30% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(0deg);
  }
}

@keyframes wiggle {
  25% {
    transform: translate(0, 0);
  }
  25.5% {
    transform: translate(7px, 0);
  }
  26% {
    transform: translate(-7px, 0);
  }
  26.5% {
    transform: translate(7px, 0);
  }
  27% {
    transform: translate(-7px, 0);
  }
  27.5% {
    transform: translate(7px, 0);
  }
  28% {
    transform: translate(-7px, 0);
  }
  28.5% {
    transform: translate(7px, 0);
  }
  29% {
    transform: translate(-7px, 0);
  }
  29.5% {
    transform: translate(7px, 0);
  }
  30% {
    transform: translate(-7px, 0);
  }
  30.5% {
    transform: translate(7px, 0);
  }
  31% {
    transform: translate(-7px, 0);
  }
  31.5% {
    transform: translate(7px, 0);
  }
  32% {
    transform: translate(-7px, 0);
  }
  32.5% {
    transform: translate(7px, 0);
  }
  33% {
    transform: translate(-7px, 0);
  }
  33.5% {
    transform: translate(7px, 0);
  }
  34% {
    transform: translate(-7px, 0);
  }
  34.5% {
    transform: translate(7px, 0);
  }
  35% {
    transform: translate(-7px, 0);
  }
  35.5% {
    transform: translate(7px, 0);
  }
  36% {
    transform: translate(-7px, 0);
  }
  36.5% {
    transform: translate(7px, 0);
  }
  37% {
    transform: translate(-7px, 0);
  }
  37.5% {
    transform: translate(7px, 0);
  }
  38% {
    transform: translate(-7px, 0);
  }
  38.5% {
    transform: translate(7px, 0);
  }
  39% {
    transform: translate(-7px, 0);
  }
  39.5% {
    transform: translate(7px, 0);
  }
  40% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(0, 0);
  }
}

// images FAQ

@keyframes float {
  0% {
    transform: rotateZ(3deg) translateY(0px);
  }
  50% {
    transform: rotateZ(-3deg) translateY(10px);
  }
  100% {
    transform: rotateZ(3deg) translateY(0px);
  }
}

@keyframes float-reverse {
  0% {
    transform: rotateZ(-3deg) translateY(0px);
  }
  50% {
    transform: rotateZ(3deg) translateY(-10px);
  }
  100% {
    transform: rotateZ(-3deg) translateY(0px);
  }
}

// ronds Landing

@keyframes slide-in {
  0% {
    opacity: 0;
    transform: translateX(500px);
  }
  100% {
    transform: translateX(0px);
    opacity: 1;
  }
}

// .fa-arrow landing

@keyframes slide-right {
  0% {
    transform: translateX(-30%);
  }
  100% {
    transform: translateX(0);
  }
}

// after `contactez-moi`

@keyframes slide-down {
  0% {
    transform: translateY(-5px);
  }
  100% {
    transform: translateY(0);
  }
}

// loader

@keyframes bounce1 {
  0% {
    transform: translateY(0px);
  }
  25% {
    transform: translateY(-40px);
  }
  50% {
    transform: translateY(0px);
  }
  100% {
    transform: translateY(0px);
  }
}

@keyframes bounce2 {
  0% {
    transform: translateY(0px);
  }
  25% {
    transform: translateY(-20px);
  }
  50% {
    transform: translateY(0px);
  }
  100% {
    transform: translateY(0px);
  }
}

@keyframes bounce3 {
  0% {
    transform: translateY(0px);
  }
  25% {
    transform: translateY(-20px);
  }
  50% {
    transform: translateY(0px);
  }
  100% {
    transform: translateY(0px);
  }
}

@media (min-width: 1920px) {
  @keyframes slide-in {
    0% {
      opacity: 0;
      transform: translateX(700px);
    }
    100% {
      transform: translateX(0px);
      opacity: 1;
    }
  }
}

//* --------------------------- LANDSCAPE -----------------------------

// voir à la fin des class landscape
