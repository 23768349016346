.landing-container-landscape-568 {
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background: linear-gradient(207deg, $c-light-duck-blue, rgba(255, 0, 0, 0) 70.71%),
    linear-gradient(336deg, $c-duck-blue, rgba(0, 255, 0, 0) 70.71%),
    linear-gradient(0deg, rgba(246, 246, 246, 0), rgba(0, 0, 255, 0) 70.71%);
  background-repeat: no-repeat;
  background-size: cover;
  overflow: hidden;

  .image {
    width: 250px;
    position: absolute;
    bottom: 0;
    left: -2rem;
  }

  .principal-content {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    overflow: hidden;
    position: relative;

    &::after {
      content: '';
      width: 300px;
      height: 300px;
      background: none;
      box-shadow: 0px 2px 10px $c-very-dark-duck-blue;
      border: solid 0.5rem;
      border-image: linear-gradient($c-ocre, $c-yellow) 90;
      transform: rotate(45deg);
      position: absolute;
      top: 0;
      right: 0%;
    }

    .title-container {
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-end;
      margin: 1rem 2rem 0 auto;

      .title {
        font-family: amatic-bold, sans-serif;
        font-size: 3.5rem;
        color: $c-white-aqua-blue;
        text-shadow: 0px 2px 5px $c-very-dark-duck-blue;
        z-index: 10;
        text-align: center;
      }

      .subtitle {
        font-family: homemade-apple, sans-serif;
        font-size: 1.2rem;
        color: $c-light-ocre;
        margin: 1rem 7% 0 0;
        text-shadow: 0px 1px 2px $c-very-dark-duck-blue;
        z-index: 10;
      }
    }

    .circle {
      width: 65px;
      height: 65px;
      background-repeat: no-repeat;
      background-size: contain;
      animation-duration: 1s;
      animation-name: slide-in-landscape-568;
      animation-iteration-count: 1;
      animation-fill-mode: forwards;
      position: absolute;
      right: -50%;
      z-index: 5;
    }

    #circle1 {
      background-image: url('../../images/circle3.webp');
      bottom: 12%;
      animation-delay: 2s;
    }

    #circle2 {
      background-image: url('../../images/circle2.webp');
      bottom: 28%;
      animation-delay: 1s;
    }

    #circle3 {
      background-image: url('../../images/circle1.webp');
      bottom: 44%;
    }

    .text-container {
      width: 100%;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      z-index: 15;

      .text {
        width: 65%;
        font-family: homemade-apple, sans-serif;
        font-size: 0.9rem;
        line-height: 1.4rem;
        color: $c-very-dark-duck-blue;
        text-align: end;
        margin-left: 0.5rem;
        text-shadow: 0px 1px 1px $c-very-dark-duck-blue;
      }
    }
  }

  .secondary-content {
    .button {
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      justify-content: center;
      font-size: 2rem;
      font-family: amatic-bold, sans-serif;
      color: $c-white-aqua-blue;
      position: absolute;
      right: 0;
      bottom: 0.5rem;
      margin-right: 1rem;
      text-shadow: 0px 1px 2px $c-very-dark-duck-blue;
      z-index: 10000;

      &:hover {
        cursor: pointer;
      }

      .fa-arrow {
        font-size: 1.5rem;
        color: $c-yellow;
        margin: 0.3rem 0 0 1rem;
        text-shadow: 0px 1px 2px $c-very-dark-duck-blue;
        animation: 1.5s ease-in-out 0s infinite running slidein;
        animation-delay: 3s;
      }
    }
  }

  @keyframes slide-in-landscape-568 {
    0% {
      opacity: 0;
    }
    100% {
      right: 20%;
      opacity: 1;
    }
  }
}

.landing-container-landscape-640 {
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background: linear-gradient(207deg, $c-light-duck-blue, rgba(255, 0, 0, 0) 70.71%),
    linear-gradient(336deg, $c-duck-blue, rgba(0, 255, 0, 0) 70.71%),
    linear-gradient(0deg, rgba(246, 246, 246, 0), rgba(0, 0, 255, 0) 70.71%);
  background-repeat: no-repeat;
  background-size: cover;
  overflow: hidden;

  .image {
    width: 280px;
    position: absolute;
    bottom: 0;
    left: -2rem;
  }

  .principal-content {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    overflow: hidden;
    position: relative;

    &::after {
      content: '';
      width: 300px;
      height: 300px;
      background: none;
      box-shadow: 0px 2px 10px $c-very-dark-duck-blue;
      border: solid 0.5rem;
      border-image: linear-gradient($c-ocre, $c-yellow) 90;
      transform: rotate(45deg);
      position: absolute;
      top: 0;
      right: 0%;
    }

    .title-container {
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-end;
      margin: 1rem 2rem 0 auto;

      .title {
        font-family: amatic-bold, sans-serif;
        font-size: 3.5rem;
        color: $c-white-aqua-blue;
        text-shadow: 0px 2px 5px $c-very-dark-duck-blue;
        z-index: 10;
        text-align: center;
      }

      .subtitle {
        font-family: homemade-apple, sans-serif;
        font-size: 1.2rem;
        color: $c-light-ocre;
        margin: 1rem 7% 0 0;
        text-shadow: 0px 1px 2px $c-very-dark-duck-blue;
        z-index: 10;
      }
    }

    .circle {
      width: 65px;
      height: 65px;
      background-repeat: no-repeat;
      background-size: contain;
      animation-duration: 1s;
      animation-name: slide-in-landscape-640;
      animation-iteration-count: 1;
      animation-fill-mode: forwards;
      position: absolute;
      right: -50%;
      z-index: 5;
    }

    #circle1 {
      background-image: url('../../images/circle3.webp');
      bottom: 16%;
      animation-delay: 2s;
    }

    #circle2 {
      background-image: url('../../images/circle2.webp');
      bottom: 30%;
      animation-delay: 1s;
    }

    #circle3 {
      background-image: url('../../images/circle1.webp');
      bottom: 44%;
    }

    .text-container {
      width: 100%;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      z-index: 15;
      margin-top: 5rem;

      .text {
        width: 65%;
        font-family: homemade-apple, sans-serif;
        font-size: 0.9rem;
        line-height: 1.4rem;
        color: $c-very-dark-duck-blue;
        text-align: end;
        margin-left: 0.5rem;
        text-shadow: 0px 1px 1px $c-very-dark-duck-blue;
      }
    }
  }

  .secondary-content {
    .button {
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      justify-content: center;
      font-size: 2rem;
      font-family: amatic-bold, sans-serif;
      color: $c-white-aqua-blue;
      position: absolute;
      right: 0;
      bottom: 0.5rem;
      margin-right: 1rem;
      text-shadow: 0px 1px 2px $c-very-dark-duck-blue;
      z-index: 10000;

      &:hover {
        cursor: pointer;
      }

      .fa-arrow {
        font-size: 1.5rem;
        color: $c-yellow;
        margin: 0.3rem 0 0 1rem;
        text-shadow: 0px 1px 2px $c-very-dark-duck-blue;
        animation: 1.5s ease-in-out 0s infinite running slidein;
        animation-delay: 3s;
      }
    }
  }

  @keyframes slide-in-landscape-640 {
    0% {
      opacity: 0;
    }
    100% {
      right: 18%;
      opacity: 1;
    }
  }
}

.landing-container-landscape-667 {
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background: linear-gradient(207deg, $c-light-duck-blue, rgba(255, 0, 0, 0) 70.71%),
    linear-gradient(336deg, $c-duck-blue, rgba(0, 255, 0, 0) 70.71%),
    linear-gradient(0deg, rgba(246, 246, 246, 0), rgba(0, 0, 255, 0) 70.71%);
  background-repeat: no-repeat;
  background-size: cover;
  overflow: hidden;

  .image {
    width: 280px;
    position: absolute;
    bottom: 0;
    left: -2rem;
  }

  .principal-content {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    overflow: hidden;
    position: relative;

    &::after {
      content: '';
      width: 300px;
      height: 300px;
      background: none;
      box-shadow: 0px 2px 10px $c-very-dark-duck-blue;
      border: solid 0.5rem;
      border-image: linear-gradient($c-ocre, $c-yellow) 90;
      transform: rotate(45deg);
      position: absolute;
      top: 0;
      right: 0%;
    }

    .title-container {
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-end;
      margin: 1rem 2rem 0 auto;

      .title {
        font-family: amatic-bold, sans-serif;
        font-size: 3.5rem;
        color: $c-white-aqua-blue;
        text-shadow: 0px 2px 5px $c-very-dark-duck-blue;
        z-index: 10;
        text-align: center;
      }

      .subtitle {
        font-family: homemade-apple, sans-serif;
        font-size: 1.2rem;
        color: $c-light-ocre;
        margin: 1rem 7% 0 0;
        text-shadow: 0px 1px 2px $c-very-dark-duck-blue;
        z-index: 10;
      }
    }

    .circle {
      width: 65px;
      height: 65px;
      background-repeat: no-repeat;
      background-size: contain;
      animation-duration: 1s;
      animation-name: slide-in-landscape-667;
      animation-iteration-count: 1;
      animation-fill-mode: forwards;
      position: absolute;
      right: -50%;
      z-index: 5;
    }

    #circle1 {
      background-image: url('../../images/circle3.webp');
      bottom: 16%;
      animation-delay: 2s;
    }

    #circle2 {
      background-image: url('../../images/circle2.webp');
      bottom: 30%;
      animation-delay: 1s;
    }

    #circle3 {
      background-image: url('../../images/circle1.webp');
      bottom: 44%;
    }

    .text-container {
      width: 100%;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      z-index: 15;
      margin-top: 5rem;

      .text {
        width: 65%;
        font-family: homemade-apple, sans-serif;
        font-size: 0.9rem;
        line-height: 1.4rem;
        color: $c-very-dark-duck-blue;
        text-align: end;
        margin-left: 0.5rem;
        text-shadow: 0px 1px 1px $c-very-dark-duck-blue;
      }
    }
  }

  .secondary-content {
    .button {
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      justify-content: center;
      font-size: 2rem;
      font-family: amatic-bold, sans-serif;
      color: $c-white-aqua-blue;
      position: absolute;
      right: 0;
      bottom: 0.5rem;
      margin-right: 1rem;
      text-shadow: 0px 1px 2px $c-very-dark-duck-blue;
      z-index: 10000;

      &:hover {
        cursor: pointer;
      }

      .fa-arrow {
        font-size: 1.5rem;
        color: $c-yellow;
        margin: 0.3rem 0 0 1rem;
        text-shadow: 0px 1px 2px $c-very-dark-duck-blue;
        animation: 1.5s ease-in-out 0s infinite running slidein;
        animation-delay: 3s;
      }
    }
  }

  @keyframes slide-in-landscape-667 {
    0% {
      opacity: 0;
    }
    100% {
      right: 17.5%;
      opacity: 1;
    }
  }
}

.landing-container-landscape-700 {
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background: linear-gradient(207deg, $c-light-duck-blue, rgba(255, 0, 0, 0) 70.71%),
    linear-gradient(336deg, $c-duck-blue, rgba(0, 255, 0, 0) 70.71%),
    linear-gradient(0deg, rgba(246, 246, 246, 0), rgba(0, 0, 255, 0) 70.71%);
  background-repeat: no-repeat;
  background-size: cover;
  overflow: hidden;

  .image {
    width: 320px;
    position: absolute;
    bottom: 0;
    left: -2rem;
  }

  .principal-content {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    overflow: hidden;
    position: relative;

    &::after {
      content: '';
      width: 350px;
      height: 350px;
      background: none;
      box-shadow: 0px 2px 10px $c-very-dark-duck-blue;
      border: solid 0.5rem;
      border-image: linear-gradient($c-ocre, $c-yellow) 90;
      transform: rotate(45deg);
      position: absolute;
      top: 6%;
      right: 5%;
    }

    .title-container {
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-end;
      margin: 1rem 2rem 0 auto;

      .title {
        font-family: amatic-bold, sans-serif;
        font-size: 4.5rem;
        color: $c-white-aqua-blue;
        text-shadow: 0px 2px 5px $c-very-dark-duck-blue;
        z-index: 10;
        text-align: center;
      }

      .subtitle {
        font-family: homemade-apple, sans-serif;
        font-size: 1.5rem;
        color: $c-light-ocre;
        margin: 1rem 10% 0 0;
        text-shadow: 0px 1px 2px $c-very-dark-duck-blue;
        z-index: 10;
      }
    }

    .circle {
      width: 85px;
      height: 85px;
      background-repeat: no-repeat;
      background-size: contain;
      animation-duration: 1s;
      animation-name: slide-in-landscape-700;
      animation-iteration-count: 1;
      animation-fill-mode: forwards;
      position: absolute;
      right: -50%;
      z-index: 5;
    }

    #circle1 {
      background-image: url('../../images/circle3.webp');
      bottom: 10%;
      animation-delay: 2s;
    }

    #circle2 {
      background-image: url('../../images/circle2.webp');
      bottom: 26.5%;
      animation-delay: 1s;
    }

    #circle3 {
      background-image: url('../../images/circle1.webp');
      bottom: 43%;
    }

    .text-container {
      width: 100%;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      z-index: 15;
      margin-top: 5rem;

      .text {
        width: 55%;
        font-family: homemade-apple, sans-serif;
        font-size: 0.9rem;
        line-height: 1.4rem;
        color: $c-very-dark-duck-blue;
        text-align: end;
        margin-left: 0.5rem;
        text-shadow: 0px 1px 1px $c-very-dark-duck-blue;
      }
    }
  }

  .secondary-content {
    .button {
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      justify-content: center;
      font-size: 2rem;
      font-family: amatic-bold, sans-serif;
      color: $c-white-aqua-blue;
      position: absolute;
      right: 0;
      bottom: 0.5rem;
      margin-right: 1rem;
      text-shadow: 0px 1px 2px $c-very-dark-duck-blue;
      z-index: 10000;

      &:hover {
        cursor: pointer;
      }

      .fa-arrow {
        font-size: 1.5rem;
        color: $c-yellow;
        margin: 0.3rem 0 0 1rem;
        text-shadow: 0px 1px 2px $c-very-dark-duck-blue;
        animation: 1.5s ease-in-out 0s infinite running slidein;
        animation-delay: 3s;
      }
    }
  }

  @keyframes slide-in-landscape-700 {
    0% {
      opacity: 0;
    }
    100% {
      right: 23.5%;
      opacity: 1;
    }
  }
}

.landing-container-landscape-736 {
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background: linear-gradient(207deg, $c-light-duck-blue, rgba(255, 0, 0, 0) 70.71%),
    linear-gradient(336deg, $c-duck-blue, rgba(0, 255, 0, 0) 70.71%),
    linear-gradient(0deg, rgba(246, 246, 246, 0), rgba(0, 0, 255, 0) 70.71%);
  background-repeat: no-repeat;
  background-size: cover;
  overflow: hidden;

  .image {
    width: 320px;
    position: absolute;
    bottom: 0;
    left: -2rem;
  }

  .principal-content {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    overflow: hidden;
    position: relative;

    &::after {
      content: '';
      width: 350px;
      height: 350px;
      background: none;
      box-shadow: 0px 2px 10px $c-very-dark-duck-blue;
      border: solid 0.5rem;
      border-image: linear-gradient($c-ocre, $c-yellow) 90;
      transform: rotate(45deg);
      position: absolute;
      top: 6%;
      right: 5%;
    }

    .title-container {
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-end;
      margin: 1rem 2rem 0 auto;

      .title {
        font-family: amatic-bold, sans-serif;
        font-size: 4.5rem;
        color: $c-white-aqua-blue;
        text-shadow: 0px 2px 5px $c-very-dark-duck-blue;
        z-index: 10;
        text-align: center;
      }

      .subtitle {
        font-family: homemade-apple, sans-serif;
        font-size: 1.5rem;
        color: $c-light-ocre;
        margin: 1rem 10% 0 0;
        text-shadow: 0px 1px 2px $c-very-dark-duck-blue;
        z-index: 10;
      }
    }

    .circle {
      width: 85px;
      height: 85px;
      background-repeat: no-repeat;
      background-size: contain;
      animation-duration: 1s;
      animation-name: slide-in-landscape-736;
      animation-iteration-count: 1;
      animation-fill-mode: forwards;
      position: absolute;
      right: -50%;
      z-index: 5;
    }

    #circle1 {
      background-image: url('../../images/circle3.webp');
      bottom: 10%;
      animation-delay: 2s;
    }

    #circle2 {
      background-image: url('../../images/circle2.webp');
      bottom: 26.5%;
      animation-delay: 1s;
    }

    #circle3 {
      background-image: url('../../images/circle1.webp');
      bottom: 43%;
    }

    .text-container {
      width: 100%;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      z-index: 15;
      margin-top: 5rem;

      .text {
        width: 50%;
        font-family: homemade-apple, sans-serif;
        font-size: 0.9rem;
        line-height: 1.4rem;
        color: $c-very-dark-duck-blue;
        text-align: end;
        margin-left: 0.5rem;
        text-shadow: 0px 1px 1px $c-very-dark-duck-blue;
      }
    }
  }

  .secondary-content {
    .button {
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      justify-content: center;
      font-size: 2rem;
      font-family: amatic-bold, sans-serif;
      color: $c-white-aqua-blue;
      position: absolute;
      right: 0;
      bottom: 0.5rem;
      margin-right: 1rem;
      text-shadow: 0px 1px 2px $c-very-dark-duck-blue;
      z-index: 10000;

      &:hover {
        cursor: pointer;
      }

      .fa-arrow {
        font-size: 1.5rem;
        color: $c-yellow;
        margin: 0.3rem 0 0 1rem;
        text-shadow: 0px 1px 2px $c-very-dark-duck-blue;
        animation: 1.5s ease-in-out 0s infinite running slidein;
        animation-delay: 3s;
      }
    }
  }

  @keyframes slide-in-landscape-736 {
    0% {
      opacity: 0;
    }
    100% {
      right: 23%;
      opacity: 1;
    }
  }
}

.landing-container-landscape-740 {
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background: linear-gradient(207deg, $c-light-duck-blue, rgba(255, 0, 0, 0) 70.71%),
    linear-gradient(336deg, $c-duck-blue, rgba(0, 255, 0, 0) 70.71%),
    linear-gradient(0deg, rgba(246, 246, 246, 0), rgba(0, 0, 255, 0) 70.71%);
  background-repeat: no-repeat;
  background-size: cover;
  overflow: hidden;

  .image {
    width: 320px;
    position: absolute;
    bottom: 0;
    left: -2rem;
  }

  .principal-content {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    overflow: hidden;
    position: relative;

    &::after {
      content: '';
      width: 350px;
      height: 350px;
      background: none;
      box-shadow: 0px 2px 10px $c-very-dark-duck-blue;
      border: solid 0.5rem;
      border-image: linear-gradient($c-ocre, $c-yellow) 90;
      transform: rotate(45deg);
      position: absolute;
      top: 6%;
      right: 5%;
    }

    .title-container {
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-end;
      margin: 1rem 2rem 0 auto;

      .title {
        font-family: amatic-bold, sans-serif;
        font-size: 4.5rem;
        color: $c-white-aqua-blue;
        text-shadow: 0px 2px 5px $c-very-dark-duck-blue;
        z-index: 10;
        text-align: center;
      }

      .subtitle {
        font-family: homemade-apple, sans-serif;
        font-size: 1.5rem;
        color: $c-light-ocre;
        margin: 1rem 10% 0 0;
        text-shadow: 0px 1px 2px $c-very-dark-duck-blue;
        z-index: 10;
      }
    }

    .circle {
      width: 85px;
      height: 85px;
      background-repeat: no-repeat;
      background-size: contain;
      animation-duration: 1s;
      animation-name: slide-in-landscape-740;
      animation-iteration-count: 1;
      animation-fill-mode: forwards;
      position: absolute;
      right: -50%;
      z-index: 5;
    }

    #circle1 {
      background-image: url('../../images/circle3.webp');
      bottom: 2%;
      animation-delay: 2s;
    }

    #circle2 {
      background-image: url('../../images/circle2.webp');
      bottom: 21%;
      animation-delay: 1s;
    }

    #circle3 {
      background-image: url('../../images/circle1.webp');
      bottom: 39%;
    }

    .text-container {
      width: 100%;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      z-index: 15;
      margin-top: 5rem;

      .text {
        width: 50%;
        font-family: homemade-apple, sans-serif;
        font-size: 0.9rem;
        line-height: 1.4rem;
        color: $c-very-dark-duck-blue;
        text-align: end;
        margin-left: 0.5rem;
        text-shadow: 0px 1px 1px $c-very-dark-duck-blue;
      }
    }
  }

  .secondary-content {
    .button {
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      justify-content: center;
      font-size: 2rem;
      font-family: amatic-bold, sans-serif;
      color: $c-white-aqua-blue;
      position: absolute;
      right: 0;
      bottom: 0.5rem;
      margin-right: 1rem;
      text-shadow: 0px 1px 2px $c-very-dark-duck-blue;
      z-index: 10000;

      &:hover {
        cursor: pointer;
      }

      .fa-arrow {
        font-size: 1.5rem;
        color: $c-yellow;
        margin: 0.3rem 0 0 1rem;
        text-shadow: 0px 1px 2px $c-very-dark-duck-blue;
        animation: 1.5s ease-in-out 0s infinite running slidein;
        animation-delay: 3s;
      }
    }
  }

  @keyframes slide-in-landscape-740 {
    0% {
      opacity: 0;
    }
    100% {
      right: 23%;
      opacity: 1;
    }
  }
}

.landing-container-landscape-812 {
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background: linear-gradient(207deg, $c-light-duck-blue, rgba(255, 0, 0, 0) 70.71%),
    linear-gradient(336deg, $c-duck-blue, rgba(0, 255, 0, 0) 70.71%),
    linear-gradient(0deg, rgba(246, 246, 246, 0), rgba(0, 0, 255, 0) 70.71%);
  background-repeat: no-repeat;
  background-size: cover;
  overflow: hidden;

  .image {
    width: 300px;
    position: absolute;
    bottom: 0;
    left: -2rem;
  }

  .principal-content {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    overflow: hidden;
    position: relative;

    &::after {
      content: '';
      width: 350px;
      height: 350px;
      background: none;
      box-shadow: 0px 2px 10px $c-very-dark-duck-blue;
      border: solid 0.5rem;
      border-image: linear-gradient($c-ocre, $c-yellow) 90;
      transform: rotate(45deg);
      position: absolute;
      top: 6%;
      right: 5%;
    }

    .title-container {
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-end;
      margin: 1rem 2rem 0 auto;

      .title {
        font-family: amatic-bold, sans-serif;
        font-size: 4.5rem;
        color: $c-white-aqua-blue;
        text-shadow: 0px 2px 5px $c-very-dark-duck-blue;
        z-index: 10;
        text-align: center;
      }

      .subtitle {
        font-family: homemade-apple, sans-serif;
        font-size: 1.5rem;
        color: $c-light-ocre;
        margin: 1rem 10% 0 0;
        text-shadow: 0px 1px 2px $c-very-dark-duck-blue;
        z-index: 10;
      }
    }

    .circle {
      width: 85px;
      height: 85px;
      background-repeat: no-repeat;
      background-size: contain;
      animation-duration: 1s;
      animation-name: slide-in-landscape-812;
      animation-iteration-count: 1;
      animation-fill-mode: forwards;
      position: absolute;
      right: -50%;
      z-index: 5;
    }

    #circle1 {
      background-image: url('../../images/circle3.webp');
      bottom: 6%;
      animation-delay: 2s;
    }

    #circle2 {
      background-image: url('../../images/circle2.webp');
      bottom: 24.5%;
      animation-delay: 1s;
    }

    #circle3 {
      background-image: url('../../images/circle1.webp');
      bottom: 43%;
    }

    .text-container {
      width: 100%;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      z-index: 15;
      margin-top: 5rem;

      .text {
        width: 50%;
        font-family: homemade-apple, sans-serif;
        font-size: 0.9rem;
        line-height: 1.4rem;
        color: $c-very-dark-duck-blue;
        text-align: end;
        margin-left: 0.5rem;
        text-shadow: 0px 1px 1px $c-very-dark-duck-blue;
      }
    }
  }

  .secondary-content {
    .button {
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      justify-content: center;
      font-size: 2rem;
      font-family: amatic-bold, sans-serif;
      color: $c-white-aqua-blue;
      position: absolute;
      right: 0;
      bottom: 0.5rem;
      margin-right: 1rem;
      text-shadow: 0px 1px 2px $c-very-dark-duck-blue;
      z-index: 10000;

      &:hover {
        cursor: pointer;
      }

      .fa-arrow {
        font-size: 1.5rem;
        color: $c-yellow;
        margin: 0.3rem 0 0 1rem;
        text-shadow: 0px 1px 2px $c-very-dark-duck-blue;
        animation: 1.5s ease-in-out 0s infinite running slidein;
        animation-delay: 3s;
      }
    }
  }

  @keyframes slide-in-landscape-812 {
    0% {
      opacity: 0;
    }
    100% {
      right: 21%;
      opacity: 1;
    }
  }
}

.landing-container-landscape-844 {
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background: linear-gradient(207deg, $c-light-duck-blue, rgba(255, 0, 0, 0) 70.71%),
    linear-gradient(336deg, $c-duck-blue, rgba(0, 255, 0, 0) 70.71%),
    linear-gradient(0deg, rgba(246, 246, 246, 0), rgba(0, 0, 255, 0) 70.71%);
  background-repeat: no-repeat;
  background-size: cover;
  overflow: hidden;

  .image {
    width: 300px;
    position: absolute;
    bottom: 0;
    left: -2rem;
  }

  .principal-content {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    overflow: hidden;
    position: relative;

    &::after {
      content: '';
      width: 350px;
      height: 350px;
      background: none;
      box-shadow: 0px 2px 10px $c-very-dark-duck-blue;
      border: solid 0.5rem;
      border-image: linear-gradient($c-ocre, $c-yellow) 90;
      transform: rotate(45deg);
      position: absolute;
      top: 6%;
      right: 5%;
    }

    .title-container {
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-end;
      margin: 1rem 2rem 0 auto;

      .title {
        font-family: amatic-bold, sans-serif;
        font-size: 4.5rem;
        color: $c-white-aqua-blue;
        text-shadow: 0px 2px 5px $c-very-dark-duck-blue;
        z-index: 10;
        text-align: center;
      }

      .subtitle {
        font-family: homemade-apple, sans-serif;
        font-size: 1.5rem;
        color: $c-light-ocre;
        margin: 1rem 10% 0 0;
        text-shadow: 0px 1px 2px $c-very-dark-duck-blue;
        z-index: 10;
      }
    }

    .circle {
      width: 85px;
      height: 85px;
      background-repeat: no-repeat;
      background-size: contain;
      animation-duration: 1s;
      animation-name: slide-in-landscape-844;
      animation-iteration-count: 1;
      animation-fill-mode: forwards;
      position: absolute;
      right: -50%;
      z-index: 5;
    }

    #circle1 {
      background-image: url('../../images/circle3.webp');
      bottom: 10%;
      animation-delay: 2s;
    }

    #circle2 {
      background-image: url('../../images/circle2.webp');
      bottom: 26.5%;
      animation-delay: 1s;
    }

    #circle3 {
      background-image: url('../../images/circle1.webp');
      bottom: 43%;
    }

    .text-container {
      width: 100%;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      z-index: 15;
      margin-top: 5rem;

      .text {
        width: 50%;
        font-family: homemade-apple, sans-serif;
        font-size: 0.9rem;
        line-height: 1.4rem;
        color: $c-very-dark-duck-blue;
        text-align: end;
        margin-left: 0.5rem;
        text-shadow: 0px 1px 1px $c-very-dark-duck-blue;
      }
    }
  }

  .secondary-content {
    .button {
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      justify-content: center;
      font-size: 2rem;
      font-family: amatic-bold, sans-serif;
      color: $c-white-aqua-blue;
      position: absolute;
      right: 0;
      bottom: 0.5rem;
      margin-right: 1rem;
      text-shadow: 0px 1px 2px $c-very-dark-duck-blue;
      z-index: 10000;

      &:hover {
        cursor: pointer;
      }

      .fa-arrow {
        font-size: 1.5rem;
        color: $c-yellow;
        margin: 0.3rem 0 0 1rem;
        text-shadow: 0px 1px 2px $c-very-dark-duck-blue;
        animation: 1.5s ease-in-out 0s infinite running slidein;
        animation-delay: 3s;
      }
    }
  }

  @keyframes slide-in-landscape-844 {
    0% {
      opacity: 0;
    }
    100% {
      right: 20%;
      opacity: 1;
    }
  }
}

.landing-container-landscape-851 {
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background: linear-gradient(207deg, $c-light-duck-blue, rgba(255, 0, 0, 0) 70.71%),
    linear-gradient(336deg, $c-duck-blue, rgba(0, 255, 0, 0) 70.71%),
    linear-gradient(0deg, rgba(246, 246, 246, 0), rgba(0, 0, 255, 0) 70.71%);
  background-repeat: no-repeat;
  background-size: cover;
  overflow: hidden;

  .image {
    width: 300px;
    position: absolute;
    bottom: 0;
    left: -2rem;
  }

  .principal-content {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    overflow: hidden;
    position: relative;

    &::after {
      content: '';
      width: 350px;
      height: 350px;
      background: none;
      box-shadow: 0px 2px 10px $c-very-dark-duck-blue;
      border: solid 0.5rem;
      border-image: linear-gradient($c-ocre, $c-yellow) 90;
      transform: rotate(45deg);
      position: absolute;
      top: 6%;
      right: 5%;
    }

    .title-container {
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-end;
      margin: 1rem 2rem 0 auto;

      .title {
        font-family: amatic-bold, sans-serif;
        font-size: 4.5rem;
        color: $c-white-aqua-blue;
        text-shadow: 0px 2px 5px $c-very-dark-duck-blue;
        z-index: 10;
        text-align: center;
      }

      .subtitle {
        font-family: homemade-apple, sans-serif;
        font-size: 1.5rem;
        color: $c-light-ocre;
        margin: 1rem 10% 0 0;
        text-shadow: 0px 1px 2px $c-very-dark-duck-blue;
        z-index: 10;
      }
    }

    .circle {
      width: 85px;
      height: 85px;
      background-repeat: no-repeat;
      background-size: contain;
      animation-duration: 1s;
      animation-name: slide-in-landscape-851;
      animation-iteration-count: 1;
      animation-fill-mode: forwards;
      position: absolute;
      right: -50%;
      z-index: 5;
    }

    #circle1 {
      background-image: url('../../images/circle3.webp');
      bottom: 10%;
      animation-delay: 2s;
    }

    #circle2 {
      background-image: url('../../images/circle2.webp');
      bottom: 26.5%;
      animation-delay: 1s;
    }

    #circle3 {
      background-image: url('../../images/circle1.webp');
      bottom: 43%;
    }

    .text-container {
      width: 100%;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      z-index: 15;
      margin-top: 5rem;

      .text {
        width: 50%;
        font-family: homemade-apple, sans-serif;
        font-size: 0.9rem;
        line-height: 1.4rem;
        color: $c-very-dark-duck-blue;
        text-align: end;
        margin-left: 0.5rem;
        text-shadow: 0px 1px 1px $c-very-dark-duck-blue;
      }
    }
  }

  .secondary-content {
    .button {
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      justify-content: center;
      font-size: 2rem;
      font-family: amatic-bold, sans-serif;
      color: $c-white-aqua-blue;
      position: absolute;
      right: 0;
      bottom: 0.5rem;
      margin-right: 1rem;
      text-shadow: 0px 1px 2px $c-very-dark-duck-blue;
      z-index: 10000;

      &:hover {
        cursor: pointer;
      }

      .fa-arrow {
        font-size: 1.5rem;
        color: $c-yellow;
        margin: 0.3rem 0 0 1rem;
        text-shadow: 0px 1px 2px $c-very-dark-duck-blue;
        animation: 1.5s ease-in-out 0s infinite running slidein;
        animation-delay: 3s;
      }
    }
  }

  @keyframes slide-in-landscape-851 {
    0% {
      opacity: 0;
    }
    100% {
      right: 21%;
      opacity: 1;
    }
  }
}

.landing-container-landscape-915 {
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background: linear-gradient(207deg, $c-light-duck-blue, rgba(255, 0, 0, 0) 70.71%),
    linear-gradient(336deg, $c-duck-blue, rgba(0, 255, 0, 0) 70.71%),
    linear-gradient(0deg, rgba(246, 246, 246, 0), rgba(0, 0, 255, 0) 70.71%);
  background-repeat: no-repeat;
  background-size: cover;
  overflow: hidden;

  .image {
    width: 300px;
    position: absolute;
    bottom: 0;
    left: -2rem;
  }

  .principal-content {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    overflow: hidden;
    position: relative;

    &::after {
      content: '';
      width: 350px;
      height: 350px;
      background: none;
      box-shadow: 0px 2px 10px $c-very-dark-duck-blue;
      border: solid 0.5rem;
      border-image: linear-gradient($c-ocre, $c-yellow) 90;
      transform: rotate(45deg);
      position: absolute;
      top: 6%;
      right: 5%;
    }

    .title-container {
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-end;
      margin: 1rem 2rem 0 auto;

      .title {
        font-family: amatic-bold, sans-serif;
        font-size: 4.5rem;
        color: $c-white-aqua-blue;
        text-shadow: 0px 2px 5px $c-very-dark-duck-blue;
        z-index: 10;
        text-align: center;
      }

      .subtitle {
        font-family: homemade-apple, sans-serif;
        font-size: 1.5rem;
        color: $c-light-ocre;
        margin: 1rem 10% 0 0;
        text-shadow: 0px 1px 2px $c-very-dark-duck-blue;
        z-index: 10;
      }
    }

    .circle {
      width: 85px;
      height: 85px;
      background-repeat: no-repeat;
      background-size: contain;
      animation-duration: 1s;
      animation-name: slide-in-landscape-915;
      animation-iteration-count: 1;
      animation-fill-mode: forwards;
      position: absolute;
      right: -50%;
      z-index: 5;
    }

    #circle1 {
      background-image: url('../../images/circle3.webp');
      bottom: 10%;
      animation-delay: 2s;
    }

    #circle2 {
      background-image: url('../../images/circle2.webp');
      bottom: 26.5%;
      animation-delay: 1s;
    }

    #circle3 {
      background-image: url('../../images/circle1.webp');
      bottom: 43%;
    }

    .text-container {
      width: 100%;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      z-index: 15;
      margin-top: 5rem;

      .text {
        width: 50%;
        font-family: homemade-apple, sans-serif;
        font-size: 0.9rem;
        line-height: 1.4rem;
        color: $c-very-dark-duck-blue;
        text-align: end;
        margin-left: 0.5rem;
        text-shadow: 0px 1px 1px $c-very-dark-duck-blue;
      }
    }
  }

  .secondary-content {
    .button {
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      justify-content: center;
      font-size: 2rem;
      font-family: amatic-bold, sans-serif;
      color: $c-white-aqua-blue;
      position: absolute;
      right: 0;
      bottom: 0.5rem;
      margin-right: 1rem;
      text-shadow: 0px 1px 2px $c-very-dark-duck-blue;
      z-index: 10000;

      &:hover {
        cursor: pointer;
      }

      .fa-arrow {
        font-size: 1.5rem;
        color: $c-yellow;
        margin: 0.3rem 0 0 1rem;
        text-shadow: 0px 1px 2px $c-very-dark-duck-blue;
        animation: 1.5s ease-in-out 0s infinite running slidein;
        animation-delay: 3s;
      }
    }
  }

  @keyframes slide-in-landscape-915 {
    0% {
      opacity: 0;
    }
    100% {
      right: 19%;
      opacity: 1;
    }
  }
}

// .landing-container-landscape-1024 {
//   width: 100vw;
//   height: 100vh;
//   display: flex;
//   flex-direction: column;
//   justify-content: space-between;
//   background: linear-gradient(207deg, $c-light-duck-blue, rgba(255, 0, 0, 0) 70.71%),
//     linear-gradient(336deg, $c-duck-blue, rgba(0, 255, 0, 0) 70.71%),
//     linear-gradient(0deg, rgba(246, 246, 246, 0), rgba(0, 0, 255, 0) 70.71%);
//   background-repeat: no-repeat;
//   background-size: cover;
//   overflow: hidden;

//   .image {
//     width: 450px;
//     position: absolute;
//     bottom: 0;
//     left: -2rem;
//   }

//   .principal-content {
//     width: 100%;
//     height: 100%;
//     display: flex;
//     flex-direction: row;
//     flex-wrap: wrap;
//     justify-content: space-between;
//     overflow: hidden;
//     position: relative;

//     &::after {
//       content: '';
//       width: 500px;
//       height: 500px;
//       background: none;
//       box-shadow: 0px 2px 10px $c-very-dark-duck-blue;
//       border: solid 1rem;
//       border-image: linear-gradient($c-ocre, $c-yellow) 90;
//       transform: rotate(45deg);
//       position: absolute;
//       top: 10%;
//       right: 5%;
//     }

//     .title-container {
//       width: 100%;
//       display: flex;
//       flex-direction: column;
//       justify-content: flex-start;
//       align-items: flex-end;
//       margin: 2rem 2rem 0 auto;

//       .title {
//         font-family: amatic-bold, sans-serif;
//         font-size: 6rem;
//         color: $c-white-aqua-blue;
//         text-shadow: 0px 2px 5px $c-very-dark-duck-blue;
//         z-index: 10;
//         text-align: center;
//       }

//       .subtitle {
//         font-family: homemade-apple, sans-serif;
//         font-size: 2rem;
//         color: $c-light-ocre;
//         margin: 1rem 14% 0 0;
//         text-shadow: 0px 1px 2px $c-very-dark-duck-blue;
//         z-index: 10;
//       }
//     }

//     .circle {
//       width: 130px;
//       height: 130px;
//       background-repeat: no-repeat;
//       background-size: contain;
//       animation-duration: 1s;
//       animation-name: slide-in-landscape-1024;
//       animation-iteration-count: 1;
//       animation-fill-mode: forwards;
//       position: absolute;
//       right: -50%;
//       z-index: 5;
//     }

//     #circle1 {
//       background-image: url('../../images/circle3.webp');
//       bottom: 25%;
//       animation-delay: 2s;
//     }

//     #circle2 {
//       background-image: url('../../images/circle2.webp');
//       bottom: 38%;
//       animation-delay: 1s;
//     }

//     #circle3 {
//       background-image: url('../../images/circle1.webp');
//       bottom: 51%;
//     }

//     .text-container {
//       width: 100%;
//       display: flex;
//       justify-content: flex-start;
//       align-items: center;
//       z-index: 15;
//       margin-top: 25rem;

//       .text {
//         width: 50%;
//         font-family: homemade-apple, sans-serif;
//         font-size: 1rem;
//         line-height: 1.5rem;
//         color: $c-very-dark-duck-blue;
//         text-align: end;
//         margin-left: 0.5rem;
//         text-shadow: 0px 1px 1px $c-very-dark-duck-blue;
//       }
//     }
//   }

//   .secondary-content {
//     .button {
//       display: flex;
//       flex-direction: row;
//       flex-wrap: nowrap;
//       justify-content: center;
//       font-size: 2.5rem;
//       font-family: amatic-bold, sans-serif;
//       color: $c-white-aqua-blue;
//       position: absolute;
//       right: 2rem;
//       bottom: 2rem;
//       margin-right: 1rem;
//       text-shadow: 0px 1px 2px $c-very-dark-duck-blue;
//       z-index: 10000;

//       &:hover {
//         cursor: pointer;
//       }

//       .fa-arrow {
//         font-size: 2rem;
//         color: $c-yellow;
//         margin: 0.3rem 0 0 1.5rem;
//         text-shadow: 0px 1px 2px $c-very-dark-duck-blue;
//         animation: 1.5s ease-in-out 0s infinite running slidein;
//         animation-delay: 3s;
//       }
//     }
//   }

//   @keyframes slide-in-landscape-1024 {
//     0% {
//       opacity: 0;
//     }
//     100% {
//       right: 23%;
//       opacity: 1;
//     }
//   }
// }

.landing-container-landscape-1366 {
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background: linear-gradient(207deg, $c-light-duck-blue, rgba(255, 0, 0, 0) 70.71%),
    linear-gradient(336deg, $c-duck-blue, rgba(0, 255, 0, 0) 70.71%),
    linear-gradient(0deg, rgba(246, 246, 246, 0), rgba(0, 0, 255, 0) 70.71%);
  background-repeat: no-repeat;
  background-size: cover;
  overflow: hidden;

  .image {
    width: 600px;
    position: absolute;
    bottom: 0;
    left: -5rem;
  }

  .principal-content {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    overflow: hidden;
    position: relative;

    &::after {
      content: '';
      width: 850px;
      height: 850px;
      background: none;
      box-shadow: 0px 2px 10px $c-very-dark-duck-blue;
      border: solid 1rem;
      border-image: linear-gradient($c-ocre, $c-yellow) 90;
      transform: rotate(45deg);
      position: absolute;
      top: 10%;
      right: -10%;
    }

    .title-container {
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-end;
      margin: 2rem 2rem 0 auto;

      .title {
        font-family: amatic-bold, sans-serif;
        font-size: 10rem;
        color: $c-white-aqua-blue;
        text-shadow: 0px 2px 5px $c-very-dark-duck-blue;
        z-index: 10;
        text-align: center;
      }

      .subtitle {
        font-family: homemade-apple, sans-serif;
        font-size: 3rem;
        color: $c-light-ocre;
        margin: 2rem 5% 0 0;
        text-shadow: 0px 1px 2px $c-very-dark-duck-blue;
        z-index: 10;
      }
    }

    .circle {
      width: 200px;
      height: 200px;
      background-repeat: no-repeat;
      background-size: contain;
      animation-duration: 1s;
      animation-name: slide-in-landscape-1366;
      animation-iteration-count: 1;
      animation-fill-mode: forwards;
      position: absolute;
      right: -50%;
      z-index: 5;
    }

    #circle1 {
      background-image: url('../../images/circle3.webp');
      bottom: 15%;
      animation-delay: 2s;
    }

    #circle2 {
      background-image: url('../../images/circle2.webp');
      bottom: 30%;
      animation-delay: 1s;
    }

    #circle3 {
      background-image: url('../../images/circle1.webp');
      bottom: 45%;
    }

    .text-container {
      width: 100%;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      z-index: 15;
      margin-top: 30rem;

      .text {
        width: 50%;
        font-family: homemade-apple, sans-serif;
        font-size: 1.5rem;
        line-height: 2rem;
        color: $c-very-dark-duck-blue;
        text-align: end;
        margin-left: 0.5rem;
        text-shadow: 0px 1px 1px $c-very-dark-duck-blue;
      }
    }
  }

  .secondary-content {
    .button {
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      justify-content: center;
      font-size: 3rem;
      font-family: amatic-bold, sans-serif;
      color: $c-white-aqua-blue;
      position: absolute;
      right: 2rem;
      bottom: 2rem;
      margin-right: 1rem;
      text-shadow: 0px 1px 2px $c-very-dark-duck-blue;
      z-index: 10000;

      &:hover {
        cursor: pointer;
      }

      .fa-arrow {
        font-size: 2.5rem;
        color: $c-yellow;
        margin: 0.4rem 0 0 1.5rem;
        text-shadow: 0px 1px 2px $c-very-dark-duck-blue;
        animation: 1.5s ease-in-out 0s infinite running slidein;
        animation-delay: 3s;
      }
    }
  }

  @keyframes slide-in-landscape-1366 {
    0% {
      opacity: 0;
    }
    100% {
      right: 13%;
      opacity: 1;
    }
  }
}
