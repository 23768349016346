.add-testimonial-container {
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  position: relative;

  .add-testimonial-hero {
    width: 100%;
    height: 100vh;
    margin: auto;
    display: flex;
    flex-direction: column-reverse;
    justify-content: space-between;
    position: relative;
    color: $c-white-aqua-blue;
    background: linear-gradient(137deg, $c-dark-pink, rgba(0, 102, 255, 0) 70.71%),
      linear-gradient(220deg, $c-pink, rgba(255, 208, 0, 0) 70.71%);
    overflow: hidden;
    padding: 1rem 0;

    .circle-white {
      width: 320px;
      height: 320px;
      background-color: #6c3c4aa1;
      border-radius: 50%;
      position: absolute;
      bottom: -1rem;
      left: 5rem;
      z-index: 0;
    }

    .circle-yellow {
      width: 300px;
      height: 300px;
      background-color: #5b2333a5;
      position: absolute;
      bottom: -5rem;
      left: -3rem;
      z-index: 0;
      transform: rotate(45deg);
    }

    .image-container {
      width: 220px;
      height: 200px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      z-index: 10;
      position: relative;

      .hero-image {
        width: 90%;
        height: auto;
        position: absolute;
        bottom: -1rem;
        left: 0em;
      }
    }

    .text-container {
      width: 100%;
      margin: 5rem auto 0 auto;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      z-index: 30;

      .title {
        color: $c-white-aqua-blue;
        font-size: 1.7rem;
        font-family: homemade-apple, sans-serif;
        line-height: 2.2rem;
        margin-bottom: 1rem;
        text-align: center;
      }

      .text {
        width: 95%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        font-size: 1rem;
        line-height: 1rem;
        font-family: barlow-italic, sans-serif;
        color: $c-dark-duck-blue;
        text-align: center;
        margin: 0 auto;
      }
    }
  }

  .add-testimonial-content {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 5rem 0 0 0;
    font-family: homemade-apple, sans-serif;
    text-align: center;

    //^ style formulaire mutualisé
  }

  .candle-image {
    width: 220px;
    height: auto;
    position: absolute;
    bottom: 50px;
    left: 0;
  }
}

@media (min-width: 360px) {
  .add-testimonial-container {
    .add-testimonial-hero {
      padding: 1.5rem 0;

      .circle-white {
        width: 350px;
        height: 350px;
        bottom: -1rem;
        left: 5rem;
      }

      .circle-yellow {
        width: 300px;
        height: 330px;
        bottom: -3rem;
      }

      .image-container {
        width: 250px;
        height: 220px;
      }

      .text-container {
        margin: 4rem auto 0 auto;

        .title {
          font-size: 1.8rem;
          line-height: 3rem;
          margin-bottom: 1rem;

          .span-blog {
            font-size: 4rem;
          }
        }

        .text {
          font-size: 1.1rem;
          line-height: 1.2rem;
          margin: 0 auto;
        }
      }
    }
  }
}

@media (min-width: 375px) {
  .add-testimonial-container {
    .add-testimonial-hero {
      .text-container {
        .title {
          font-size: 2rem;
        }
      }
    }
  }
}

@media (min-width: 375px) and (min-height: 812px) {
  .add-testimonial-container {
    .add-testimonial-hero {
      padding: 2rem 0;

      .circle-white {
        width: 370px;
        height: 370px;
      }

      .circle-yellow {
        width: 370px;
        height: 370px;
        bottom: -2rem;
      }

      .image-container {
        width: 300px;
        height: 270px;
      }

      .text-container {
        .title {
          margin-bottom: 4rem;
        }
      }
    }
  }
}

@media (min-width: 414px) {
  .add-testimonial-container {
    .add-testimonial-hero {
      .circle-white {
        width: 350px;
        height: 350px;
        bottom: -1rem;
        left: 5rem;
      }

      .circle-yellow {
        width: 370px;
        height: 370px;
        bottom: -3rem;
      }

      .image-container {
        width: 300px;
        height: 270px;
      }

      .text-container {
        .title {
          font-size: 2.2rem;
          line-height: 3.3rem;
          margin-bottom: 1.5rem;

          .span-blog {
            font-size: 5rem;
          }
        }

        .text {
          font-size: 1.2rem;
          line-height: 1.3rem;
        }
      }
    }
  }
}

//* -------------------------------------------------------------------------------
//* ------------------------------ TABLET ----------------------------------------
//* -------------------------------------------------------------------------------

@media (min-width: 599px) {
  .add-testimonial-container {
    .add-testimonial-hero {
      .circle-white {
        width: 600px;
        height: 600px;
        bottom: -1rem;
        left: 10rem;
      }

      .circle-yellow {
        width: 550px;
        height: 550px;
        bottom: -4rem;
        left: -4rem;
      }

      .image-container {
        width: 450px;
        height: 400px;
      }

      .text-container {
        width: 85%;
        margin: 5rem auto 0 auto;

        .title {
          font-size: 3rem;
          line-height: 4rem;
          margin-bottom: 2rem;

          .span-blog {
            font-size: 7rem;
          }
        }

        .text {
          font-size: 1.5rem;
          line-height: 1.8rem;
          margin: 0 auto;
        }
      }
    }

    .add-testimonial-content {
    }

    .candle-image {
      width: 300px;
    }
  }
}

@media (min-width: 899px) {
  .add-testimonial-container {
    .add-testimonial-hero {
      .circle-white {
        width: 800px;
        height: 800px;
        bottom: -1rem;
        left: 10rem;
      }

      .circle-yellow {
        width: 700px;
        height: 700px;
        bottom: -4rem;
      }

      .image-container {
        width: 600px;
        height: 550px;
      }

      .text-container {
        width: 85%;
        margin: 5rem auto 0 auto;

        .title {
          font-size: 3.5rem;
          line-height: 5rem;
          margin-bottom: 2rem;

          .span-blog {
            font-size: 9rem;
          }
        }

        .text {
          width: 80%;
          font-size: 1.8rem;
          line-height: 2rem;
          margin: 0 auto;
        }
      }
    }

    .add-testimonial-content {
    }

    .candle-image {
      width: 350px;
    }
  }
}

//* -------------------------------------------------------------------------------
//* ------------------------------ DESKTOP ----------------------------------------
//* -------------------------------------------------------------------------------

@media (min-width: 1199px) {
  .add-testimonial-container {
    .add-testimonial-hero {
      flex-direction: row;
      padding: 0 1rem;

      .circle-white {
        width: 800px;
        height: 800px;
        bottom: -6rem;
        left: 2rem;
      }

      .circle-yellow {
        bottom: -8rem;
        left: -5rem;
      }

      .image-container {
        width: 750px;
        height: 700px;

        .hero-image {
          left: 1rem;
        }
      }

      .text-container {
        width: 85%;
        // margin: 5rem auto 0 auto;

        .title {
          font-size: 4rem;
          line-height: 5.5rem;
          margin-bottom: 2rem;

          .span-blog {
            font-size: 9rem;
          }
        }

        .text {
          width: 80%;
          font-size: 1.8rem;
          line-height: 2rem;
          margin: 0 auto;
        }
      }
    }

    .add-testimonial-content {
      padding: 5rem 1rem 0 5rem;
    }

    .candle-image {
      width: 250px;
      bottom: 0;
    }
  }
}

@media (min-width: 1399px) {
  .add-testimonial-container {
    .add-testimonial-hero {
      .image-container {
        .hero-image {
          width: 85%;
        }
      }
    }

    .candle-image {
      width: 300px;
    }
  }
}

@media (min-width: 1599px) {
  .add-testimonial-container {
    .add-testimonial-hero {
      .image-container {
        .hero-image {
          width: 80%;
        }
      }
    }

    .candle-image {
      width: 330px;
    }
  }
}

@media (min-width: 1920px) {
  .add-testimonial-container {
    .add-testimonial-hero {
      .circle-white {
        width: 1100px;
        height: 1100px;
        bottom: -10rem;
        left: 2rem;
      }

      .circle-yellow {
        width: 850px;
        height: 850px;
        bottom: -12rem;
      }

      .image-container {
        width: 40%;
        height: 100%;
        z-index: 10;

        .painting-image {
          width: 100%;
        }
      }

      .text-container {
        width: 60%;
        height: 75%;
        margin: auto;
        padding: 2rem 0rem 5rem 2rem;

        .title {
          font-size: 6rem;
          line-height: 8rem;
          margin-bottom: 5rem;

          .span-blog {
            font-size: 12rem;
          }
        }

        .text {
          width: 90%;
          font-size: 2rem;
          line-height: 2.7rem;
        }
      }
    }

    .candle-image {
      width: 400px;
    }
  }
}
