.faq {
  width: 101%;
  height: auto;
  padding-top: 5rem;
  margin-top: 5rem;
  position: relative;

  .title {
    width: 300px;
    font-family: homemade-apple, sans-serif;
    font-size: 2rem;
    line-height: 2.5rem;
    color: $c-light-duck-blue;
    background-color: $c-light-ocre;
    border-radius: 20% 0% 0% 40%;
    padding: 2rem 1rem 2rem 0;
    text-align: end;
    box-shadow: 0px 10px 10px -10px $c-very-dark-duck-blue;
    position: absolute;
    top: -3rem;
    right: 0;
    z-index: 5;
  }

  .title-background {
    width: 300px;
    height: 150px;
    background-color: $c-dark-ocre;
    border-radius: 20% 40% 30% 30%;
    box-shadow: 0px 10px 10px -10px $c-very-dark-duck-blue;
    position: absolute;
    top: 0.5rem;
    right: 1rem;
    z-index: 1;
  }

  .image-door-background {
    width: 300px;
    height: 700px;
    background-image: url('../../images/illustrations/door.png');
    background-repeat: no-repeat;
    background-size: contain;
    position: absolute;
    top: 5rem;
    left: -3rem;
    z-index: 0;

    .image {
      width: 80px;
      height: 100px;
      position: absolute;
    }

    .show {
      top: 10%;
      left: 75%;
    }

    .eyes {
      top: 18%;
      left: 95%;
    }

    .voices {
      top: 35%;
      left: 98%;
    }

    .fire {
      top: 52%;
      left: 95%;
    }

    .smoke {
      top: 51%;
      left: 65%;
    }

    .flood {
      top: 50%;
      left: 38%;
    }

    .breakLegs {
      top: 43%;
      left: 15%;
    }
  }

  .questions-container {
    width: 95%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    text-align: center;
    font-size: 1.1rem;
    line-height: 1.4rem;
    font-family: barlow-italic, sans-serif;
    margin: 0 auto;
    padding: 30rem 0 0rem 0;

    .question-container {
      width: 100%;
      margin: 1rem auto;

      .question {
        width: 75%;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        list-style: none;
        cursor: pointer;
        color: $c-duck-blue;
        text-align: start;

        .question-icon {
          cursor: pointer;
          transition: all 0.4s ease-in-out;
        }
      }

      .question1 {
        background: linear-gradient(217deg, #6c3c4a65, rgba(255, 255, 255, 0) 70.71%),
          linear-gradient(127deg, #45112092, rgba(255, 255, 255, 0) 70.71%),
          linear-gradient(336deg, #a26c7b55, rgba(255, 255, 255, 0) 70.71%);
        padding: 1rem 2rem;
        border-radius: 50% 20% / 30% 40%;
        position: relative;
      }

      .question1:after {
        content: '';
        position: absolute;
        bottom: -23%;
        left: 10%;
        border-top: 20px solid #45112059;
        border-left: 20px solid transparent;
        border-right: 20px solid transparent;
        transform: rotate(5deg);
      }

      .question2 {
        background: linear-gradient(217deg, #db9d4758, rgba(255, 255, 255, 0) 70.71%),
          linear-gradient(127deg, #985e0d9f, rgba(255, 255, 255, 0) 70.71%),
          linear-gradient(336deg, #db9d4739, rgba(255, 255, 255, 0) 70.71%);
        padding: 1rem 2rem;
        border-radius: 10% 40% / 50% 20%;
        position: relative;
      }

      .question2:after {
        content: '';
        position: absolute;
        bottom: -22%;
        left: 30px;
        border-top: 20px solid #985e0d57;
        border-left: 20px solid transparent;
        border-right: 20px solid transparent;
        transform: rotate(5deg);
      }

      .question3 {
        background: linear-gradient(217deg, #0f5a662e, rgba(255, 255, 255, 0) 70.71%),
          linear-gradient(127deg, #0f5a66a8, rgba(255, 255, 255, 0) 70.71%),
          linear-gradient(336deg, #0f5a6620, rgba(255, 255, 255, 0) 70.71%);
        padding: 1rem 2rem;

        border-radius: 30% 10% / 30% 20%;
        position: relative;
      }

      .question3:after {
        content: '';
        position: absolute;
        bottom: -20%;
        left: 30px;
        border-top: 20px solid #0f5a6656;
        border-left: 20px solid transparent;
        border-right: 20px solid transparent;
        transform: rotate(0deg);
      }

      .question4 {
        background: linear-gradient(217deg, #fac17234, rgba(255, 255, 255, 0) 70.71%),
          linear-gradient(127deg, #db9d478e, rgba(255, 255, 255, 0) 70.71%),
          linear-gradient(336deg, #fac17246, rgba(255, 255, 255, 0) 70.71%);
        padding: 1rem 2rem;
        border-radius: 10% 10% / 40% 20%;
        position: relative;
      }

      .question4:after {
        content: '';
        position: absolute;
        bottom: -16%;
        left: 30px;
        border-top: 20px solid #db9d4749;
        border-left: 20px solid transparent;
        border-right: 20px solid transparent;
        transform: rotate(0deg);
      }

      .question5 {
        background: linear-gradient(217deg, #ce95a553, rgba(255, 255, 255, 0) 70.71%),
          linear-gradient(127deg, #6c3c4a9c, rgba(255, 255, 255, 0) 70.71%),
          linear-gradient(336deg, #ce95a549, rgba(255, 255, 255, 0) 70.71%);
        padding: 1rem 2rem;

        border-radius: 10% 20% / 40% 20%;
        position: relative;
      }

      .question5:after {
        content: '';
        position: absolute;
        bottom: -19%;
        left: 30px;
        border-top: 20px solid #6c3c4a4e;
        border-left: 20px solid transparent;
        border-right: 20px solid transparent;
        transform: rotate(3deg);
      }

      .question6 {
        background: linear-gradient(217deg, #0f5a6600, rgba(255, 255, 255, 0) 70.71%),
          linear-gradient(127deg, #012f3681, rgba(255, 255, 255, 0) 70.71%),
          linear-gradient(336deg, #012f366b, rgba(255, 255, 255, 0) 70.71%);
        padding: 1rem 2rem 1.5rem 2rem;
        border-radius: 10% 40% / 50% 20%;
        position: relative;
      }

      .question6:after {
        content: '';
        position: absolute;
        bottom: -11%;
        left: 30px;
        border-top: 20px solid #012f3659;
        border-left: 20px solid transparent;
        border-right: 20px solid transparent;
        transform: rotate(9deg);
      }

      .answer-container {
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: flex-end;

        .answer {
          width: 75%;
          text-align: left;
          color: $c-duck-blue;
          position: relative;
          margin-top: 1rem;
        }

        .answer1 {
          background: linear-gradient(217deg, #6c3c4a65, rgba(255, 255, 255, 0) 70.71%),
            linear-gradient(127deg, #45112092, rgba(255, 255, 255, 0) 70.71%),
            linear-gradient(336deg, #a26c7b55, rgba(255, 255, 255, 0) 70.71%);
          padding: 2rem;
          border-radius: 50% 20% / 10% 40%;
        }

        .answer1:after {
          content: '';
          position: absolute;
          bottom: -7.5%;
          right: 5%;
          border-top: 20px solid #6c3c4a47;
          border-left: 20px solid transparent;
          border-right: 20px solid transparent;
          transform: rotate(-8deg);
        }

        .answer2 {
          background: linear-gradient(217deg, #db9d4758, rgba(255, 255, 255, 0) 70.71%),
            linear-gradient(127deg, #985e0d9f, rgba(255, 255, 255, 0) 70.71%),
            linear-gradient(336deg, #db9d4776, rgba(255, 255, 255, 0) 70.71%);
          padding: 3rem;
          border-radius: 10% 40% / 50% 20%;
        }

        .answer2:after {
          content: '';
          position: absolute;
          bottom: -4.2%;
          right: 10%;
          border-top: 20px solid #db9d4776;
          border-left: 20px solid transparent;
          border-right: 20px solid transparent;
          transform: rotate(0deg);
        }

        .answer3 {
          background: linear-gradient(217deg, #0f5a662e, rgba(255, 255, 255, 0) 70.71%),
            linear-gradient(127deg, #0f5a66a8, rgba(255, 255, 255, 0) 70.71%),
            linear-gradient(336deg, #0f5a6682, rgba(255, 255, 255, 0) 70.71%);
          padding: 3rem;
          border-radius: 10% 20% / 40% 20%;
        }

        .answer3:after {
          content: '';
          position: absolute;
          bottom: -2.5%;
          right: 10%;
          border-top: 20px solid #0f5a6682;
          border-left: 20px solid transparent;
          border-right: 20px solid transparent;
          transform: rotate(0deg);
        }

        .answer4 {
          background: linear-gradient(217deg, #fac17234, rgba(255, 255, 255, 0) 70.71%),
            linear-gradient(127deg, #db9d478e, rgba(255, 255, 255, 0) 70.71%),
            linear-gradient(336deg, #fac172b9, rgba(255, 255, 255, 0) 70.71%);
          padding: 2rem 4rem;
          border-radius: 20% 10% / 40% 20%;
        }

        .answer4:after {
          content: '';
          position: absolute;
          bottom: -1.5%;
          right: 20%;
          border-top: 20px solid #fac172b9;
          border-left: 20px solid transparent;
          border-right: 20px solid transparent;
          transform: rotate(0deg);
        }

        .answer5 {
          background: linear-gradient(217deg, #ce95a553, rgba(255, 255, 255, 0) 70.71%),
            linear-gradient(127deg, #6c3c4a9c, rgba(255, 255, 255, 0) 70.71%),
            linear-gradient(336deg, #ce95a58a, rgba(255, 255, 255, 0) 70.71%);
          padding: 2rem 4rem;
          border-radius: 15% 20% / 40% 20%;
        }

        .answer5:after {
          content: '';
          position: absolute;
          bottom: -2.1%;
          right: 15%;
          border-top: 20px solid #ce95a58a;
          border-left: 20px solid transparent;
          border-right: 20px solid transparent;
          transform: rotate(0deg);
        }

        .answer6 {
          background: linear-gradient(217deg, #0f5a6600, rgba(255, 255, 255, 0) 70.71%),
            linear-gradient(127deg, #012f3681, rgba(255, 255, 255, 0) 70.71%),
            linear-gradient(336deg, #012f366b, rgba(255, 255, 255, 0) 70.71%);
          padding: 3rem 4rem 5rem;
          border-radius: 25% 40% / 50% 20%;
        }

        .answer6:after {
          content: '';
          position: absolute;
          bottom: -2%;
          right: 25%;
          border-top: 20px solid #012f3661;
          border-left: 20px solid transparent;
          border-right: 20px solid transparent;
          transform: rotate(0.2deg);
        }
      }
    }

    details {
      .question-icon {
        width: 25px;
        height: 25px;
      }
    }

    details > summary {
      list-style: none;
    }

    details > summary::-webkit-details-marker {
      display: none;
    }

    details[open] {
      .question-icon {
        width: 25px;
        height: 25px;
        transform: rotate(90deg);
      }
    }

    details[open] summary {
      transition: all 0.4s ease-in-out;
      margin-bottom: 1rem;
    }
  }
}

@media (min-width: 360px) {
  .faq {
    .questions-container {
      .question-container {
        .question1:after {
          bottom: -23.5%;
          left: 10%;
          border-top: 20px solid #45112075;
          transform: rotate(5deg);
        }

        .question2:after {
          bottom: -30%;
          left: 30px;
          border-top: 20px solid #985e0d70;
          transform: rotate(4deg);
        }

        .question3:after {
          bottom: -25%;
          left: 30px;
          border-top: 20px solid #0f5a666e;
          transform: rotate(0deg);
        }

        .question4:after {
          bottom: -20%;
          left: 30px;
          border-top: 20px solid #db9d4760;
          transform: rotate(0deg);
        }

        .question5:after {
          bottom: -18%;
          left: 30px;
          border-top: 20px solid #6c3c4a63;
          transform: rotate(5deg);
        }

        .question6:after {
          bottom: -11%;
          left: 30px;
          border-top: 20px solid #012f365c;
          transform: rotate(9deg);
        }

        .answer-container {
          .answer1:after {
            bottom: -7%;
            right: 5%;
            border-top: 20px solid #6c3c4a62;
            transform: rotate(-7deg);
          }

          .answer2:after {
            bottom: -4.6%;
            right: 10%;
            border-top: 20px solid #db9d4776;
            transform: rotate(0deg);
          }

          .answer3:after {
            bottom: -2.8%;
            right: 10%;
            border-top: 20px solid #0f5a6682;
            transform: rotate(0deg);
          }

          .answer4:after {
            bottom: -1.7%;
            right: 20%;
            border-top: 20px solid #fac172b9;
            transform: rotate(0deg);
          }

          .answer5:after {
            bottom: -2.6%;
            right: 15%;
            border-top: 20px solid #ce95a58a;
            transform: rotate(0deg);
          }

          .answer6:after {
            bottom: -2.2%;
            right: 25%;
            border-top: 20px solid #012f3661;
            transform: rotate(0.2deg);
          }
        }
      }

      details {
        .question-icon {
          width: 25px;
          height: 25px;
        }
      }

      details[open] {
        .question-icon {
          width: 25px;
          height: 25px;
          transform: rotate(90deg);
        }
      }

      details[open] summary {
        transition: all 0.4s ease-in-out;
        margin-bottom: 1rem;
      }
    }
  }
}

@media (min-width: 375px) {
  .faq {
    .questions-container {
      .question-container {
        .question6:after {
          bottom: -14%;
          left: 30px;
          border-top: 20px solid #012f365c;
          transform: rotate(7deg);
        }

        .answer-container {
          .answer1:after {
            bottom: -8%;
            right: 5%;
            border-top: 20px solid #6c3c4a62;
            transform: rotate(-7deg);
          }

          .answer6:after {
            bottom: -2.4%;
            right: 25%;
            border-top: 20px solid #012f3661;
            transform: rotate(0.2deg);
          }
        }
      }

      details {
        .question-icon {
          width: 25px;
          height: 25px;
        }
      }

      details[open] {
        .question-icon {
          width: 25px;
          height: 25px;
          transform: rotate(90deg);
        }
      }

      details[open] summary {
        transition: all 0.4s ease-in-out;
        margin-bottom: 1rem;
      }
    }
  }
}

@media (min-width: 414px) {
  .faq {
    .questions-container {
      .question-container {
        .question6:after {
          bottom: -13%;
        }

        .answer-container {
          .answer1:after {
            bottom: -8.9%;
            transform: rotate(-6deg);
          }

          .answer2:after {
            bottom: -5.3%;
          }

          .answer3:after {
            bottom: -3.4%;
          }

          .answer4:after {
            bottom: -2.2%;
          }

          .answer5:after {
            bottom: -3.2%;
          }

          .answer6:after {
            bottom: -2.9%;
          }
        }
      }
    }
  }
}

//* -------------------------------------------------------------------------------
//* ------------------------------ TABLET ----------------------------------------
//* -------------------------------------------------------------------------------

@media (min-width: 599px) {
  .faq {
    .title {
      width: 450px;
      font-size: 2.5rem;
      line-height: 3rem;
      border-radius: 30% 0% 0% 35%;
      padding: 2rem;
    }

    .title-background {
      width: 450px;
      top: 0;
    }

    .image-door-background {
      width: 450px;
      top: 8rem;

      .image {
        width: 100px;
        height: 120px;
      }

      .show {
        top: 10%;
        left: 75%;
      }

      .eyes {
        top: 23%;
        left: 95%;
      }

      .voices {
        top: 45%;
        left: 98%;
      }

      .fire {
        top: 65%;
        left: 100%;
      }

      .smoke {
        top: 78%;
        left: 65%;
      }

      .flood {
        top: 70%;
        left: 38%;
      }

      .breakLegs {
        top: 65%;
        left: 15%;
      }
    }

    .questions-container {
      width: 85%;
      font-size: 1.3rem;
      line-height: 1.6rem;
      padding: 45rem 0 0rem 0;
      margin-top: 3rem;

      .question-container {
        .question1:after {
          bottom: -26%;
          left: 5%;
          border-top: 20px solid #45112073;
          transform: rotate(8deg);
        }

        .question2:after {
          bottom: -26%;
          border-top: 20px solid #985e0d76;
          transform: rotate(5deg);
        }

        .question3:after {
          bottom: -33%;
          border-top: 20px solid #0f5a667f;
          transform: rotate(2deg);
        }

        .question4:after {
          bottom: -23%;
          border-top: 20px solid #db9d4775;
          transform: rotate(3deg);
        }

        .question5:after {
          bottom: -20%;
          border-top: 20px solid #6c3c4a75;
          transform: rotate(6deg);
        }

        .question6:after {
          bottom: -14%;
          border-top: 20px solid #012f3669;
          transform: rotate(6deg);
        }

        .answer-container {
          .answer1:after {
            bottom: -9%;
            border-top: 20px solid #6c3c4a62;
            transform: rotate(-4deg);
          }

          .answer2:after {
            bottom: -5.8%;
            border-top: 20px solid #db9d478b;
          }

          .answer3:after {
            bottom: -3.8%;
            border-top: 20px solid rgba(15, 90, 102, 0.472);
          }

          .answer4:after {
            bottom: -2.7%;
            border-top: 20px solid #fac172b9;
          }

          .answer5:after {
            bottom: -3.9%;
            border-top: 20px solid #ce95a58a;
          }

          .answer6 {
            padding: 3rem 4rem 5rem 4rem;
            border-radius: 25% 30% 15% 25%;
          }

          .answer6:after {
            bottom: -3.4%;
            border-top: 20px solid #012f3661;
            transform: rotate(0.2deg);
          }
        }
      }

      details {
        .question-icon {
          width: 25px;
          height: 25px;
        }
      }

      details[open] {
        .question-icon {
          width: 25px;
          height: 25px;
          transform: rotate(90deg);
        }
      }

      details[open] summary {
        transition: all 0.4s ease-in-out;
        margin-bottom: 1rem;
      }
    }
  }
}

@media (min-width: 899px) {
  .faq {
    .questions-container {
      .question-container {
        .question1:after {
          bottom: -25.5%;
          transform: rotate(7deg);
        }

        .question2:after {
          bottom: -25.5%;
          transform: rotate(4deg);
        }

        .question3:after {
          bottom: -32.5%;
          border-top: 20px solid #0f5a6693;
          transform: rotate(3deg);
        }

        .question4:after {
          bottom: -22.5%;
        }

        .question5:after {
          bottom: -18.9%;
          border-top: 20px solid #6c3c4a85;
        }

        .question6:after {
          bottom: -12.5%;
          transform: rotate(5deg);
        }

        .answer-container {
          .answer1:after {
            bottom: -12%;
            border-top: 20px solid #6c3c4a74;
            transform: rotate(-3deg);
          }

          .answer2:after {
            bottom: -6.3%;
          }

          .answer3:after {
            bottom: -4.5%;
          }

          .answer4:after {
            bottom: -3.5%;
          }

          .answer5:after {
            bottom: -4.6%;
          }

          .answer6 {
            padding: 3rem 4rem 5rem 4rem;
            border-radius: 25% 30% 25% 25%;
          }

          .answer6:after {
            bottom: -4%;
            right: 23%;
          }
        }
      }

      details {
        .question-icon {
          width: 25px;
          height: 25px;
        }
      }

      details[open] {
        .question-icon {
          width: 25px;
          height: 25px;
          transform: rotate(90deg);
        }
      }

      details[open] summary {
        transition: all 0.4s ease-in-out;
        margin-bottom: 1rem;
      }
    }
  }
}

//* -------------------------------------------------------------------------------
//* ------------------------------ DESKTOP ----------------------------------------
//* -------------------------------------------------------------------------------

@media (min-width: 1199px) {
  .faq {
    padding-top: 5rem;
    margin-top: 5rem;

    &:hover > .image-door-background > .image:nth-child(odd) {
      animation: float 6s running infinite;
    }

    &:hover > .image-door-background > .image:nth-child(even) {
      animation: float-reverse 5s running infinite;
    }

    .title {
      width: 700px;
      font-size: 3rem;
      line-height: 3.5rem;
      padding: 2rem 4rem 2rem 0;
      top: -3rem;
    }

    .title-background {
      width: 700px;
      height: 120px;
      top: 2rem;
      right: 3rem;
    }

    .image-door-background {
      width: 400px;
      height: 700px;
      top: 5rem;
      left: -3rem;
      transition: all 0.5s ease-in-out;

      .show {
        left: 73%;
      }

      .eyes {
        top: 17%;
        left: 100%;
      }

      .voices {
        top: 39%;
        left: 97%;
      }

      .fire {
        top: 63%;
        left: 99%;
      }

      .smoke {
        top: 70%;
        left: 68%;
      }

      .flood {
        top: 75%;
        left: 38%;
      }

      .breakLegs {
        top: 60%;
      }
    }

    .questions-container {
      width: 75%;
      font-size: 1.6rem;
      line-height: 2rem;
      margin: 0 -5rem 0 auto;
      padding: 7rem 0 3rem 0;

      .question-container {
        width: 75%;
        margin: 1rem auto;

        .question {
          width: 55%;
        }

        .question1:after {
          bottom: -13.5%;
          transform: rotate(14deg);
        }

        .question2:after {
          bottom: -23%;
        }

        .question3:after {
          bottom: -19.5%;
          border-top: 20px solid #0f5a6685;
          transform: rotate(1deg);
        }

        .question4:after {
          bottom: -14.5%;
          border-top: 20px solid #db9d476b;
          transform: rotate(1deg);
        }

        .question5:after {
          bottom: -12%;
          border-top: 20px solid #6c3c4a78;
          transform: rotate(7deg);
        }

        .question6:after {
          bottom: -8%;
          transform: rotate(8deg);
        }

        .answer-container {
          .answer {
            width: 50%;
          }

          .answer1:after {
            bottom: -4.5%;
            transform: rotate(-8deg);
          }

          .answer2:after {
            bottom: -3%;
          }

          .answer3:after {
            bottom: -2%;
          }

          .answer4:after {
            bottom: -1.3%;
            right: 20%;
          }

          .answer5:after {
            bottom: -2%;
            right: 15%;
          }

          .answer6 {
            border-radius: 10% 40% / 40% 10%;
          }

          .answer6:after {
            bottom: -1.9%;
            right: 12%;
          }
        }
      }
    }
  }
}

@media (min-width: 1399px) {
  .faq {
    .image-door-background {
      left: 0;

      .image {
        width: 120px;
        height: 150px;
      }

      .show {
        left: 75%;
      }

      .eyes {
        top: 10%;
        left: 105%;
      }

      .voices {
        top: 35%;
        left: 101%;
      }

      .fire {
        top: 63%;
        left: 102%;
      }

      .smoke {
        top: 73%;
        left: 73%;
      }

      .flood {
        top: 75%;
        left: 40%;
      }

      .breakLegs {
        top: 60%;
      }
    }

    .questions-container {
      padding: 7rem 0;

      .question-container {
        .question1:after {
          bottom: -13%;
          border-top: 20px solid #45112082;
          transform: rotate(14deg);
        }

        .question2:after {
          bottom: -23%;
        }

        .question3:after {
          bottom: -20%;
          transform: rotate(1deg);
        }

        .question4:after {
          bottom: -15%;
          transform: rotate(2deg);
        }

        .question5:after {
          bottom: -18%;
        }

        .question6:after {
          bottom: -7.5%;
          transform: rotate(8deg);
        }

        .answer-container {
          .answer1:after {
            bottom: -6%;
            right: 5%;
            border-top: 20px solid #6c3c4a65;
            transform: rotate(-6deg);
          }

          .answer2:after {
            bottom: -3.7%;
          }

          .answer3:after {
            bottom: -2.3%;
            border-top: 20px solid #0f5a6682;
          }

          .answer4:after {
            bottom: -1.5%;
          }

          .answer5:after {
            bottom: -2.2%;
          }

          .answer6:after {
            bottom: -2%;
            right: 10%;
            border-top: 20px solid #012f3661;
          }
        }
      }

      details[open] {
        .question-icon {
          transform: rotate(90deg);
        }
      }

      details[open] summary {
        transition: all 0.4s ease-in-out;
        margin-bottom: 1rem;
      }
    }
  }
}

@media (min-width: 1920px) {
  .faq {
    .image-door-background {
      left: -2rem;
    }

    .questions-container {
      width: 80%;

      .question-container {
        .question1:after {
          bottom: -22.4%;
          left: 5%;
          border-top: 20px solid #45112082;
          transform: rotate(7.5deg);
        }

        .question2:after {
          bottom: -21%;
          left: 30px;
          border-top: 20px solid #985e0d87;
          transform: rotate(4deg);
        }

        .question3:after {
          bottom: -28.695%;
          left: 30px;
          border-top: 20px solid #0f5a669c;
          transform: rotate(3.5deg);
        }

        .question4:after {
          bottom: -18.2%;
          left: 30px;
          border-top: 20px solid #db9d477d;
          transform: rotate(4deg);
        }

        .question5:after {
          bottom: -14.985%;
          left: 30px;
          border-top: 20px solid #6c3c4a87;
          transform: rotate(6.5deg);
        }

        .question6:after {
          bottom: -10%;
          left: 30px;
          border-top: 20px solid #012f366b;
          transform: rotate(6deg);
        }

        .answer-container {
          .answer1:after {
            bottom: -13.5%;
            right: 5%;
            border-top: 30px solid #6c3c4a65;
            transform: rotate(-5deg);
          }

          .answer2:after {
            bottom: -7.1%;
            right: 10%;
            border-top: 30px solid #db9d4776;
            transform: rotate(0deg);
          }

          .answer3:after {
            bottom: -4.6%;
            right: 10%;
            border-top: 30px solid #0f5a6682;
            transform: rotate(0deg);
          }

          .answer4 {
            border-radius: 10% 10% / 40% 20%;
          }

          .answer4:after {
            bottom: -3.4%;
            right: 10%;
            border-top: 30px solid #fac172b9;
            transform: rotate(0deg);
          }

          .answer5:after {
            bottom: -4.9%;
            right: 15%;
            border-top: 30px solid #ce95a58a;
            transform: rotate(0deg);
          }

          .answer6:after {
            bottom: -4.2%;
            right: 10%;
            border-top: 30px solid #012f3661;
            transform: rotate(0.2deg);
          }
        }
      }
    }
  }
}
