.contactAndRdv {
  padding: 3rem 0 8rem 0;
  position: relative;
  margin-top: 10rem;

  .title {
    width: 300px;
    font-family: homemade-apple, sans-serif;
    font-size: 2rem;
    line-height: 2.5rem;
    color: $c-yellow;
    background-color: $c-light-duck-blue;
    border-radius: 20% 0% 0% 40%;
    padding: 2rem 0 2rem 2rem;
    text-align: center;
    box-shadow: 0px 10px 10px -10px $c-very-dark-duck-blue;
    position: absolute;
    top: -2rem;
    right: 0;
    z-index: 5;
  }

  .title-background {
    width: 300px;
    height: 150px;
    background-color: $c-duck-blue;
    border-radius: 35% 40% 30% 30%;
    box-shadow: 0px 10px 10px -10px $c-very-dark-duck-blue;
    position: absolute;
    top: 1.5rem;
    right: 1rem;
    z-index: 3;
  }

  .container {
    width: 100%;
    display: flex;
    flex-direction: column;
    background: linear-gradient(217deg, #0f5a66ad, rgba(255, 0, 0, 0) 70.71%),
      linear-gradient(127deg, #0f5a6600, rgba(0, 255, 0, 0) 70.71%),
      linear-gradient(336deg, #0f5a6632, rgba(0, 0, 255, 0) 70.71%);
    border-radius: 15% 20% 15% 25%;

    .content {
      width: 100%;
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: space-between;
      padding: 8rem 0rem 2rem 0rem;
      position: relative;

      .text-container {
        width: 95%;
        height: auto;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: center;
        font-family: barlow-regular, sans-serif;
        font-size: 1.2rem;
        line-height: 1.5rem;
        margin: 0 auto;

        .image-container {
          width: 300px;
          height: 450px;
          margin-top: 1rem;

          .tresor-map-image {
            width: 100%;
            height: 100%;
            margin: 0 auto;
          }
        }

        .text {
          width: 100%;
          height: auto;
          display: flex;
          flex-direction: column;
          justify-content: space-between;

          .step {
            margin-top: 2rem;

            &:first-child {
              margin-top: 0;
            }

            .way-title {
              color: $c-ocre;
              font-size: 2rem;
              margin-bottom: 0.5rem;
              font-family: amatic-bold, sans-serif;
            }

            .span {
              color: $c-ocre;
            }

            .phone {
              white-space: nowrap;
            }
          }
        }
      }
    }

    .map-container {
      width: 100%;
      height: 300px;
      margin-top: 2rem;

      .map {
        width: 100%;
        height: 100%;
      }
    }
  }
}

//* -------------------------------------------------------------------------------
//* ------------------------------ TABLET ----------------------------------------
//* -------------------------------------------------------------------------------

@media (min-width: 599px) {
  .contactAndRdv {
    .title {
      width: 450px;
      font-size: 2.5rem;
      line-height: 3rem;
      padding: 2rem;
      top: -6rem;
    }

    .title-background {
      width: 450px;
      height: 150px;
      top: 0;
    }

    .container {
      .content {
        width: 100%;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: space-between;
        padding: 8rem 0rem 2rem 0rem;
        position: relative;

        .text-container {
          width: 85%;
          font-size: 1.4rem;
          line-height: 1.6rem;
          text-align: center;

          .image-container {
            width: 400px;
            height: 600px;
          }

          .text {
            .step {
              .way-title {
                font-size: 2.5rem;
                margin-bottom: 1rem;
              }

              .phone {
                pointer-events: none !important;
              }
            }
          }
        }
      }

      .map-container {
        width: 100%;
        height: 350px;
        margin-top: 2rem;

        .map {
          width: 100%;
          height: 100%;
        }
      }
    }
  }
}

@media (min-width: 899px) {
  .contactAndRdv {
    .title {
      top: -4rem;
    }

    .title-background {
      top: 2rem;
    }

    .container {
      .content {
        .text-container {
          padding: 2rem 0;

          .image-container {
            width: 450px;
            height: 650px;
          }
        }
      }

      .map-container {
        width: 100%;
        height: 350px;
        margin-top: 2rem;

        .map {
          width: 100%;
          height: 100%;
        }
      }
    }
  }
}

//* -------------------------------------------------------------------------------
//* ------------------------------ DESKTOP ----------------------------------------
//* -------------------------------------------------------------------------------

@media (min-width: 1199px) {
  .contactAndRdv {
    padding: 7rem 0 10rem 0;
    position: relative;
    margin-top: 12rem;

    &:hover > .container > .content > .form-container {
      animation: move 20s infinite alternate;
    }

    .title {
      width: 750px;
      font-size: 3rem;
      line-height: 4.5rem;
      top: -2rem;
    }

    .title-background {
      width: 750px;
      height: 150px;
      top: 4rem;
      right: 3rem;
    }

    .container {
      border-radius: 30% 20% 15% 25%;

      .content {
        flex-wrap: nowrap;

        .text-container {
          width: 600px;
          height: 600px;
          font-size: 1.4rem;
          line-height: 1.7rem;
          margin-top: 5rem;
          text-align: left;

          .image-container {
            width: 200px;
            height: 350px;
            margin-top: 10rem;
          }

          .text {
            width: 60%;

            .step {
              .way-title {
                font-size: 2.5rem;
              }
            }
          }
        }
      }
    }
  }
}

@media (min-width: 1399px) {
  .contactAndRdv {
    .container {
      .content {
        .text-container {
          width: 700px;

          .image-container {
            width: 250px;
            height: 450px;
            margin-top: 5rem;
          }

          .text {
            width: 60%;
            height: 100%;
          }
        }
      }

      .map-container {
        width: 100%;
        height: 400px;
        margin-top: 2rem;
      }
    }
  }
}

@media (min-width: 1920px) {
  .contactAndRdv {
    margin-top: 15rem;

    .container {
      .content {
        padding: 8rem 2rem 2rem 2rem;

        .text-container {
          width: 900px;
          height: 600px;

          .image-container {
            width: 350px;
            height: 600px;
            margin-top: 0;
          }
        }
      }

      .map-container {
        width: 100%;
        height: 400px;
        margin-top: 2rem;
      }
    }
  }
}
