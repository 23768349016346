.modal {
  width: 100vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  position: fixed;
  top: 0;
  bottom: 0;
  padding: 23rem 0 10rem 0;
  backdrop-filter: blur(20px);
  z-index: 1000;

  .moretext {
    width: 310px;
    min-height: 100vh;
    font-family: barlow-italic, sans-serif;
    font-size: 1.2rem;
    line-height: 1.5rem;
    overflow-y: scroll;
    margin: 0 auto;
    padding-top: 1rem;
    padding-bottom: 10rem;
  }

  .modal-close {
    display: flex;
    flex-direction: row;
    background-color: transparent;
    font-family: homemade-apple, sans-serif;
    font-size: 1.5rem;
    color: $c-white-aqua-blue;
    margin-left: 1.5rem;

    .span-close {
      font-size: 1.5rem;
      margin: -0.3rem 0 0 1rem;
    }
  }
}

.modal-juliette {
  background-color: #5b2333f0;
  right: 0;

  .moretext-juliette {
    color: lighten($c-very-light-pink, 15%);
  }
}

.modal-sophie {
  background-color: #0f5a66f0;
  left: 0;

  .moretext-sophie {
    color: lighten($c-very-light-duck-blue, 70%);
  }
}

//* -------------------------------------------------------------------------------
//* ------------------------------ TABLET ----------------------------------------
//* -------------------------------------------------------------------------------

@media (min-width: 599px) {
  .modal {
    padding-top: 28rem;

    .moretext {
      width: 80%;
      font-size: 1.3rem;
      line-height: 1.6rem;
    }

    .modal-close {
      font-size: 1.5rem;
      margin-left: 5rem;
    }
  }
}

@media (min-width: 899px) {
  .modal {
    padding-top: 33rem;

    .moretext {
      font-size: 1.5rem;
      line-height: 2rem;
    }

    .modal-close {
      font-size: 2.5rem;
      margin-left: 5rem;

      .span-close {
        font-size: 2rem;
        margin: -0.3rem 0 0 2rem;
      }
    }
  }
}

//* -------------------------------------------------------------------------------
//* ------------------------------ DESKTOP ----------------------------------------
//* -------------------------------------------------------------------------------

@media (min-width: 1199px) {
  .modal {
    width: 50vw;
    padding: 8rem 0 0 0;

    .moretext {
      width: 80%;
      min-height: 600px;
      max-height: 90vh;
      padding-top: 0;
    }

    .modal-close {
      height: 90px;
      font-size: 3rem;

      .span-close {
        font-size: 1.3rem;
        margin: 1rem 0 0 1.5rem;
      }
    }
  }
}

@media (min-width: 1599px) {
  .modal {
    top: 0;
    bottom: 0;
    padding: 10rem 0 2rem 0;

    .moretext {
      width: 80%;
      padding-bottom: 5rem;
      margin: 0 auto;
    }

    .modal-close {
      font-size: 3rem;

      .span-close {
        font-size: 1.5rem;
        margin: 1rem 0 0 1.5rem;
      }
    }
  }
}

@media (min-width: 1920px) {
  .modal {
    padding: 5rem 0 2rem 0;

    .moretext {
      width: 90%;
      overflow-y: hidden;
    }

    .modal-close {
      font-size: 2.5rem;

      .span-close {
        font-size: 1.5rem;
        margin: 0.5rem 0 0 1.5rem;
      }
    }
  }
}
