.background {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  overflow: hidden;
  padding: 3rem 0 12rem 0;

  .image {
    transition: all 0.5s ease-in-out;
  }

  .content {
    width: 100%;
    position: relative;

    .title {
      width: 300px;
      font-family: homemade-apple, sans-serif;
      font-size: 2rem;
      color: $c-white-aqua-blue;
      background-color: $c-dark-straw-pink;
      border-radius: 0% 40% 20% 0%;
      padding: 2rem 0 2rem 2rem;
      box-shadow: 0px 10px 10px -10px $c-very-dark-duck-blue;
      position: absolute;
      top: -2rem;
      left: 0;
      z-index: 5;
    }

    .title-background {
      width: 300px;
      height: 100px;
      background-color: $c-light-pink;
      border-radius: 10% 40% / 50% 20%;
      box-shadow: 0px 10px 10px -10px $c-very-dark-duck-blue;
      position: absolute;
      top: -1rem;
      left: 1rem;
      z-index: 0;
    }

    .incircle2 {
      width: 185px;
      height: 200px;
      position: absolute;
      top: 5rem;
      left: -2rem;
      z-index: 5;
    }

    .container {
      width: 100%;
      height: 100%;
      background: linear-gradient(217deg, #bf7e240a, rgba(255, 0, 0, 0) 70.71%),
        linear-gradient(127deg, #fac17200, rgba(0, 255, 0, 0) 70.71%),
        linear-gradient(336deg, #bf7e244a, rgba(0, 0, 255, 0) 70.71%);
      border-radius: 50% 20% / 10% 25%;
      margin: 0 auto;
      padding: 7rem 0;

      .text {
        width: 95%;
        text-align: center;
        font-family: barlow-regular, sans-serif;
        font-size: 1.2rem;
        line-height: 1.5rem;
        color: $c-dark-duck-blue;
        text-align: center;
        z-index: 100;
        margin: 0 auto;
        padding-top: 10rem;
      }

      .second-section {
        .incircle1 {
          width: 200px;
          height: 210px;
          position: absolute;
          top: 5rem;
          right: -2rem;
          z-index: 5;
        }

        .incircle3 {
          width: 250px;
          height: 250px;
          position: absolute;
          top: 37rem;
          right: -5rem;
          z-index: 5;
        }
      }
    }
  }
}

@media (min-width: 375px) {
  .background {
    padding: 3rem 0 9rem 0;

    .content {
      .container {
        .second-section {
          .incircle3 {
            top: 35rem;
          }
        }
      }
    }
  }
}

//* -------------------------------------------------------------------------------
//* ------------------------------ TABLET ----------------------------------------
//* -------------------------------------------------------------------------------

@media (min-width: 599px) {
  .background {
    .content {
      .title {
        width: 450px;
        font-size: 2.5rem;
        padding: 2rem;
        top: -2rem;
      }

      .title-background {
        width: 450px;
        height: 100px;
        top: 0;
      }

      .incircle2 {
        width: 250px;
        height: 260px;
        top: 7rem;
      }

      .container {
        .text {
          width: 65%;
          font-size: 1.3rem;
          line-height: 1.6rem;
          padding-top: 13rem;
        }

        .second-section {
          .incircle1 {
            width: 320px;
            height: 350px;
            top: 0rem;
          }

          .incircle3 {
            width: 280px;
            height: 280px;
            top: 33rem;
            right: -3rem;
            z-index: 5;
          }
        }
      }
    }
  }
}

@media (min-width: 899px) {
  .background {
    .content {
      .incircle2 {
        width: 320px;
        height: 340px;
        top: 8rem;
      }

      .container {
        .text {
          width: 45%;
        }
        .second-section {
          .incircle1 {
            width: 350px;
            height: 360px;
            top: 0rem;
          }

          .incircle3 {
            width: 350px;
            height: 350px;
            top: 30rem;
            right: -3rem;
          }
        }
      }
    }
  }
}

//* -------------------------------------------------------------------------------
//* ------------------------------ DESKTOP ----------------------------------------
//* -------------------------------------------------------------------------------s

@media (min-width: 1199px) {
  .background {
    padding: 7rem 0;

    &:hover > .content > .incircle2 {
      animation: zoom 4s infinite;
      animation-delay: 0s;
    }

    &:hover > .content > .container > .second-section > .incircle1 {
      animation: zoom 4s infinite;
      animation-delay: 1s;
    }

    &:hover > .content > .container > .second-section > .incircle3 {
      animation: zoom 4s infinite;
      animation-delay: 2s;
    }

    .content {
      .title {
        width: 500px;
        font-size: 3rem;
        padding: 2rem 0 2rem 2rem;
        top: -2rem;
      }

      .title-background {
        width: 500px;
        height: 100px;
        top: 0;
        left: 3rem;
      }

      .incircle2 {
        width: 500px;
        height: 520px;
        top: 8rem;
        left: -8rem;
      }

      .container {
        border-radius: 50% 20% / 10% 40%;
        margin: 0 auto;
        padding: 7rem 0;

        .text {
          width: 500px;
          font-size: 1.7rem;
          line-height: 2.3rem;
          margin-top: -5rem;
        }

        .second-section {
          .incircle1 {
            width: 400px;
            height: 410px;
            top: -4rem;
            right: 0rem;
          }

          .incircle3 {
            width: 450px;
            height: 450px;
            top: 30rem;
            right: -5rem;
          }
        }
      }
    }
  }
}

@media (min-width: 1399px) {
  .background {
    .content {
      .incircle2 {
        width: 600px;
        height: 620px;
      }

      .container {
        .text {
          margin-top: -5rem;
        }

        .second-section {
          .incircle1 {
            width: 450px;
            height: 460px;
            top: -7rem;
            right: 2rem;
          }

          .incircle3 {
            width: 450px;
            height: 450px;
            top: 25;
          }
        }
      }
    }
  }
}

@media (min-width: 1920px) {
  .background {
    .content {
      .incircle2 {
        width: 700px;
        height: 720px;
        top: 8rem;
        left: -8rem;
      }

      .container {
        .text {
          margin-top: -5rem;
        }

        .second-section {
          .incircle1 {
            width: 450px;
            height: 460px;
            top: -2rem;
            right: 8rem;
          }

          .incircle3 {
            width: 550px;
            height: 550px;
            top: 23rem;
            right: -5rem;
          }
        }
      }
    }
  }
}
