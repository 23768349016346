.legales-container {
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  position: relative;

  .legales-content {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 10rem 1rem 0 1rem;
    font-family: homemade-apple, sans-serif;
    color: $c-light-duck-blue;
    font-size: 2rem;
    line-height: 2.5rem;
    text-align: center;
  }

  .yoga-image {
    width: 220px;
    height: auto;
    position: absolute;
    bottom: 50px;
    right: 0;
  }
}

//* -------------------------------------------------------------------------------
//* ------------------------------ TABLET ----------------------------------------
//* -------------------------------------------------------------------------------

@media (min-width: 599px) {
  .legales-container {
    .legales-content {
      padding: 20rem 1rem 0 1rem;
      font-size: 2.5rem;
      line-height: 3rem;
    }

    .yoga-image {
      width: 300px;
    }
  }
}

@media (min-width: 899px) {
  .legales-container {
    .legales-content {
      font-size: 2.5rem;
      line-height: 3rem;
    }

    .yoga-image {
      width: 350px;
    }
  }
}

//* -------------------------------------------------------------------------------
//* ------------------------------ DESKTOP ----------------------------------------
//* -------------------------------------------------------------------------------

@media (min-width: 1199px) {
  .legales-container {
    .legales-content {
      padding: 15rem 1rem;
    }

    .yoga-image {
      width: 270px;
      bottom: 0;
    }
  }
}

@media (min-width: 1399px) {
  .legales-container {
    .yoga-image {
      width: 320px;
    }
  }
}

@media (min-width: 1599px) {
  .legales-container {
    .yoga-image {
      width: 350px;
    }
  }
}

@media (min-width: 1920px) {
  .legales-container {
    .yoga-image {
      width: 400px;
    }
  }
}
