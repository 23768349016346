.testimonials {
  width: 100%;
  padding-top: 14rem;
  position: relative;

  .title {
    width: 300px;
    font-family: homemade-apple, sans-serif;
    font-size: 2rem;
    line-height: 2.5rem;
    color: $c-white-aqua-blue;
    background-color: $c-dark-pink;
    border-radius: 0% 30% 60% 0%;
    padding: 2rem 0 2rem 2rem;
    text-align: start;
    box-shadow: 0px 10px 10px -10px $c-very-dark-duck-blue;
    position: absolute;
    top: 5rem;
    left: 0;
    z-index: 5;
  }

  .title-background {
    width: 300px;
    height: 120px;
    background-color: $c-dark-straw-pink;
    border-radius: 0% 30% 50% 20%;
    box-shadow: 0px 10px 10px -10px $c-very-dark-duck-blue;
    position: absolute;
    top: 10.5rem;
    left: 1rem;
    z-index: 0;
  }

  .carrousel-testimonials {
    width: 100%;
    background: linear-gradient(217deg, #2b040f3c, rgba(255, 0, 0, 0) 70.71%),
      linear-gradient(127deg, #6c3c4a00, rgba(0, 255, 0, 0) 70.71%),
      linear-gradient(336deg, #2b040f18, rgba(0, 0, 255, 0) 70.71%);
    border-radius: 50% 20% / 10% 40%;
    padding: 3rem 0 2rem 0;

    .carrousel-item {
      .item {
        width: 420px;
        height: 420px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin: 0 auto 0 -2.5rem;

        .text-container {
          width: 80%;
          height: 60%;
          padding: 0 2.5rem;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;

          .client-infos {
            width: 90%;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            margin: 1rem auto 0.3rem 0.5rem;

            .client-name {
              display: flex;
              flex-direction: row;
              color: $c-white-aqua-blue;
              font-family: barlow-regular, sans-serif;
              font-size: 1rem;

              .name {
                margin-right: 1rem;
              }

              .age {
                font-family: barlow-thin, sans-serif;
              }
            }

            .date {
              font-family: barlow-thin, sans-serif;
              font-size: 1rem;
            }

            .date-juliette {
              color: $c-light-pink;
            }

            .date-remi {
              color: $c-yellow;
            }

            .date-sophie {
              color: lighten($c-very-light-duck-blue, 7%);
            }
          }

          .text {
            width: 90%;
            height: 90%;
            margin: auto 0 auto -0.5rem;
            align-self: center;
            font-size: 0.9rem;
            line-height: 1.1rem;
            font-family: barlow-italic, sans-serif;

            .dots {
              font-size: 2rem;
              line-height: 0.2rem;
            }
          }

          .text-juliette {
            color: lighten($c-very-light-pink, 10%);
          }

          .text-remi {
            color: lighten($c-light-yellow, 12%);
          }

          .text-sophie {
            color: lighten($c-very-light-duck-blue, 65%);
          }

          .seemore-container {
            width: 120px;
            height: auto;
            display: flex;
            flex-direction: row;
            align-items: center;
            align-self: flex-end;
            background-color: transparent;
            font-family: barlow-light, sans-serif;
            font-size: 1rem;
            color: $c-white-aqua-blue;

            .label {
              margin-right: 0.5rem;
            }

            .fa-arrow {
              font-size: 0.8rem;
              margin-top: 5%;
            }
          }
        }
      }

      .item-juliette {
        background-image: url('../../images/bg-testi-red.webp');
        background-repeat: no-repeat;
        background-size: contain;
        background-position: center center;
      }

      .item-remi {
        background-image: url('../../images/bg-testi-yellow.webp');
        background-repeat: no-repeat;
        background-size: contain;
        background-position: center center;

        .seemore-container {
          display: none !important;
        }

        .dots {
          display: none;
        }
      }

      .item-sophie {
        background-image: url('../../images/bg-testi-blue.webp');
        background-repeat: no-repeat;
        background-size: contain;
        background-position: center center;
      }
    }

    .splide__arrow {
      width: 20px;
      height: 20px;
      background-color: $c-straw-red;
    }

    .splide__arrow--prev {
      margin-left: -0.5rem;
    }

    .splide__arrow--next {
      margin-right: -0.5rem;
    }

    .splide__pagination {
      bottom: 1rem;
    }

    .splide__pagination__page {
      border: 5px solid $c-straw-red;
    }

    .splide__arrow svg {
      fill: $c-white-aqua-blue;
      width: 15px;
      height: 15px;
    }

    .add-testimonial-btn {
      width: 220px;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: $c-light-ocre;
      font-family: amatic-bold, sans-serif;
      margin: 0 auto;
      padding: 0.3rem 1rem;
      color: $c-dark-duck-blue;
      border-radius: 40% 25% 30% 25%;
      transition: all 0.3s ease-in-out;

      &:hover {
        box-shadow: $c-very-dark-duck-blue 0px 5px 30px -30px, $c-very-dark-duck-blue 0px 20px 30px -15px;
        transform: scale(1.005);
        background-color: $c-yellow;
        color: $c-duck-blue;
      }

      &:hover > .label {
        font-size: 1.3rem;
      }

      .label {
        font-size: 1.2rem;
        margin-right: 1rem;
      }
    }
  }
}

@media (min-width: 360px) {
  .testimonials {
    .carrousel-testimonials {
      .carrousel-item {
        .item {
          margin: 0 auto 0 -1.5rem;
        }
      }
    }
  }
}

@media (min-width: 375px) {
  .testimonials {
    .carrousel-testimonials {
      .carrousel-item {
        .item {
          margin: 0 auto 0 -1rem;
        }
      }
    }
  }
}

@media (min-width: 390px) {
  .testimonials {
    .carrousel-testimonials {
      .carrousel-item {
        .item {
          margin: 0 auto 0 -0.5rem;
        }
      }
    }
  }
}

@media (min-width: 414px) {
  .testimonials {
    .carrousel-testimonials {
      .carrousel-item {
        .item {
          margin: 0;
        }
      }
    }
  }
}

//* -------------------------------------------------------------------------------
//* ------------------------------ TABLET ----------------------------------------
//* -------------------------------------------------------------------------------

@media (min-width: 599px) {
  .testimonials {
    .title {
      width: 500px;
      font-size: 2.5rem;
      line-height: 3rem;
      border-radius: 0% 30% 40% 0%;
      padding: 2rem;
    }

    .title-background {
      width: 500px;
      top: 9.5rem;
    }

    .carrousel-testimonials {
      padding: 3rem 0 2rem 0;

      .carrousel-item {
        .item {
          width: 470px;
          height: 470px;
          margin: 0 auto 0 -1rem;

          .text-container {
            height: 50%;

            .client-infos {
              width: 85%;
              margin: 0rem auto 0.3rem 1rem;

              .client-name {
                font-size: 1.2rem;
              }

              .date {
                font-size: 1.2rem;
              }
            }

            .text {
              margin: 0 auto 0 0.3rem;
              font-size: 1rem;
              line-height: 1.1rem;
            }
          }
        }

        .item-sophie {
          .seemore-container {
            bottom: -3rem;
          }
        }

        .seemore-container {
          right: 2.5rem;
        }
      }

      .splide__arrow {
        width: 30px;
        height: 30px;
        background-color: $c-straw-red;
      }

      .splide__arrow--prev {
        margin-left: -1rem;
      }

      .splide__arrow--next {
        margin-right: -1rem;
      }

      .splide__arrow svg {
        fill: $c-white-aqua-blue;
        width: 20px;
        height: 20px;
      }
    }
  }
}

@media (min-width: 899px) {
  .testimonials {
    .seemore-container {
      right: 1rem;
      bottom: -1rem;
      font-size: 1.2rem;

      .fa-arrow {
        font-size: 0.9rem;
        margin-top: 5%;
      }
    }

    .carrousel-testimonials {
      padding: 3rem 0 2rem 0;

      .carrousel-item {
        .item {
          width: 550px;
          height: 550px;
          margin: 0 auto 0 -0.5rem;

          .text-container {
            width: 75%;
            height: 45%;

            .client-infos {
              width: 85%;
              margin: 0rem auto 0.6rem 1rem;

              .client-name {
                font-size: 1.2rem;
              }

              .date {
                font-size: 1.2rem;
              }
            }

            .text {
              margin: 0 auto 0 0.3rem;
              font-size: 1.1rem;
              line-height: 1.2rem;
            }
          }
        }

        .item-sophie {
          .seemore-container {
            bottom: -3rem;
          }
        }

        .seemore-container {
          right: 2.5rem;
        }
      }

      .splide__arrow {
        width: 30px;
        height: 30px;
        background-color: $c-straw-red;
      }

      .splide__arrow--prev {
        margin-left: -1rem;
      }

      .splide__arrow--next {
        margin-right: -1rem;
      }

      .splide__arrow svg {
        fill: $c-white-aqua-blue;
        width: 20px;
        height: 20px;
      }
    }
  }
}

//* -------------------------------------------------------------------------------
//* ------------------------------ DESKTOP ----------------------------------------
//* -------------------------------------------------------------------------------

@media (min-width: 1199px) {
  .testimonials {
    padding-top: 17rem;

    .title {
      width: 820px;
      font-size: 3rem;
      line-height: 3.5rem;
      padding: 2rem 0 2rem 2rem;
      top: 7rem;
    }

    .title-background {
      width: 820px;
      height: 120px;
      top: 12rem;
    }

    .carrousel-testimonials {
      height: 700px;

      .carrousel-item {
        .item {
          width: 100%;
          height: 600px;

          &:hover > .text-container > .seemore-container > .fa-arrow {
            animation: slide-right 0.5s infinite alternate;
          }

          .text-container {
            width: 70%;
            height: 50%;

            .client-infos {
              margin-bottom: 0.5rem;

              .client-name {
                font-size: 1.5rem;
                margin-left: -1rem;

                .name {
                  margin-right: 1rem;
                }
              }

              .date {
                font-size: 1.5rem;
              }
            }

            .text {
              width: 100%;
              height: 90%;
              font-size: 1.3rem;
              line-height: 1.6rem;
              margin: auto 0 auto -1rem;
            }

            .seemore-container {
              width: 150px;
              font-size: 1.2rem;

              .label {
                margin-right: 1rem;
              }

              .fa-arrow {
                font-size: 1rem;
              }
            }
          }
        }
      }

      .splide__arrow {
        width: 40px;
        height: 40px;
      }

      .splide__arrow--prev {
        margin-top: 0;
      }

      .splide__arrow--next {
        margin-top: 0;
      }

      .splide__pagination {
        bottom: 2rem;
      }

      .splide__arrow svg {
        width: 25px;
        height: 25px;
      }

      .add-testimonial-btn {
        &:hover > .fa-arrow {
          animation: slide-right 0.5s infinite alternate;
        }
      }
    }
  }
}

@media (min-width: 1399px) {
  .testimonials {
    .carrousel-testimonials {
      width: 100%;
      height: auto;
      border-radius: 50% 20% / 10% 40%;

      .carrousel-item {
        .item {
          width: 100%;
          height: 800px;

          .text-container {
            width: 68%;
            height: 45%;

            .client-infos {
              width: 93%;
              margin: 1rem 1rem 1.5rem 0.5rem;

              .client-name {
                font-size: 1.7rem;
              }

              .date {
                font-size: 1.7rem;
              }
            }

            .text {
              font-size: 1.3rem;
              line-height: 1.6rem;
            }
          }
        }
      }

      .splide__pagination {
        bottom: 5rem;
      }

      .splide__pagination__page {
        border: 8px solid $c-straw-red;
      }

      .add-testimonial-btn {
        width: 250px;

        &:hover > .label {
          font-size: 1.5rem;
        }

        .label {
          font-size: 1.4rem;
          margin-right: 1rem;
        }

        .fa-arrow {
          margin-top: 0.2rem;
        }
      }
    }
  }
}

@media (min-width: 1599px) {
  .testimonials {
    padding-top: 14rem;

    .seemore-container {
      bottom: -2rem;

      .label {
        margin-right: 1rem;
      }

      .fa-arrow {
        font-size: 0.8rem;
        margin-top: 5%;
      }
    }

    .carrousel-testimonials {
      .carrousel-item {
        .item {
          .text-container {
            width: 68%;
            height: 45%;

            .client-infos {
              width: 93%;
              margin: 1rem 1rem 1.5rem 0.5rem;

              .client-name {
                font-size: 1.7rem;
              }

              .date {
                font-size: 1.7rem;
              }
            }

            .text {
              font-size: 1.5rem;
              line-height: 1.8rem;
            }

            .seemore-container {
              width: 150px;
              font-size: 1.4rem;

              .fa-arrow {
                font-size: 1.2rem;
              }
            }
          }
        }
      }

      .splide__arrow--prev {
        margin-top: 0;
        margin-left: -0.5rem;
      }

      .splide__arrow--next {
        margin-top: 0;
        margin-right: -0.5rem;
      }

      .splide__pagination {
        bottom: 2rem;
      }
    }
  }
}
@media (min-width: 1920px) {
  .testimonials {
    padding-top: 17rem;

    .carrousel-testimonials {
      .carrousel-item {
        .item {
          .text-container {
            width: 50%;
            height: 50%;

            .client-infos {
              width: 90%;
            }
          }
        }
      }

      .splide__arrow--prev {
        margin-left: 0;
      }

      .splide__arrow--next {
        margin-right: 0;
      }
    }
  }
}
