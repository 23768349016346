.header-container {
  width: 100vw;
  height: 50px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: transparent;
  color: $c-white-aqua-blue;
  background-color: #6c3c4ac3;
  position: fixed;
  z-index: 10000;

  .logo-container {
    width: 270px;
    display: flex;
    justify-content: space-evenly;

    .logo-imageCtn {
      width: 50px;

      .logo-img {
        width: 100%;
      }
    }

    .logo-title {
      width: 150px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      font-family: amatic-bold, sans-serif;
      font-size: 1.7rem;
      text-shadow: 0 0 0.5rem $c-very-dark-duck-blue;
    }
  }

  .burger-container {
    border: none;
    background-color: transparent;
    margin-right: 0.5rem;
  }

  .burger {
    display: flex;
    width: 25px;
    height: 3px;
    background-color: $c-very-light-aqua-blue;
    border-radius: 5px;
    position: relative;
    z-index: 5000;

    &::before {
      content: '';
      width: 100%;
      height: 3px;
      background-color: $c-very-light-aqua-blue;
      border-radius: 5px;
      position: absolute;
      bottom: 0.5rem;
      left: 0;
    }

    &::after {
      content: '';
      width: 100%;
      height: 3px;
      background-color: $c-very-light-aqua-blue;
      border-radius: 5px;
      position: absolute;
      top: 0.5rem;
      right: 0;
    }
  }

  .cross {
    display: flex;
    width: 25px;
    height: 25px;
    font-size: 1.7rem;
    color: $c-very-light-aqua-blue;
    display: flex;
    flex-direction: column;
    margin-top: -0.2rem;
  }

  .contact-nav-container {
    display: none;
  }

  .contact-nav-container-mobile {
    width: 100vw;
    height: calc(100vh - 50px);
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    background-color: #6c3c4ae6;
    backdrop-filter: blur(10px);
    position: absolute;
    top: 50px;
    left: 0;
    bottom: 0;
    right: 0;

    .contact-container {
      width: 130px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      margin-top: 3rem;

      .contact-list {
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: space-evenly;
        color: $c-white-aqua-blue;

        .fa {
          font-size: 1.5rem;
          transition: transform 0.3s ease-in-out;
          filter: drop-shadow(0px 0px 0.2rem $c-dark-duck-blue);

          &:hover {
            transform: translateY(-3px);
            color: $c-ocre;
            filter: drop-shadow(1px 1px 1px $c-dark-duck-blue);
          }
        }

        .phone-container {
          position: relative;

          .span-phone {
            width: 115px;
            font-size: 1rem;
            font-family: barlow-regular, sans-serif;
            display: none;
            background-color: $c-ocre;
            padding: 1rem;
            border-radius: 20px;
            position: absolute;
            top: 2.5rem;
            left: -3.2rem;
            transition: all 0.3s ease-in-out;
            color: $c-very-light-aqua-blue;
          }
        }

        .email-container {
          position: relative;

          .span-email {
            width: 157px;
            font-size: 1rem;
            font-family: barlow-regular, sans-serif;
            display: none;
            background-color: $c-ocre;
            padding: 1rem;
            border-radius: 20px;
            position: absolute;
            top: 2.5rem;
            left: -4.2rem;
            transition: all 0.3s ease-in-out;
            color: $c-very-light-aqua-blue;

            &:after {
              content: '';
              position: absolute;
              top: -0.8rem;
              left: 37%;
              border-top: 20px solid $c-ocre;
              border-left: 20px solid transparent;
              border-right: 20px solid transparent;
              transform: rotate(180deg);
            }
          }
        }

        .address-container {
          display: none;
        }
      }
    }

    .navigation-container {
      width: 100%;
      height: 80%;
      margin: auto auto;
      display: flex;
      flex-direction: column;
      align-items: center;
      text-align: center;
      text-shadow: 0 0 0.5rem $c-very-dark-duck-blue;
      font-family: amatic-bold, sans-serif;
      font-size: 1.7rem;
      line-height: 2.7rem;
      color: $c-very-light-aqua-blue;

      .nav-list {
        .nav-item {
          transition: all 0.3s ease-in-out;

          &:hover {
            color: $c-ocre;
          }
        }

        .blog-link {
          width: 90px;
          background-color: $c-ocre;
          padding: 0.3rem 1rem;
          border-radius: 40% 25% 30% 25%;
          color: $c-white-aqua-blue;
          transition: all 0.3s ease-in-out;
          margin: 1rem auto 0 auto;

          &:hover {
            box-shadow: $c-very-dark-duck-blue 0px 5px 30px -30px, $c-very-dark-duck-blue 0px 20px 30px -15px;
            transform: scale(1.005);
            font-size: 1.9rem;
            background-color: $c-light-ocre;
            color: $c-white-aqua-blue;
          }
        }
      }
    }
  }
}

//& -----------------------------------------------------------
//& ---------------------- PAGE BLOG --------------------------
//& -----------------------------------------------------------

.header-container-blog {
  background-color: #985e0dce;

  .contact-nav-container-mobile {
    background-color: #985e0dba;

    .contact-container {
      .contact-list {
        .fa {
          &:hover {
            color: $c-dark-pink;
          }
        }
      }
    }

    .navigation-container {
      .nav-list {
        .nav-item {
          &:hover {
            color: $c-dark-pink;
          }
        }

        .blog-link {
          background-color: $c-light-duck-blue;

          &:hover {
            background-color: $c-very-light-duck-blue;
            color: $c-white-aqua-blue;
          }
        }
      }
    }
  }
}

//? -----------------------------------------------------------
//? -----------------------  FIXED  ---------------------------
//? -----------------------------------------------------------

.header-container-fixed {
  color: $c-duck-blue;
  background-color: #ffffffc2;

  .burger {
    background-color: $c-duck-blue;

    &::before {
      background-color: $c-duck-blue;
    }

    &::after {
      background-color: $c-duck-blue;
    }
  }

  .cross {
    color: $c-duck-blue;
  }

  .contact-nav-container-mobile {
    background-color: #0f5a66dd;

    .navigation-container {
      .blog-link {
        background-color: $c-pink !important;
        color: $c-white-aqua-blue !important;

        &:hover {
          background-color: $c-light-pink !important;
        }
      }

      .nav-list {
        .nav-item {
          &:hover {
            color: $c-dark-ocre;
          }
        }
      }
    }
  }
}

//^ ---------------------------------------------------------------
//^ -----------------------  LANDSCAPE  ---------------------------
//^ ---------------------------------------------------------------

.header-container-landscape-mobile {
  .contact-nav-container-mobile {
    .contact-container {
      margin-bottom: 0;
    }

    .navigation-container {
      font-size: 1.3rem !important;
      line-height: 1.5rem !important;
    }
  }
}

.header-container-landscape-tablet {
  .contact-nav-container-mobile {
    .contact-container {
      margin-bottom: 0;
    }

    .navigation-container {
      font-size: 2rem !important;
      line-height: 3rem !important;
    }
  }
}

// .header-container-landscape-fixed-mobile {
//   .contact-nav-container-mobile {
//     background-color: #f0c;
//     .contact-container {
//       margin-bottom: 0;
//     }

//     .navigation-container {
//       font-size: 1.3rem !important;
//       line-height: 1.5rem !important;
//     }
//   }
// }

// .header-container-landscape-fixed-tablet {
//   .contact-nav-container-mobile {
//     .contact-container {
//       margin-bottom: 0;
//     }

//     .navigation-container {
//       font-size: 2rem !important;
//       line-height: 3rem !important;
//     }
//   }
// }

//* -------------------------------------------------------------------------------
//* ------------------------------ TABLET ----------------------------------------
//* -------------------------------------------------------------------------------

@media (min-width: 599px) {
  .header-container {
    height: 70px;

    .logo-container {
      .logo-imageCtn {
        width: 70px;
      }
    }

    .contact-nav-container-mobile {
      height: calc(100vh - 70px);
      top: 70px;

      .contact-container {
        .contact-list {
          width: 100%;
          display: flex;
          flex-direction: row;
          justify-content: space-evenly;
          color: $c-white-aqua-blue;

          .phone-container {
            &:hover .span-phone {
              display: inline;
            }

            .span-phone {
              background-color: $c-ocre !important;

              &:after {
                content: '';
                position: absolute;
                top: -0.8rem;
                left: 37%;
                border-top: 20px solid $c-ocre !important;
                border-left: 20px solid transparent;
                border-right: 20px solid transparent;
                transform: rotate(180deg);
              }
            }
          }

          .email-container {
            &:hover .span-email {
              display: inline;
            }

            .span-email {
              background-color: $c-ocre !important;

              &:after {
                content: '';
                position: absolute;
                top: -0.8rem;
                left: 37%;
                border-top: 20px solid $c-ocre !important;
                border-left: 20px solid transparent;
                border-right: 20px solid transparent;
                transform: rotate(180deg);
              }
            }
          }

          .address-container {
            display: inline;
            position: relative;

            &:hover .span-address {
              display: inline;
            }

            .span-address {
              width: 170px;
              font-size: 1rem;
              font-family: barlow-regular, sans-serif;
              display: none;
              background-color: $c-ocre !important;
              padding: 1rem;
              border-radius: 20px;
              position: absolute;
              top: 2.5rem;
              left: -4.7rem;
              transition: all 0.3s ease-in-out;
              color: $c-very-light-aqua-blue;
              text-align: center;

              &:after {
                content: '';
                position: absolute;
                top: -0.8rem;
                left: 37%;
                border-top: 20px solid $c-ocre !important;
                border-left: 20px solid transparent;
                border-right: 20px solid transparent;
                transform: rotate(180deg);
              }
            }
          }
        }

        .contact-list {
          .phone-container {
            .span-phone {
              background-color: $c-dark-pink;

              &:after {
                border-top: 20px solid $c-dark-pink;
              }
            }
          }

          .email-container {
            .span-email {
              background-color: $c-dark-pink;

              &:after {
                border-top: 20px solid $c-dark-pink;
              }
            }
          }

          .address-container {
            .span-address {
              background-color: $c-dark-pink;

              &:after {
                border-top: 20px solid $c-dark-pink;
              }
            }
          }
        }
      }

      .navigation-container {
        margin-top: 8rem;
        font-size: 2rem;
        line-height: 3.5rem;
      }
    }
  }

  //& -----------------------------------------------------------
  //& ---------------------- PAGE BLOG --------------------------
  //& -----------------------------------------------------------

  .header-container-blog {
    .contact-nav-container-mobile {
      .contact-container {
        .contact-list {
          .phone-container {
            .span-phone {
              background-color: $c-dark-pink !important;

              &:after {
                border-top: 20px solid $c-dark-pink !important;
              }
            }
          }

          .email-container {
            .span-email {
              background-color: $c-dark-pink !important;

              &:after {
                border-top: 20px solid $c-dark-pink !important;
              }
            }
          }

          .address-container {
            .span-address {
              background-color: $c-dark-pink !important;

              &:after {
                border-top: 20px solid $c-dark-pink !important;
              }
            }
          }
        }
      }

      .navigation-container {
        margin-top: 8rem;
        font-size: 2rem;
        line-height: 3.5rem;
      }
    }
  }

  //? -----------------------------------------------------------
  //? -----------------------  FIXED  ---------------------------
  //? -----------------------------------------------------------

  // nada
}

@media (min-width: 899px) {
  .header-container {
    height: 90px;

    .logo-container {
      .logo-imageCtn {
        width: 90px;
      }
    }

    .contact-nav-container-mobile {
      height: calc(100vh - 90px);
      top: 90px;

      .navigation-container {
        font-size: 3rem;
        line-height: 5rem;
      }
    }
  }

  //& -----------------------------------------------------------
  //& ---------------------- PAGE BLOG --------------------------
  //& -----------------------------------------------------------

  // nada

  //? -----------------------------------------------------------
  //? -----------------------  FIXED  ---------------------------
  //? -----------------------------------------------------------

  // nada
}

//* -------------------------------------------------------------------------------
//* ------------------------------ DESKTOP ----------------------------------------
//* -------------------------------------------------------------------------------

@media (min-width: 1199px) {
  .header-container {
    background-color: #ffffff09;
    backdrop-filter: blur(10px);
    height: 100px;

    .logo-container {
      width: 250px;

      .logo-imageCtn {
        width: 80px;

        .logo-img {
          margin-top: 0.5rem;
        }
      }

      .logo-title {
        font-size: 1.5rem;
      }
    }

    .burger-container {
      display: none;
    }

    .contact-nav-container-mobile {
      display: none;
    }

    .contact-nav-container {
      width: 1000px;
      display: flex;
      justify-content: space-between;
      margin-right: 1rem;

      .contact-container {
        width: 130px;
        display: flex;
        flex-direction: column;
        justify-content: center;

        .contact-list {
          width: 100%;
          display: flex;
          flex-direction: row;
          justify-content: space-evenly;
          color: $c-white-aqua-blue;

          .fa {
            font-size: 1.5rem;
            filter: drop-shadow(0px 0px 0.2rem $c-dark-duck-blue);
          }

          .phone-container {
            position: relative;

            &:hover .span-phone {
              display: inline;
            }

            .span-phone {
              width: 115px;
              font-size: 1rem;
              font-family: barlow-regular, sans-serif;
              display: none;
              background-color: $c-ocre;
              padding: 1rem;
              border-radius: 20px;
              position: absolute;
              top: 2.5rem;
              left: -3.2rem;
              transition: all 0.3s ease-in-out;
              color: $c-very-light-aqua-blue;

              &:after {
                content: '';
                position: absolute;
                top: -0.8rem;
                left: 37%;
                border-top: 20px solid $c-ocre;
                border-left: 20px solid transparent;
                border-right: 20px solid transparent;
                transform: rotate(180deg);
              }
            }
          }

          .email-container {
            position: relative;

            &:hover .span-email {
              display: inline;
            }

            .span-email {
              width: 157px;
              font-size: 1rem;
              font-family: barlow-regular, sans-serif;
              display: none;
              background-color: $c-ocre;
              padding: 1rem;
              border-radius: 20px;
              position: absolute;
              top: 2.5rem;
              left: -4.2rem;
              transition: all 0.3s ease-in-out;
              color: $c-very-light-aqua-blue;

              &:after {
                content: '';
                position: absolute;
                top: -0.8rem;
                left: 37%;
                border-top: 20px solid $c-ocre;
                border-left: 20px solid transparent;
                border-right: 20px solid transparent;
                transform: rotate(180deg);
              }
            }
          }

          .address-container {
            display: inline;
            position: relative;

            &:hover .span-address {
              display: inline;
            }

            .span-address {
              width: 170px;
              font-size: 1rem;
              font-family: barlow-regular, sans-serif;
              display: none;
              background-color: $c-ocre;
              padding: 1rem;
              border-radius: 20px;
              position: absolute;
              top: 2.5rem;
              left: -4.7rem;
              transition: all 0.3s ease-in-out;
              color: $c-very-light-aqua-blue;
              text-align: center;

              &:after {
                content: '';
                position: absolute;
                top: -0.8rem;
                left: 37%;
                border-top: 20px solid $c-ocre;
                border-left: 20px solid transparent;
                border-right: 20px solid transparent;
                transform: rotate(180deg);
              }
            }
          }
        }
      }

      .navigation-container {
        width: 800px;
        display: flex;
        flex-direction: column;
        justify-content: center;

        .nav-list {
          width: 100%;
          display: flex;
          flex-direction: row;
          justify-content: space-evenly;
          text-shadow: 0 0 0.5rem $c-very-dark-duck-blue;
          font-family: amatic-bold, sans-serif;
          font-size: 1.5rem;
          align-items: center;

          .nav-item {
            transition: all 0.3s ease-in-out;

            &:hover {
              color: $c-light-ocre;
            }
          }

          .blog-link {
            background-color: $c-ocre;
            padding: 0.3rem 1rem;
            border-radius: 40% 25% 30% 25%;
            color: $c-white-aqua-blue;
            transition: all 0.3s ease-in-out;

            &:hover {
              box-shadow: $c-very-dark-duck-blue 0px 5px 30px -30px, $c-very-dark-duck-blue 0px 20px 30px -15px;
              transform: scale(1.005);
              font-size: 1.9rem;
              background-color: $c-light-ocre;
              color: $c-white-aqua-blue !important;
            }
          }
        }
      }
    }
  }

  //& -----------------------------------------------------------
  //& ---------------------- PAGE BLOG --------------------------
  //& -----------------------------------------------------------

  .header-container-blog {
    .contact-nav-container {
      .contact-container {
        .contact-list {
          .fa {
            &:hover {
              color: $c-dark-pink;
            }
          }

          .phone-container {
            .span-phone {
              background-color: $c-dark-pink;

              &:after {
                border-top: 20px solid $c-dark-pink;
              }
            }
          }

          .email-container {
            .span-email {
              background-color: $c-dark-pink;

              &:after {
                border-top: 20px solid $c-dark-pink;
              }
            }
          }

          .address-container {
            .span-address {
              background-color: $c-dark-pink;

              &:after {
                border-top: 20px solid $c-dark-pink;
              }
            }
          }
        }
      }

      .navigation-container {
        .nav-list {
          .nav-item {
            &:hover {
              color: $c-dark-pink;
            }
          }

          .blog-link {
            background-color: $c-light-duck-blue;

            &:hover {
              background-color: $c-very-light-duck-blue;
            }
          }
        }
      }
    }
  }

  //? -----------------------------------------------------------
  //? -----------------------  FIXED  ---------------------------
  //? -----------------------------------------------------------

  .header-container-fixed {
    height: 80px;

    .contact-nav-container {
      .contact-container {
        width: 120px;

        .contact-list {
          color: $c-duck-blue;

          .fa {
            font-size: 1.4rem;
          }
        }
      }

      .navigation-container {
        .nav-list {
          .nav-item {
            &:hover {
              color: $c-ocre;
            }
          }

          .blog-link {
            background-color: $c-pink;

            &:hover {
              background-color: $c-light-pink;
            }
          }
        }
      }
    }
  }
}

@media (min-width: 1399px) {
  .header-container {
    .logo-container {
      .logo-title {
        font-size: 1.7rem;
      }
    }

    .contact-nav-container {
      width: 1200px;

      .navigation-container {
        width: 1000px;

        .nav-list {
          font-size: 1.6rem;
        }
      }
    }
  }

  //& -----------------------------------------------------------
  //& ---------------------- PAGE BLOG --------------------------
  //& -----------------------------------------------------------

  // nada

  //? -----------------------------------------------------------
  //? -----------------------  FIXED  ---------------------------
  //? -----------------------------------------------------------

  .header-container-fixed {
    .contact-nav-container {
      width: 1100px;

      .navigation-container {
        width: 1100px;

        .nav-list,
        .nav-list-blog {
          font-size: 1.6rem;
        }
      }
    }
  }
}

@media (min-width: 1599px) {
  .header-container {
    .contact-nav-container {
      width: 1400px;

      .navigation-container {
        width: 1100px;

        .nav-list {
          font-size: 1.7rem;
        }
      }
    }
  }

  //& -----------------------------------------------------------
  //& ---------------------- PAGE BLOG --------------------------
  //& -----------------------------------------------------------

  // nada

  //? -----------------------------------------------------------
  //? -----------------------  FIXED  ---------------------------
  //? -----------------------------------------------------------

  // nada
}
