.loader-container {
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  position: fixed;
  top: 30%;
  left: 0;

  .circle {
    width: 100px;
    position: absolute;
  }

  .circle1 {
    top: 0;
    animation: bounce1 3s infinite;
    animation-delay: 0s;
  }

  .circle2 {
    top: 79px;
    animation: bounce2 3s infinite;
    animation-delay: 0.5s;
  }

  .circle3 {
    top: 157px;
    animation: bounce3 3s infinite;
    animation-delay: 1s;
  }

  &:after {
    width: 101%;
    height: 100vh;
    content: '';
    display: block;
    background-image: url('../../images/wallpaper.svg');
    background-repeat: no-repeat;
    background-size: cover;
    position: fixed;
    top: 0;
    left: -5px;
    z-index: -1;
  }
}

//* -------------------------------------------------------------------------------
//* ------------------------------ TABLET ----------------------------------------
//* -------------------------------------------------------------------------------

//* -------------------------------------------------------------------------------
//* ------------------------------ DESKTOP ----------------------------------------
//* -------------------------------------------------------------------------------

@media (min-width: 1199px) {
  .loader-container {
    top: 40%;
  }
}
