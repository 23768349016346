.session {
  height: auto;
  width: 101%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 7rem 0 0 0;
  margin-top: 0rem;
  position: relative;

  .container {
    width: 100%;
    height: 100%;
    background: linear-gradient(217deg, #0f5a6600, rgba(255, 0, 0, 0) 70.71%),
      linear-gradient(127deg, #0f5a6632, rgba(0, 255, 0, 0) 70.71%),
      linear-gradient(336deg, #0f5a6600, rgba(0, 0, 255, 0) 70.71%);
    border-radius: 25% 20% 10% 5%;
    margin: 0 auto;
    padding: 7rem 0 0 0;

    .title {
      width: 300px;
      font-family: homemade-apple, sans-serif;
      font-size: 2rem;
      line-height: 2.5rem;
      color: $c-duck-blue;
      background-color: $c-light-ocre;
      border-radius: 40% 0% 0% 20%;
      padding: 2rem 1rem 2rem 0;
      text-align: end;
      box-shadow: 0px 10px 10px -10px $c-very-dark-duck-blue;
      position: absolute;
      top: -1rem;
      right: 0;
      z-index: 5;
    }

    .title-background {
      width: 300px;
      height: 150px;
      background-color: $c-dark-ocre;
      border-radius: 20% 40% 30% 50%;
      box-shadow: 0px 10px 10px -10px $c-very-dark-duck-blue;
      position: absolute;
      top: 3rem;
      right: 1rem;
      z-index: 0;
    }

    .content {
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      align-items: center;
      padding: 0;

      .thinkingCtn {
        position: relative;
        margin: 1rem auto;

        .thinking {
          width: 250px;
          height: 300px;
          background-size: contain;
          background: url('../../images/illustrations/thinking1.webp');
          background-repeat: no-repeat;
          background-size: contain;
          transition: all 1s ease-in-out;
        }

        .span {
          font-family: homemade-apple, sans-serif;
          font-size: 8rem;
          font-weight: bold;
          color: $c-light-ocre;
          position: absolute;
          top: 0rem;
          right: 0rem;
          text-shadow: 0px 2px 5px $c-very-dark-duck-blue;
        }
      }

      .text {
        width: 95%;
        text-align: center;
        font-family: barlow-italic, sans-serif;
        font-size: 1.2rem;
        line-height: 1.5rem;
        color: $c-very-dark-duck-blue;
        margin: 0 auto;
      }
    }
  }
}

//* -------------------------------------------------------------------------------
//* ------------------------------ TABLET ----------------------------------------
//* -------------------------------------------------------------------------------

@media (min-width: 599px) {
  .session {
    .container {
      padding: 7rem 0 5rem 0;
      border-radius: 25% 20% 10% 20%;

      .title {
        width: 550px;
        font-size: 2.5rem;
        line-height: 3rem;
        padding: 2rem;
      }

      .title-background {
        width: 550px;
        top: 2rem;
      }

      .content {
        flex-wrap: nowrap;

        .text {
          width: 85%;
          font-size: 1.3rem;
          line-height: 1.6rem;
        }

        .thinkingCtn {
          margin: 2rem auto;

          .thinking {
            width: 350px;
            height: 400px;
          }

          .span {
            font-size: 10rem;
            right: 2rem;
          }
        }
      }
    }
  }
}

@media (min-width: 899px) {
  .session {
    .container {
      .content {
        .thinkingCtn {
          .thinking {
            width: 400px;
            height: 450px;
          }

          .span {
            right: 2.5rem;
          }
        }
      }
    }
  }
}

//* -------------------------------------------------------------------------------
//* ------------------------------ DESKTOP ----------------------------------------
//* -------------------------------------------------------------------------------

@media (min-width: 1199px) {
  .session {
    padding: 7rem 0 2rem 0;
    margin-top: 8rem;

    &:hover > .container > .content > .thinkingCtn > .thinking {
      animation: changeBg 6s infinite;
    }

    .container {
      border-radius: 50% 20% / 10% 40%;
      padding: 7rem 0 3rem 0;

      .title {
        width: 700px;
        font-size: 3rem;
        line-height: 3.5rem;
        padding: 2rem 4rem 2rem 0;
        top: -1rem;
      }

      .title-background {
        width: 700px;
        height: 120px;
        top: 4rem;
        right: 3rem;
      }

      .content {
        padding: 0 3rem;

        .text {
          width: 70%;
          font-size: 1.7rem;
          line-height: 2.3rem;
          margin: 0 0 0 auto;
        }

        .thinkingCtn {
          .thinking {
            width: 300px;
            height: 350px;
          }

          .span {
            right: 1rem;
          }
        }
      }
    }
  }
}

@media (min-width: 1399px) {
  .session {
    .container {
      .content {
        .thinkingCtn {
          .thinking {
            width: 450px;
            height: 500px;
          }

          .span {
            right: 4rem;
          }
        }
      }
    }
  }
}

@media (min-width: 1920px) {
  .session {
    padding: 7rem 0 5rem 0;

    .container {
      border-radius: 30% 20% 10% 20%;

      .content {
        .text {
          width: 1300px;
        }
      }
    }
  }
}
