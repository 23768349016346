.renovation-container {
  width: 100vw;
  min-height: 100vh;
  background-color: #026a79;

  color: #f1f7ed;
  text-align: center;
  padding: 1rem;

  .title-container {
    height: 20vh;

    .title {
      font-size: 1.5rem;
      margin-bottom: 0.5rem;
    }

    .subtitle {
      font-size: 1.2rem;
    }
  }

  .image-container {
    width: 280px;
    margin: 0 auto 1rem auto;

    .img {
      width: 100%;
    }
  }

  .text {
    height: 50px;
    font-size: 1rem;

    .phone {
      pointer-events: auto !important;
      white-space: nowrap;
      text-decoration: underline;
    }

    .email {
      text-decoration: underline;
      cursor: pointer;
    }
  }
}

@media (min-width: 599px) {
  .renovation-container {
    .title-container {
      .title {
        font-size: 1.7rem;
      }

      .subtitle {
        font-size: 1.3rem;
      }
    }

    .image-container {
      width: 500px;
      margin: 0 auto 1rem auto;

      .img {
        width: 100%;
      }
    }

    .text {
      font-size: 1.2rem;

      .phone {
        pointer-events: none !important;
      }
    }
  }
}

@media (min-width: 899px) {
  .renovation-container {
    .title-container {
      .title {
        font-size: 1.9rem;
      }

      .subtitle {
        font-size: 1.5rem;
      }
    }

    .text {
      font-size: 1.3rem;
    }
  }
}

@media (min-width: 1199px) {
  .renovation-container {
    .title-container {
      .title {
        font-size: 2rem;
      }

      .subtitle {
        font-size: 1.7rem;
      }
    }

    .image-container {
      width: 700px;
      margin: 0 auto 1rem auto;

      .img {
        width: 100%;
      }
    }

    .text {
      font-size: 1.5rem;
    }
  }
}

@media (min-width: 1399px) {
  .renovation-container {
    .title-container {
      .title {
        font-size: 2.2rem;
      }

      .subtitle {
        font-size: 1.8rem;
      }
    }

    .text {
      font-size: 1.6rem;
    }
  }
}
