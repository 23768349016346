.illustrations {
  min-width: 300px;
  max-width: 1950px;
  height: auto;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  background-repeat: no-repeat;
  background-size: cover;
  margin: 5rem auto;
  padding: 2rem 0;
  overflow-x: scroll;

  .imageCtn {
    box-shadow: 0px 10px 10px -10px $c-very-dark-duck-blue;
  }

  // & -----------------  1ère frise  ----------------------

  .cleanCtn {
    background-color: #4f273276;
    border: solid 1rem darken($c-light-aqua-blue, 2%);
    border-bottom: solid 2.5rem darken($c-light-aqua-blue, 2%);
    transform: rotateZ(-5deg);
  }

  .therapyCtn {
    background-color: #0d28267d;
    border: solid 1rem darken($c-white-aqua-blue, 1%);
    border-bottom: solid 2.5rem darken($c-white-aqua-blue, 1%);
  }

  .momCtn {
    background-color: #420a1a52;
    border: solid 1rem lighten($c-ocre, 45%);
    border-bottom: solid 2.5rem lighten($c-ocre, 45%);
    transform: rotateZ(2deg);
  }

  .mirorCtn {
    background-color: #08485255;
    border: solid 1rem darken($c-white-aqua-blue, 2%);
    border-bottom: solid 2.5rem darken($c-white-aqua-blue, 2%);
    transform: rotateZ(-3deg);
  }

  .chairCtn {
    background-color: #4c30097c;
    border: solid 1rem $c-light-aqua-blue;
    border-bottom: solid 2.5rem $c-light-aqua-blue;
    transform: rotateZ(5deg);
  }

  .holeCtn {
    background-color: #61263788;
    border: solid 1rem darken($c-white-aqua-blue, 1%);
    border-bottom: solid 2.5rem darken($c-white-aqua-blue, 1%);
    transform: rotateZ(-5deg);
  }

  .miror1Ctn {
    background-color: #0b48528f;
    border: solid 1rem lighten($c-ocre, 40%);
    border-bottom: solid 2.5rem lighten($c-ocre, 40%);
  }

  .twoheadCtn {
    background-color: #25062b84;
    border: solid 1rem darken($c-white-aqua-blue, 2.5%);
    border-bottom: solid 2.5rem darken($c-white-aqua-blue, 2.5%);
    transform: rotateZ(3deg);
  }

  .couple2Ctn {
    background-color: #3e131f9e;
    border: solid 1rem lighten($c-light-aqua-blue, 2%);
    border-bottom: solid 2.5rem lighten($c-light-aqua-blue, 2%);
    transform: rotateZ(-3deg);
  }

  // & -----------------  2ème frise  ----------------------

  .smileCtn {
    background-color: #4610208b;
    border: solid 1rem lighten($c-ocre, 50%);
    border-bottom: solid 2.5rem lighten($c-ocre, 50%);
    transform: rotateZ(-3deg);
  }

  .bottleCtn {
    background-color: #5c3a0b7a;
    border: solid 1rem #6c3c4a76;
    border-bottom: solid 2.5rem #6c3c4a76;
    transform: rotateZ(2deg);
  }

  .depressionCtn {
    background-color: #022e3589;
    border: solid 1rem darken($c-white-aqua-blue, 4%);
    border-bottom: solid 2.5rem darken($c-white-aqua-blue, 4%);
  }

  .guysCtn {
    background-color: #985e0d27;
    border: solid 1rem $c-light-aqua-blue;
    border-bottom: solid 2.5rem $c-light-aqua-blue;
    transform: rotateZ(2deg);
  }

  .bodyCtn {
    background-color: #0f5a6691;
    border: solid 1rem lighten($c-ocre, 40%);
    border-bottom: solid 2.5rem lighten($c-ocre, 40%);
    transform: rotateZ(5deg);
  }

  .pizzaCtn {
    background-color: #56283588;
    border: solid 1rem darken($c-white-aqua-blue, 1%);
    border-bottom: solid 2.5rem darken($c-white-aqua-blue, 1%);
    transform: rotateZ(-5deg);
  }

  .fallCtn {
    background-color: #985e0d96;
    border: solid 1rem $c-aqua-blue;
    border-bottom: solid 2.5rem $c-aqua-blue;
  }

  .waterCtn {
    background-color: #370b188b;
    border: solid 1rem darken($c-white-aqua-blue, 2.5%);
    border-bottom: solid 2.5rem darken($c-white-aqua-blue, 2.5%);
    transform: rotateZ(3deg);
  }

  .parentCtn {
    background-color: #0a383f91;
    border: solid 1rem darken($c-light-aqua-blue, 2%);
    border-bottom: solid 2.5rem darken($c-light-aqua-blue, 2%);
    transform: rotateZ(-3deg);
  }

  .image {
    height: 200px;
    width: auto;
  }
}

@media (min-width: 1399px) {
  .illustrations {
    overflow-x: hidden;
  }
}
