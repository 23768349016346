.footer-container {
  width: 100vw;
  height: auto;
  display: flex;
  flex-direction: column;
  background-color: #00181b20;
  backdrop-filter: blur(2px);
  padding: 3rem 2rem 20rem 2rem;
  margin-top: 10rem;

  .content {
    width: 80%;
    display: flex;
    flex-direction: column-reverse;
    flex-wrap: wrap;
    margin: 0 auto;
    align-content: center;

    .element {
      width: 600px;
    }

    .logo-container {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-top: 1rem;

      .logo-footer {
        width: 150px;
      }

      .title-logo {
        font-family: amatic-bold, sans-serif;
        color: $c-ocre;
        font-size: 2rem;
        margin: 0rem 0 1rem 0;
        text-shadow: 0px 1px 1px $c-ocre;
      }

      .text {
        font-family: barlow-regular, sans-serif;
        text-align: center;
        font-size: 1rem;
        margin: 0;
        color: $c-duck-blue;
      }
    }

    .social-container {
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;
      font-family: homemade-apple, sans-serif;
      font-size: 1.2rem;
      margin: 4rem auto 2rem auto;
      color: $c-light-duck-blue;
      text-shadow: 0px 1px 1px $c-light-duck-blue;

      .social {
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;

        &::after {
          content: '\2193';
          font-size: 1.5rem;
          margin-top: 1rem;
          font-family: oswald, serif;
          color: $c-light-duck-blue;
        }
      }

      .icones {
        width: 100px;
        display: flex;
        flex-direction: row;
        justify-content: space-evenly;

        .fa {
          margin-top: 1rem;
          font-size: 1.5rem;
          color: $c-ocre;
          margin-right: 0.5rem;

          &:hover {
            color: $c-very-light-duck-blue;
          }
        }

        .phone-container {
          position: relative;
          transition: all 1s ease-in-out;

          &:hover .span-phone {
            display: inline;
          }

          .span-phone {
            width: 115px;
            font-size: 1rem;
            font-family: barlow-regular, sans-serif;
            display: none;
            background-color: $c-very-light-duck-blue;
            padding: 1rem;
            border-radius: 20px;
            position: absolute;
            top: 3.5rem;
            left: -3.1rem;
            transition: all 0.3s ease-in-out;
            color: $c-very-light-aqua-blue;

            &:after {
              content: '';
              position: absolute;
              top: -0.8rem;
              left: 37%;
              border-top: 20px solid $c-very-light-duck-blue;
              border-left: 20px solid transparent;
              border-right: 20px solid transparent;
              transform: rotate(180deg);
            }
          }
        }

        .email-container {
          position: relative;
          transition: all 1s ease-in-out;

          &:hover .span-email {
            display: inline;
          }

          .span-email {
            width: 157px;
            font-size: 1rem;
            font-family: barlow-regular, sans-serif;
            display: none;
            background-color: $c-very-light-duck-blue;
            padding: 1rem;
            border-radius: 20px;
            position: absolute;
            top: 3.5rem;
            left: -4.1rem;
            transition: all 0.3s ease-in-out;
            color: $c-very-light-aqua-blue;

            &:after {
              content: '';
              position: absolute;
              top: -0.8rem;
              left: 37%;
              border-top: 20px solid $c-very-light-duck-blue;
              border-left: 20px solid transparent;
              border-right: 20px solid transparent;
              transform: rotate(180deg);
            }
          }
        }

        .address-container {
          position: relative;
          transition: all 1s ease-in-out;

          &:hover .span-address {
            display: inline;
          }

          .span-address {
            width: 170px;
            font-size: 1rem;
            font-family: barlow-regular, sans-serif;
            display: none;
            background-color: $c-very-light-duck-blue;
            padding: 1rem;
            border-radius: 20px;
            position: absolute;
            top: 3.5rem;
            left: -4.6rem;
            transition: all 0.3s ease-in-out;
            color: $c-very-light-aqua-blue;
            text-align: center;

            &:after {
              content: '';
              position: absolute;
              top: -0.8rem;
              left: 37%;
              border-top: 20px solid $c-very-light-duck-blue;
              border-left: 20px solid transparent;
              border-right: 20px solid transparent;
              transform: rotate(180deg);
            }
          }
        }
      }
    }

    .links-container {
      display: flex;
      flex-direction: column;
      align-items: center;
      font-family: barlow-semibold, sans-serif;

      .legales-link {
        font-size: 1.2rem;
        transition: all 0.3s ease-in-out;
        color: $c-light-duck-blue;
        transition: all 0.3s ease-in-out;
        position: relative;

        &:hover {
          color: $c-ocre;
        }
      }
    }
  }
}

.fidia {
  height: 50px;
  font-size: 1rem;
  padding: 0.5rem 0;
  font-family: edu-regular, sans-serif;
  text-align: center;
  background-color: #00181b0b;
  backdrop-filter: blur(2px);
  color: $c-light-duck-blue;
  border-top: 1px solid $c-aqua-blue;

  .fa-heart {
    margin-bottom: -0.3rem;
  }

  .fa-coffee {
    margin-bottom: -0.1rem;
  }

  .fa-notes {
    margin-bottom: -0.3rem;
  }

  .underline {
    text-decoration: underline;
  }
}

//* -------------------------------------------------------------------------------
//* ------------------------------ TABLET ----------------------------------------
//* -------------------------------------------------------------------------------

@media (min-width: 599px) {
  .footer-container {
    .content {
      .social-container {
        margin: 5rem auto 3rem auto;
      }
    }
  }
}

//* -------------------------------------------------------------------------------
//* ------------------------------ DESKTOP ----------------------------------------
//* -------------------------------------------------------------------------------

@media (min-width: 1199px) {
  .footer-container {
    padding: 3rem 2rem;

    &:hover > .content > .social-container > .social::after {
      animation: slide-down 0.5s infinite alternate;
    }

    .content {
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      justify-content: space-between;
      align-items: center;

      .element {
        width: 33, 3333333%;
      }

      .logo-container {
        width: 33, 3333333%;
      }

      .social-container {
        width: 33, 3333333%;
      }
    }
  }

  .fidia {
    font-size: 1.1rem;
    padding: 0.5rem 0;
  }
}
