@font-face {
  font-family: 'amatic-regular';
  src: url('/fonts/amatic/AmaticSC-Regular.ttf');
}

@font-face {
  font-family: 'amatic-bold';
  src: url('/fonts/amatic/AmaticSC-Bold.ttf');
}

@font-face {
  font-family: 'barlow-black';
  src: url('/fonts/barlow/BarlowCondensed-Black.ttf');
}

@font-face {
  font-family: 'barlow-black-italic';
  src: url('/fonts/barlow/BarlowCondensed-BlackItalic.ttf');
}

@font-face {
  font-family: 'barlow-bold';
  src: url('/fonts/barlow/BarlowCondensed-Bold.ttf');
}

@font-face {
  font-family: 'barlow-bold-italic';
  src: url('/fonts/barlow/BarlowCondensed-BoldItalic.ttf');
}

@font-face {
  font-family: 'barlow-extra-bold';
  src: url('/fonts/barlow/BarlowCondensed-ExtraBold.ttf');
}

@font-face {
  font-family: 'barlow-extra-bold-italic';
  src: url('/fonts/barlow/BarlowCondensed-ExtraBoldItalic.ttf');
}

@font-face {
  font-family: 'barlow-extra-light';
  src: url('/fonts/barlow/BarlowCondensed-ExtraLight.ttf');
}

@font-face {
  font-family: 'barlow-extra-light-italic';
  src: url('/fonts/barlow/BarlowCondensed-ExtraLightItalic.ttf');
}

@font-face {
  font-family: 'barlow-italic';
  src: url('/fonts/barlow/BarlowCondensed-Italic.ttf');
}

@font-face {
  font-family: 'barlow-light';
  src: url('/fonts/barlow/BarlowCondensed-Light.ttf');
}

@font-face {
  font-family: 'barlow-light-italic';
  src: url('/fonts/barlow/BarlowCondensed-LightItalic.ttf');
}

@font-face {
  font-family: 'barlow-medium';
  src: url('/fonts/barlow/BarlowCondensed-Medium.ttf');
}

@font-face {
  font-family: 'barlow-medium-italic';
  src: url('/fonts/barlow/BarlowCondensed-MediumItalic.ttf');
}

@font-face {
  font-family: 'barlow-regular';
  src: url('/fonts/barlow/BarlowCondensed-Regular.ttf');
}

@font-face {
  font-family: 'barlow-semibold';
  src: url('/fonts/barlow/BarlowCondensed-SemiBold.ttf');
}

@font-face {
  font-family: 'barlow-semibold-italic';
  src: url('/fonts/barlow/BarlowCondensed-SemiBoldItalic.ttf');
}

@font-face {
  font-family: 'barlow-thin';
  src: url('/fonts/barlow/BarlowCondensed-Thin.ttf');
}

@font-face {
  font-family: 'barlow-thin-italic';
  src: url('/fonts/barlow/BarlowCondensed-ThinItalic.ttf');
}

@font-face {
  font-family: 'edu-bold';
  src: url('/fonts/edu-nsw/EduNSWACTFoundation-Bold.ttf');
}

@font-face {
  font-family: 'edu-medium';
  src: url('/fonts/edu-nsw/EduNSWACTFoundation-Medium.ttf');
}

@font-face {
  font-family: 'edu-regular';
  src: url('/fonts/edu-nsw/EduNSWACTFoundation-Regular.ttf');
}

@font-face {
  font-family: 'edu-semibold';
  src: url('/fonts/edu-nsw/EduNSWACTFoundation-SemiBold.ttf');
}

@font-face {
  font-family: 'homemade-apple';
  src: url('/fonts/homemade-apple/HomemadeApple-Regular.ttf');
}
