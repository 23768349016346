.presentation {
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  padding: 3rem 0;

  .julie {
    width: 310px;
    height: 100%;
    transform: rotate(5deg);
    margin: 0 auto 2rem auto;
  }

  .presentation-text {
    width: 95%;
    margin: 0 auto;

    .title {
      font-family: homemade-apple, sans-serif;
      font-size: 2rem;
      color: $c-light-duck-blue;
      margin-bottom: 1rem;
      text-align: center;
    }

    .text {
      font-family: barlow-italic, sans-serif;
      font-size: 1.2rem;
      line-height: 1.5rem;
      color: $c-dark-duck-blue;
      text-align: center;
    }
  }

  .articles {
    width: 400px;
    height: auto;
    display: flex;
    position: relative;
    background-image: url('../../images/logo/logo-bg.webp');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: top;
    margin-right: -6px;
    padding-bottom: 5rem;
    text-shadow: 0px 1px 1px $c-very-dark-duck-blue;

    .text-content {
      width: 90vw;
      margin: 5rem 1rem auto auto;

      .title {
        color: $c-yellow;
        font-family: amatic-bold, sans-serif;
        font-size: 2rem;
        margin-bottom: 1.5rem;
        text-align: center;
      }

      .item {
        display: flex;
        flex-direction: column;
        margin-bottom: 1.5rem;
        position: relative;

        .article-title {
          font-family: barlow-semibold, sans-serif;
          font-size: 1rem;
          margin-bottom: 0.5rem;
          color: $c-light-ocre;
        }

        .excerpt {
          font-family: barlow-regular, sans-serif;
          font-size: 1rem;
          line-height: 1.2rem;
          color: $c-white-aqua-blue;
        }

        .see-article-btn {
          display: flex;
          flex-direction: row;
          justify-content: flex-end;
          align-items: center;
          background-color: transparent;
          margin-top: 0.3rem;
          color: $c-yellow;
          text-shadow: 0px 1px 1px $c-very-dark-duck-blue;

          &:hover > .fa-arrow {
            animation: slidein 1.3s ease-in-out infinite running;
          }

          .label {
            font-family: barlow-semibold, sans-serif;
            font-size: 1rem;
            margin-right: 1rem;
          }

          .fa-arrow {
            font-size: 0.8rem;
            margin-top: 0.2rem;
          }
        }

        &::after {
          content: '♦';
          position: absolute;
          left: 45%;
          bottom: -1rem;
          color: $c-yellow;
        }

        &:last-child::after {
          content: '';
        }
      }
    }
  }
}

@media (min-width: 414px) {
  .presentation {
    .julie {
      width: 350px;
    }

    .articles {
      width: 450px;
    }
  }
}

//* -------------------------------------------------------------------------------
//* ------------------------------ TABLET ----------------------------------------
//* -------------------------------------------------------------------------------

@media (min-width: 599px) {
  .presentation {
    padding: 5rem 0;

    .julie {
      width: 400px;
    }

    .presentation-text {
      width: 85%;

      .title {
        font-size: 2.5rem;
        margin-bottom: 1.5rem;
      }

      .text {
        font-size: 1.3rem;
        line-height: 1.6rem;
      }
    }

    .articles {
      width: 100%;
      padding-bottom: 5rem;
      margin-top: 2rem;

      .text-content {
        width: 50%;
        margin: 5rem auto auto auto;

        .title {
          font-size: 2.5rem;
        }

        .item {
          .article-title {
            font-size: 1.6rem;
          }

          .excerpt {
            font-size: 1.3rem;
            line-height: 1.6rem;
          }

          .see-article-btn {
            margin-top: 0;
            .label {
              font-size: 1.2rem;
            }

            .fa-arrow {
              font-size: 1rem;
            }
          }
        }
      }
    }
  }
}

@media (min-width: 899px) {
  .presentation {
    .julie {
      width: 450px;
    }
  }
}

//* -------------------------------------------------------------------------------
//* ------------------------------ DESKTOP ----------------------------------------
//* -------------------------------------------------------------------------------

@media (min-width: 1199px) {
  .presentation {
    width: 100%;
    flex-wrap: wrap;
    padding: 8rem 0 5rem 1rem;

    .julie {
      width: 400px;
      transform: rotate(5deg);
    }

    .presentation-text {
      width: 730px;

      .title {
        font-family: homemade-apple, sans-serif;
        font-size: 3.5rem;
        color: $c-light-duck-blue;
        margin-bottom: 2rem;
      }

      .text {
        font-family: barlow-italic, sans-serif;
        font-size: 1.7rem;
        line-height: 2.2rem;
        color: $c-dark-duck-blue;
        text-align: center;
      }
    }

    .articles {
      width: 100%;
      height: auto;
      background-image: url('../../images/logo/logo-bg.webp');
      margin-right: -6px;
      text-shadow: 0px 1px 1px $c-very-dark-duck-blue;

      .text-content {
        width: 45%;
        margin: 6.5rem auto 0 auto;

        .title {
          color: $c-yellow;
          font-family: amatic-bold, sans-serif;
          font-size: 3.5rem;
          margin-bottom: 1.5rem;
        }

        .item {
          display: flex;
          flex-direction: column;
          margin-bottom: 1.5rem;
          position: relative;

          .article-title {
            font-family: barlow-semibold, sans-serif;
            font-size: 1.4rem;
            margin-bottom: 0.5rem;
            color: $c-light-ocre;
          }

          .excerpt {
            font-family: barlow-regular, sans-serif;
            font-size: 1.1rem;
            line-height: 1.3rem;
            color: $c-white-aqua-blue;
          }

          .see-article-btn {
            display: flex;
            flex-direction: row;
            justify-content: flex-end;
            align-items: center;
            background-color: transparent;
            margin-top: 0.5rem;
            color: $c-yellow;
            text-shadow: 0px 1px 1px $c-very-dark-duck-blue;

            .label {
              font-family: barlow-semibold, sans-serif;
              font-size: 1.2rem;
              margin-right: 1rem;
            }

            .fa-arrow {
              font-size: 1rem;
              margin-top: 0.2rem;
            }
          }

          &::after {
            content: '♦';
            position: absolute;
            left: 45%;
            bottom: -1rem;
            color: $c-yellow;
          }

          &:last-child::after {
            content: '';
          }
        }
      }
    }
  }
}

@media (min-width: 1399px) {
  .presentation {
    flex-wrap: nowrap;
    padding: 8rem 0 5rem 1rem;

    .presentation-text {
      width: 750px;

      .title {
        font-size: 3.5rem;
        margin-bottom: 2rem;
      }

      .text {
        font-size: 1.7rem;
        line-height: 2.2rem;
      }
    }

    .articles {
      width: 550px;
      display: flex;
      position: relative;
      background-image: url('../../images/logo/bg-articles.webp');
      background-repeat: no-repeat;
      background-size: contain;
      background-position: top;
      margin-right: -6px;
      margin-top: 10rem;
      text-shadow: 0px 1px 1px $c-very-dark-duck-blue;

      .text-content {
        width: 60%;
        margin: 5rem 1rem auto auto;

        .title {
          font-size: 3rem;
          margin-bottom: 1rem;
        }

        .item {
          margin-bottom: 1.5rem;

          .article-title {
            font-size: 1.2rem;
            margin-bottom: 0.5rem;
          }

          .excerpt {
            font-family: barlow-regular, sans-serif;
            font-size: 1.1rem;
            line-height: 1.3rem;
            color: $c-white-aqua-blue;
          }

          .see-article-btn {
            display: flex;
            flex-direction: row;
            justify-content: flex-end;
            align-items: center;
            background-color: transparent;
            margin-top: 0.5rem;
            color: $c-yellow;
            text-shadow: 0px 1px 1px $c-very-dark-duck-blue;

            .label {
              font-family: barlow-semibold, sans-serif;
              font-size: 1.2rem;
              margin-right: 1rem;
            }

            .fa-arrow {
              font-size: 1rem;
              margin-top: 0.2rem;
            }
          }

          &::after {
            content: '♦';
            position: absolute;
            left: 45%;
            bottom: -1rem;
            color: $c-yellow;
          }

          &:last-child::after {
            content: '';
          }
        }
      }
    }
  }
}

@media (min-width: 1920px) {
  .presentation {
    .julie {
      width: 500px;
    }

    .articles {
      width: 550px;
      height: 770px;
      margin-top: 0;

      .text-content {
        margin: 10rem 1rem auto auto;

        .title {
          font-size: 3.5rem;
          margin-bottom: 2rem;
        }

        .item {
          margin-bottom: 3.5rem;

          &:last-child {
            margin-bottom: 0;
          }

          .article-title {
            font-size: 1.4rem;
          }
        }
      }
    }
  }
}
