.gallery {
  margin: 7rem 0 0 0;
  position: relative;

  .title {
    width: 300px;
    font-family: homemade-apple, sans-serif;
    font-size: 2rem;
    line-height: 2.5rem;
    color: $c-duck-blue;
    background-color: $c-light-ocre;
    border-radius: 40% 0% 0% 20%;
    padding: 2rem 2rem 2rem 0;
    text-align: end;
    box-shadow: 0px 10px 10px -10px $c-very-dark-duck-blue;
    position: absolute;
    top: -6rem;
    right: 0;
    z-index: 5;
  }

  .title-background {
    width: 300px;
    height: 150px;
    background-color: $c-dark-ocre;
    border-radius: 20% 40% 30% 50%;
    box-shadow: 0px 10px 10px -10px $c-very-dark-duck-blue;
    position: absolute;
    top: -4.5rem;
    right: 1rem;
    z-index: 0;
  }

  .carrousel-gallery {
    width: 100%;
    background: linear-gradient(217deg, #985e0d12, rgba(255, 0, 0, 0) 70.71%),
      linear-gradient(127deg, #985e0d34, rgba(0, 255, 0, 0) 70.71%),
      linear-gradient(336deg, #985e0d00, rgba(0, 0, 255, 0) 70.71%);
    border-radius: 20% 25% 30% 25%;
    padding: 5rem 1rem;
    margin: 0 auto;

    .carrousel-item {
      width: 100%;
      padding: 1rem 0;

      .image-container {
        width: 280px;
        height: 280px;
        position: relative;
        margin: auto;
        overflow: hidden;
        border: 10px solid $c-white-aqua-blue;
        box-shadow: 0px 10px 10px -10px $c-very-dark-duck-blue;

        .image {
          width: 210%;
          height: 170%;
          position: absolute;
          top: -25%;
          left: -52%;
        }

        .portrait {
          width: 350%;
          height: 250%;
          top: -65%;
          left: -125%;
        }
      }
    }
  }

  .splide__arrow {
    width: 20px;
    height: 20px;
    background-color: $c-duck-blue;
  }

  .splide__arrow--prev {
    margin-left: -1.5rem;
  }

  .splide__arrow--next {
    margin-right: -1.5rem;
  }

  .splide__pagination {
    bottom: -2rem;
  }

  .splide__pagination__page {
    border: 7px solid $c-duck-blue;
  }

  .splide__arrow svg {
    fill: $c-white-aqua-blue;
    width: 15px;
    height: 15px;
  }
}

//* -------------------------------------------------------------------------------
//* ------------------------------ TABLET ----------------------------------------
//* -------------------------------------------------------------------------------

@media (min-width: 599px) {
  .gallery {
    margin: 7rem 0 0 0;
    position: relative;

    .title {
      width: 450px;
      font-size: 2.5rem;
      line-height: 3rem;
      top: -5rem;
    }

    .title-background {
      width: 450px;
      height: 100px;
      background-color: $c-dark-ocre;
      border-radius: 20% 40% 30% 50%;
      box-shadow: 0px 10px 10px -10px $c-very-dark-duck-blue;
      position: absolute;
      top: -2.5rem;
      right: 1rem;
      z-index: 0;
    }

    .carrousel-gallery {
      .carrousel-item {
        width: 100%;
        padding: 1rem 0;

        .image-container {
          width: 230px;
          height: 230px;
        }
      }
    }

    .splide__arrow {
      width: 30px;
      height: 30px;
      background-color: $c-duck-blue;
    }

    .splide__arrow--prev {
      margin-left: -2rem;
    }

    .splide__arrow--next {
      margin-right: -2rem;
    }

    .splide__pagination {
      bottom: -2rem;
    }

    .splide__pagination__page {
      border: 7px solid $c-duck-blue;
    }

    .splide__arrow svg {
      fill: $c-white-aqua-blue;
      width: 20px;
      height: 20px;
    }
  }
}

@media (min-width: 899px) {
  .gallery {
    .carrousel-gallery {
      .carrousel-item {
        width: 100%;
        padding: 1rem 0;

        .image-container {
          width: 270px;
          height: 270px;
        }
      }
    }
  }
}

//* -------------------------------------------------------------------------------
//* ------------------------------ DESKTOP ----------------------------------------
//* -------------------------------------------------------------------------------

@media (min-width: 1199px) {
  .gallery {
    .title {
      width: 550px;
      font-size: 3rem;
      line-height: 3.5rem;
      padding: 2rem 4rem 2rem 0;
      top: -6rem;
    }

    .title-background {
      width: 550px;
      height: 120px;
      top: -4rem;
      right: 3rem;
    }

    .carrousel-gallery {
      .carrousel-item {
        .image-container {
          width: 220px;
          height: 220px;
          position: relative;
          margin: auto;
          overflow: hidden;
          border: 10px solid $c-white-aqua-blue;
          box-shadow: 0px 10px 10px -10px $c-very-dark-duck-blue;
        }
      }
    }

    .splide__arrow {
      width: 40px;
      height: 40px;
      background-color: $c-duck-blue;
    }

    .splide__arrow--prev {
      margin-left: -2.5rem;
    }

    .splide__arrow--next {
      margin-right: -2.5rem;
    }

    .splide__pagination {
      bottom: -2rem;
    }

    .splide__pagination__page {
      border: 7px solid $c-duck-blue;
    }

    .splide__arrow svg {
      fill: $c-white-aqua-blue;
      width: 25px;
      height: 25px;
    }
  }
}

@media (min-width: 1399px) {
  .gallery {
    .carrousel-gallery {
      .carrousel-item {
        .image-container {
          width: 250px;
          height: 250px;
        }
      }
    }

    .splide__pagination {
      bottom: -3rem;
    }

    .splide__pagination__page {
      border: 8px solid $c-duck-blue;
    }
  }
}

@media (min-width: 1599px) {
  .gallery {
    .carrousel-gallery {
      .carrousel-item {
        .image-container {
          width: 300px;
          height: 300px;
        }
      }
    }
  }
}

@media (min-width: 1920px) {
  .gallery {
    .carrousel-gallery {
      .carrousel-item {
        .image-container {
          width: 350px;
          height: 350px;
        }
      }
    }

    .splide__arrow--prev {
      margin-left: -1.7rem;
    }

    .splide__arrow--next {
      margin-right: -1.7rem;
    }
  }
}
